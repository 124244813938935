 
import { BASE_API } from "../../utils/constants";
import { makeRequest } from "../Request";
const BASE_URL = `${BASE_API}/`;

// Función para obtener los datos 
export async function getProductoApi(token,schema) {
  const url = `${BASE_URL}${schema}/productos/productos`;
  return makeRequest(url, "GET", token);
}


///////////////////////////////////////////////////////////////////////////////

// Función para agregar un nuevo dato
export async function addProductoApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productos/`;
  return makeRequest(url, "POST", token, data);
}
// Función para agregar un nuevo dato
export async function ObtenerProductoApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/modbus/`;
  return makeRequest(url, "POST", token, data);
}
// Función para actualizar los datos 
export async function updateProductoApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productos/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteProductoApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productos/${id}/`;
  return makeRequest(url, "DELETE", token);
}

///////////////////////////////////////////////////////////////////////////////

// Función para obtener los datos 
export async function getProductoBaseApi(token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosbase`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addProductoBaseApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosbase/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateProductoBaseApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosbase/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteProductoBaseApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosbase/${id}/`;
  return makeRequest(url, "DELETE", token);
}


///////////////////////////////////////////////////////////////////////////////

// Función para obtener los datos 
export async function getProductoMezclaApi(token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosmezcla`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addProductoMezclaApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosmezcla/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateProductoMezclaApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosmezcla/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteProductoMezclaApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productosmezcla/${id}/`;
  return makeRequest(url, "DELETE", token);
}


///////////////////////////////////////////////////////////////////////////////

// Función para obtener los datos 
export async function getProductoTanqueApi(token,schema) {
  const url = `${BASE_URL}${schema}/productos/productostanque`;
  return makeRequest(url, "GET", token);
}

// Función para agregar un nuevo dato
export async function addProductoTanqueApi(data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productostanque/`;
  return makeRequest(url, "POST", token, data);
}

// Función para actualizar los datos 
export async function updateProductoTanqueApi(id, data, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productostanque/${id}/`;
  return makeRequest(url, "PUT", token, data);
}

// Función para eliminar 
export async function deleteProductoTanqueApi(id, token,schema) {
  const url = `${BASE_URL}${schema}/productos/productostanque/${id}/`;
  return makeRequest(url, "DELETE", token);
}
