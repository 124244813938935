import { useFormik } from "formik";
import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import * as Yup from "yup";

import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import { useTipoControlador } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
import { Divider, Typography } from "@mui/material";

const tipoOptions = [
  { key: "reg_comb", text: "Registradora", value: "reg_comb" },
  { key: "comp_flujo", text: "Comp. flujo", value: "comp_flujo" },
  { key: "kunbus", text: "Kunbus", value: "kunbus" },
];


const options = [
  {
    label:
      "SMP (Single Meter Preset) - No. Brazos: 1, No. Medidores: 1, No. Componentes: 9, No. Aditivos x Brazo: 2",
    value: "1",
  },
  {
    label:
      "EXL Standard DIV I - No. Brazos: 6, No. Medidores: 5, No. Componentes: 9, No. Aditivos x Brazo: 3",
    value: "2",
  },
  {
    label:
      "SCS (Skid Control System) - No. Brazos: 1, No. Medidores: 4, No. Componentes: 4, No. Aditivos x Brazo: 0",
    value: "3",
  },
  {
    label:
      "MLII DIV II - No. Brazos: 6, No. Medidores: 5, No. Componentes: 9, No. Aditivos x Brazo: 3",
    value: "4",
  },
];



export function AddEditTipoControlador(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, TipoControlador } = props;
  const { addTipoControlador, updateTipoControlador } = useTipoControlador();

  const [maxValues, setMaxValues] = useState({
    brazo: 1,
    medidor: 1,
    componente: 9,
    aditivo: 2,
  });
  const formik = useFormik({
    initialValues: initialValues(TipoControlador),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // Construye la lista de configuración de brazos con sus respectivos medidores, componentes y aditivos
        const configuracion = [];
        for (let i = 1; i <= formValue.brazo; i++) {
          const nombreBrazo = `Brazo ${i}`;
          const brazoConfig = {
            [nombreBrazo]: {
              medidores: formValue[`medidor_${i}`] || 0,
              componentes: formValue[`componente_${i}`] || 0,
              aditivos: formValue[`aditivo_${i}`] || 0,
              name: formValue[`name_${i}`] || "",
              index: formValue[`index_${i}`] || "",
            },
          };
          configuracion.push(brazoConfig);
        }

        formValue.configuracion = configuracion;

        // Elimina las propiedades de medidor, componente y aditivo del objeto formValue, ya que no existen en el modelo Django
        delete formValue.medidor;
        delete formValue.componente;
        delete formValue.aditivo;
        delete formValue.name;
        delete formValue.index;

        if (!formValue.used_for_access) {
          formValue.proceso = "";
        }

        if (TipoControlador)
          await updateTipoControlador(TipoControlador.id, formValue);
        else await addTipoControlador(formValue);

        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });
  // Función para obtener los valores máximos basados en la opción seleccionada en "marca_tipo"
  const getMaxValues = (optionValue) => {
    const selectedOption = options.find(
      (option) => option.value === optionValue
    );
    if (selectedOption) {
      const valuesArray = selectedOption.label.match(/\d+/g).map(Number);
      return {
        brazo: valuesArray[0],
        medidor: valuesArray[1],
        componente: valuesArray[2],
        aditivo: valuesArray[3],
      };
    }
    return {
      brazo: 1,
      medidor: 1,
      componente: 9,
      aditivo: 2,
    };
  };

  useEffect(() => {
    // Actualiza los valores máximos cuando cambia la opción seleccionada en "marca_tipo"
    if (formik.values.marca_tipo) {
      setMaxValues(getMaxValues(formik.values.marca_tipo));
    }
  }, [formik.values.marca_tipo]);

  const [errors, setErrors] = useState({
    tipo: false,
    brazo: false,
  });

  useEffect(() => {
    if (TipoControlador?.usa_brazos) {
      const arm_count = TipoControlador?.configuracion.length;
      formik.setFieldValue("brazo", arm_count);

      TipoControlador?.configuracion.forEach((config, index) => {
        const brazoIndex = index + 1;
        const brazoInfo = Object.values(config)[0];

        // Set field values
        formik.setFieldValue(`medidor_${brazoIndex}`, brazoInfo.medidores);
        formik.setFieldValue(`componente_${brazoIndex}`, brazoInfo.componentes);
        formik.setFieldValue(`aditivo_${brazoIndex}`, brazoInfo.aditivos);
        formik.setFieldValue(`name_${brazoIndex}`, brazoInfo.name);
        formik.setFieldValue(`index_${brazoIndex}`, brazoInfo.index);
      });
    }
  }, [TipoControlador]);

  const handleAutocompleteChange = (name, value) => {
    formik.setFieldValue(name, value?.value || "");
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  const handleAutocompleteChange2 = (name, value) => {
    formik.setFieldValue(name, value); // en este caso llega el valor directamente sin propiedades extras
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !value }));
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="marca_tipo"
            options={options}
            getOptionLabel={(option) => option.label || ""}
            value={options.find(option => option.value === formik.values.marca_tipo) || null}
            onChange={(event, value) => handleAutocompleteChange("marca_tipo", value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Modelo"
                variant="outlined"
                error={errors.marca_tipo}
                required
                helperText={errors.marca_tipo ? "Es necesario que escojas un marca" : ""}
              />
            )}
          />
        </Grid>
  
        <Grid item xs={12} sm={4}>
          <Autocomplete
            id="tipo"
            options={tipoOptions}
            getOptionLabel={(option) => option.text || ""}
            value={tipoOptions.find(option => option.value === formik.values.tipo) || null}
            onChange={(event, value) => handleAutocompleteChange("tipo", value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tipo de controlador"
                variant="outlined"
                error={errors.tipo}
                required
                helperText={errors.tipo ? "Es necesario que escoga un tipo" : ""}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            id="nombre_tipo"
            name="nombre_tipo"
            label="Nombre de tipo"
            variant="outlined"
            fullWidth
            value={formik.values.nombre_tipo}
            onChange={formik.handleChange}
            error={formik.touched.nombre_tipo && Boolean(formik.errors.nombre_tipo)}
            helperText={formik.touched.nombre_tipo && formik.errors.nombre_tipo}
          />
        </Grid>
  
        <Grid item xs={3}>
          <FormControl fullWidth>
            <FormControlLabel
              control={
                <Checkbox
                  name="usa_brazos"
                  checked={formik.values.usa_brazos}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  color="primary"
                />
              }
              label="¿Usa brazos?"
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            title="Indica si el controlador usa brazos para el despacho"
            placement="top"
          >
            <HelpIcon style={{ color: "gray" }} />
          </Tooltip>
        </Grid>
  
        {formik.values.usa_brazos && (
          <>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                fullWidth
                id="brazo"
                options={generateOptions(1, maxValues.brazo)}
                getOptionLabel={(option) => option.toString()}
                value={formik.values.brazo}
                onChange={(event, value) => handleAutocompleteChange2("brazo", value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Cantidad de brazos"
                    variant="outlined"
                    error={errors.brazo}
                    required
                    helperText={errors.brazo ? "Es necesario una cantidad de brazos" : ""}
                  />
                )}
              />
            </Grid>
  
            {[...Array(formik.values.brazo)].map((_, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={12}>
                  <Typography variant="h5" gutterBottom>
                    Configuración del Brazo {index + 1}
                  </Typography>
                  <Divider />
                </Grid>
  
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id={`medidor_${index + 1}`}
                    options={generateOptions(1, maxValues.medidor)}
                    getOptionLabel={(option) => option.toString()}
                    value={formik.values[`medidor_${index + 1}`]}
                    onChange={(_, value) => handleAutocompleteChange2(`medidor_${index + 1}`, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Medidores"
                        variant="outlined"
                        error={errors[`medidor_${index + 1}`]}
                        required
                        helperText={errors[`medidor_${index + 1}`] ? "Es necesario una cantidad de medidores" : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id={`componente_${index + 1}`}
                    options={generateOptions(1, maxValues.componente)}
                    getOptionLabel={(option) => option.toString()}
                    value={formik.values[`componente_${index + 1}`]}
                    onChange={(_, value) => handleAutocompleteChange2(`componente_${index + 1}`, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Componentes"
                        variant="outlined"
                        error={errors[`componente_${index + 1}`]}
                        required
                        helperText={errors[`componente_${index + 1}`] ? "Es necesario una cantidad de componentes" : ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Autocomplete
                    id={`aditivo_${index + 1}`}
                    options={generateOptions(1, maxValues.aditivo)}
                    getOptionLabel={(option) => option.toString()}
                    value={formik.values[`aditivo_${index + 1}`]}
                    onChange={(_, value) => handleAutocompleteChange2(`aditivo_${index + 1}`, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Aditivos"
                        variant="outlined"
                        error={errors[`aditivo_${index + 1}`]}
                        required
                        helperText={errors[`aditivo_${index + 1}`] ? "Es necesario una cantidad de aditivos" : ""}
                      />
                    )}
                  />
                </Grid>
  
                <Grid item xs={12} sm={6}>
                  <TextField
                    id={`name_${index + 1}`}
                    name={`name_${index + 1}`}
                    label={`Nombre del brazo ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    value={formik.values[`name_${index + 1}`]}
                    onChange={(event) => formik.setFieldValue(`name_${index + 1}`, event.target.value)}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id={`index_${index + 1}`}
                    name={`index_${index + 1}`}
                    label={`Índice del brazo ${index + 1}`}
                    variant="outlined"
                    fullWidth
                    value={formik.values[`index_${index + 1}`]}
                    onChange={(event) => formik.setFieldValue(`index_${index + 1}`, event.target.value)}
                    required
                  />
                </Grid>
              </React.Fragment>
            ))}
          </>
        )}
  
        <Grid item xs={12} sm={12}>
          <TextField
            id="descripcion"
            name="descripcion"
            label="Descripción"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={formik.values.descripcion}
            onChange={formik.handleChange}
            error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
            helperText={formik.touched.descripcion && formik.errors.descripcion}
          />
        </Grid>
  
        <Grid item xs={12} sm={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
          >
            {TipoControlador ? "Guardar" : "Añadir"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}  

function initialValues(data) {
  const values = {
    nombre_tipo: data?.nombre_tipo || "",
    tipo: data?.tipo,
    brazo: data?.brazo || 1,
    marca_tipo: data?.marca_tipo || "",
    descripcion: data?.descripcion || "",
    usa_brazos: data?.usa_brazos || false,
    ...generateInitialValues(data?.brazo || 1),
  };

  return values;
}

function generateInitialValues(brazo) {
  const initialValues = {};
  for (let i = 1; i <= brazo; i++) {
    initialValues[`medidor_${i}`] = "";
    initialValues[`componente_${i}`] = "";
    initialValues[`aditivo_${i}`] = "";
    initialValues[`name_${i}`] = "";
    initialValues[`index_${i}`] = "";
  }
  return initialValues;
}


function newSchema() {
  return {
    nombre_tipo: Yup.string().required(true),
    marca_tipo: Yup.string().required(true),
    descripcion: Yup.string(),
    // ...generateSchema()
  };
}

function generateOptions(min, max) {
  return Array.from({ length: max - min + 1 }, (_, i) => i + min);
}