import styled from "styled-components";

export const ContainerImageTank = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  svg {
    height: ${({ height }) => (height ? `${height}px` : "150px")};
    > .bg-gradient {
      fill: transparent !important;
    }
    z-index: 2;
    transform: ${({ detail, tank }) => {
    let scaleValue;
    let translateXValue = tank == 2 ? "-17px" : "0";
    let translateYValue = tank == 2 ? "7px" : "0";

    if (detail) {
      scaleValue = tank == 1 ? "1" : tank == 3 ? "1.2" : tank == 2 ? "1" : "1";
    } else {
      scaleValue = tank == 1 ? "1" : tank == 2 || tank == 3 ? "1.2" : "1";
    }

    return `scale(${scaleValue}) translateX(${translateXValue}) translateY(${translateYValue})`;
  }};
    transform-origin: center;
    right: ${({ rightPercent, tank }) => {
    if (rightPercent) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "100px" : tank == 2 ? "250px" : tank == 3 ? "244px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "100px" : tank == 2 ? "80px" : tank == 3 ? "85px" : "0px";
  }};
  }
`;

export const Oval = styled.div`
  width: ${({ width }) => (width ? `${width}px` : "20px")};
  height: ${({ height }) => (height ? `${height}px` : "15px")};
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.primary.gray8};
  background-color: ${({ bg, theme }) => (bg ? bg : theme.primary.white)};
`;

export const ContainerOval = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  p {
    font-size: ${({ detail }) => (detail ? "12px" : "8px")};
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
`;

export const ContainerOvalText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  z-index: 3;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  position: relative;
  left: ${({ leftContainerOval, tank }) => {
    if (leftContainerOval) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "520px" : tank == 2 ? "520px" : tank == 3 ? "520px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "195px" : tank == 2 ? "195px" : tank == 3 ? "195px" : "0px";
  }};
  bottom: ${({ bottomContainerOval }) =>
    bottomContainerOval ? `${bottomContainerOval}px` : "30px"};
`;

export const ContainerFooter = styled.div`
  display: flex;
  gap: 6px;
  margin-top: 8px;
  margin-left: 8px;
  > div {
    display: flex;
    gap: 6px;
    > p {
      font-size: 8px;
      text-transform: uppercase;
      margin: 0;
    }
  }
`;

export const Percent = styled.span`
  font-size: ${({ fontSizePercent }) =>
    fontSizePercent ? `${fontSizePercent}px` : "12px"};
  font-weight: bold;
  color: ${({ theme }) => theme.primary.gray7};
  margin: 0;
  position: relative;
  right: ${({ rightPercent, tank }) => {
    if (rightPercent) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "300px" : tank == 2 ? "200px" : tank == 3 ? "244px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "105px" : tank == 2 ? "80px" : tank == 3 ? "85px" : "0px";
  }};
  bottom: ${({ bottomPercent, tank }) => {
    if (bottomPercent) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "180px" : tank == 2 ? "190px" : tank == 3 ? "190px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "60px" : tank == 2 ? "65px" : tank == 3 ? "60px" : "0px";
  }};
  z-index: 3;
`;

export const ContainerTank = styled.div`
  position: relative;
  align-self: center;
  border-radius: ${({ detail, tank }) => {
    if (detail) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "0px" : tank == 2 ? "200px" : tank == 3 ? "78px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "0px" : tank == 2 ? "15px" : tank == 3 ? "50px" : "0px";
  }};
  background: ${({ theme }) => theme.primary.gradient} !important;
  transform: rotate(180deg);

  height: ${({ detail, tank }) => {
    if (detail) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "325px" : tank == 2 ? "125px" : tank == 3 ? "305px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "120px" : tank == 2 ? "52px" : tank == 3 ? "120px" : "0px";
  }};

  right: ${({ detail, tank }) => {
    if (detail) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "240px" : tank == 2 ? "80px" : tank == 3 ? "155px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "87px" : tank == 2 ? "45px" : tank == 3 ? "55px" : "0px";
  }};

  bottom: ${({ detail, tank }) => {
    if (detail) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "-8px" : tank == 2 ? "0px" : tank == 3 ? "-10px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "-3px" : tank == 2 ? "-6px" : tank == 3 ? "-3px" : "0px";
  }};

  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    width: ${({ detail, tank }) => {
    if (detail) {
      // Estilos para cuando estamos en la parte aumentada del tanque
      return tank == 1 ? "165px" : tank == 2 ? "300px" : tank == 3 ? "220px" : "0px";
    }
    // Estilos para cuando no estamos en la parte aumentada del tanque
    return tank == 1 ? "60px" : tank == 2 ? "130px" : tank == 3 ? "87px" : "0px";
  }};
    height: ${({ percent }) => (percent ? `${percent}%` : "0%")};
    background: ${({ theme }) => theme.primary.black};
    border-radius: inherit;
  }
`;