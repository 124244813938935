import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { alertas } from "../../../Components/Comons/Alertas/alertas";


import {
    getAlarmasNivelTanquesApi,
    updateAlarmasNivelTanqueApi,
    getAlarmasTemperTanquesApi,
    updateAlarmasTemperTanqueApi
} from "../../../api/A_Tanques/A_Alarmas/alarmas";

export function useAlarmasTanques() {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const { unauthorized, SessionExpired, errores, unauthorizedModal } = alertas();

  const [state, setState] = useState({
    loading: true,
    error: null,
    alarmasNivelTanque: null,
    alarmasTemperTanque: null,
  });

  const { loading, error, alarmasNivelTanque, alarmasTemperTanque } = state;

  const handleResponse = (response) => {
    if (response.status === 401) {
      SessionExpired();
      logout();
    } else if (response.status === 403) {
      unauthorized();
      navigate("/");
    }
  };

  const getAlarmasNivelTanque = async (id) => {
    try {
      const [result, response] = await getAlarmasNivelTanquesApi(id, auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, alarmasNivelTanque: result }));
      
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };


  const updateAlarmasNivelTanque = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateAlarmasNivelTanqueApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  /************************************** */

  const getAlarmasTemperTanque = async (id) => {
    try {
      const [result, response] = await getAlarmasTemperTanquesApi(id, auth.token, auth.tenant);
      setState((prevState) => ({ ...prevState, alarmasTemperTanque: result }));
      
    } catch (error) {
      setState((prevState) => ({ ...prevState, error }));
    }
  };


  const updateAlarmasTemperTanque = async (id, data) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const [result, response] = await updateAlarmasTemperTanqueApi(id, data, auth.token, auth.tenant);
      if (response.status === 400) {
        errores();
      } else if (response.status === 403) {
        throw new Error(unauthorizedModal());
      }
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error) {
      setState((prevState) => ({ ...prevState, loading: false, error }));
      throw error;
    }
  };

  return {
    loading,
    error,
    alarmasNivelTanque,
    alarmasTemperTanque,
    getAlarmasNivelTanque,
    getAlarmasTemperTanque,
    updateAlarmasNivelTanque,
    updateAlarmasTemperTanque
  };
}
