export const PermAtmExplosivas = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    data-name="Capa 1"
    viewBox="240 210 520 520"
    {...props}
  >
    <defs>
      <style>{".cls-3{fill:#f8cf0f;stroke-width:0}"}</style>
    </defs>
    <rect
      width={431.43}
      height={654.74}
      x={286.65}
      y={143.63}
      rx={76.35}
      ry={76.35}
      style={{
        stroke: "#1d1d1b",
        strokeMiterlimit: 10,
        fill: "#fff",
        strokeWidth: 21,
      }}
    />
    <rect
      width={317.89}
      height={324.69}
      x={343.42}
      y={439.73}
      rx={46.15}
      ry={46.15}
      style={{
        fill: "#9d9d9c",
        strokeWidth: 19,
        stroke: "#1d1d1b",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M378.69 713.35c-7.19-1.52-9.45-6.33-5.86-12.44 30.37-51.63 60.73-103.26 91.1-154.89 9.72-16.52 19.47-33.02 29.23-49.51 5.24-8.85 11.86-8.96 17.13-.01 29.17 49.53 58.21 99.14 87.35 148.69 10.6 18.02 21.39 35.92 32 53.93 3.76 6.38 2.45 10.6-3.87 13.6-31.4-.05-62.81-.12-94.22-.12-4.73 0-9.45.37-14.18.57-1.91.02-3.81.04-5.72.05-1.25-.22-2.5-.64-3.76-.64-41.84-.04-83.68-.06-125.53-.03-1.23 0-2.46.51-3.69.78Zm17.5-17.03h210.56c-35.15-59.73-69.94-118.84-105.21-178.77-35.37 60.02-70.16 119.05-105.35 178.77Z"
      className="cls-3"
    />
    <path
      d="m502.67 501.61-121.2 201.55 242.23 2.69-121.03-204.24z"
      className="cls-3"
    />
    <path
      d="M392.78 705.85c-6.44-1.36-8.46-5.67-5.24-11.13 27.19-46.21 54.36-92.43 81.55-138.65 8.7-14.79 17.43-29.55 26.16-44.32 4.69-7.93 10.61-8.02 15.33-.01 26.11 44.34 52.11 88.74 78.2 133.1 9.49 16.13 19.15 32.15 28.64 48.28 3.37 5.71 2.19 9.49-3.46 12.17-28.11-.05-56.22-.11-84.34-.1-4.23 0-8.46.33-12.69.51-1.71.02-3.41.03-5.12.05-1.12-.2-2.24-.57-3.36-.57-37.46-.04-74.91-.05-112.37-.02-1.1 0-2.2.46-3.31.7Zm15.66-15.24h188.48c-31.47-53.46-62.61-106.38-94.18-160.02-31.66 53.73-62.8 106.57-94.3 160.02Z"
      style={{
        fill: "#1d1d1b",
        strokeWidth: 0,
      }}
    />
    <text
      style={{
        fontFamily: "MyriadPro-Bold,&quot",
        fontSize: 84,
        fontWeight: 700,
        fill: "#1d1d1b",
      }}
      transform="translate(454.18 679.96)"
    >
      <tspan x={0} y={0}>
        {"EX"}
      </tspan>
    </text>
  </svg>
);

export const PermAtmExplosivas2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    data-name="Capa 1"
    viewBox="275 235 460 460"
    {...props}
  >
    <defs>
      <style>{".cls-3{fill:#f8cf0f;stroke-width:0}"}</style>
    </defs>
    <rect
      width={431.43}
      height={654.74}
      x={286.65}
      y={143.63}
      rx={76.35}
      ry={76.35}
      style={{
        stroke: "#1d1d1b",
        strokeMiterlimit: 10,
        fill: "#fff",
        strokeWidth: 21,
      }}
    />
    <rect
      width={317.89}
      height={324.69}
      x={343.42}
      y={439.73}
      rx={46.15}
      ry={46.15}
      style={{
        fill: "#9d9d9c",
        strokeWidth: 19,
        stroke: "#1d1d1b",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M378.69 713.35c-7.19-1.52-9.45-6.33-5.86-12.44 30.37-51.63 60.73-103.26 91.1-154.89 9.72-16.52 19.47-33.02 29.23-49.51 5.24-8.85 11.86-8.96 17.13-.01 29.17 49.53 58.21 99.14 87.35 148.69 10.6 18.02 21.39 35.92 32 53.93 3.76 6.38 2.45 10.6-3.87 13.6-31.4-.05-62.81-.12-94.22-.12-4.73 0-9.45.37-14.18.57-1.91.02-3.81.04-5.72.05-1.25-.22-2.5-.64-3.76-.64-41.84-.04-83.68-.06-125.53-.03-1.23 0-2.46.51-3.69.78Zm17.5-17.03h210.56c-35.15-59.73-69.94-118.84-105.21-178.77-35.37 60.02-70.16 119.05-105.35 178.77Z"
      className="cls-3"
    />
    <path
      d="m502.67 501.61-121.2 201.55 242.23 2.69-121.03-204.24z"
      className="cls-3"
    />
    <path
      d="M392.78 705.85c-6.44-1.36-8.46-5.67-5.24-11.13 27.19-46.21 54.36-92.43 81.55-138.65 8.7-14.79 17.43-29.55 26.16-44.32 4.69-7.93 10.61-8.02 15.33-.01 26.11 44.34 52.11 88.74 78.2 133.1 9.49 16.13 19.15 32.15 28.64 48.28 3.37 5.71 2.19 9.49-3.46 12.17-28.11-.05-56.22-.11-84.34-.1-4.23 0-8.46.33-12.69.51-1.71.02-3.41.03-5.12.05-1.12-.2-2.24-.57-3.36-.57-37.46-.04-74.91-.05-112.37-.02-1.1 0-2.2.46-3.31.7Zm15.66-15.24h188.48c-31.47-53.46-62.61-106.38-94.18-160.02-31.66 53.73-62.8 106.57-94.3 160.02Z"
      style={{
        fill: "#1d1d1b",
        strokeWidth: 0,
      }}
    />
    <text
      style={{
        fontFamily: "MyriadPro-Bold,&quot",
        fontSize: 84,
        fontWeight: 700,
        fill: "#1d1d1b",
      }}
      transform="translate(454.18 679.96)"
    >
      <tspan x={0} y={0}>
        {"EX"}
      </tspan>
    </text>
  </svg>
);