import React, { useCallback } from "react";
import Tooltip from '@mui/material/Tooltip';
import { Circle } from "../common";
import { Perm3 } from "../icons/perm_3";
import { Perm4 } from "../icons/perm_4";
import { PermEmergencyStop } from "../icons/perm_emergency_stop";
import { PermGrounding } from "../icons/perm_grounding";
import { PermOverFill } from "../icons/perm_overfill";
import { PermWhiteAlert } from "../icons/perm_white_alert";
import { PermAtmExplosivas, PermAtmExplosivas2 } from "../icons/perm_atm_explosivas";
import { PermAtmPeligrosas, PermAtmPeligrosas2 } from "../icons/perm_atm_peligrosas";
import { ContainerCircleIcon, ContainerIcon, ContainerIcons } from "./styles";
import { theme } from "../../theme";

export const IconAlert = ({ data, top, size, width, height, otherview }) => {
  const perSW = useCallback((perm) => {
    if (perm === 0) {
      return theme.default.primary.green;
    } else if (perm === 1) {
      return theme.default.primary.red;
    } else {
      return theme.default.primary.gray4;
    }
  }, []);

  const renderCircleIcon = (permissionKey) => {
    const permissionValue = data?.read_permiss?.[permissionKey];

    const IconComponent = getIconComponent(permissionKey);
    const tooltipTitle = getTooltipTitle(permissionKey);

    return (
      <Tooltip key={permissionKey} title={tooltipTitle}>
        <ContainerCircleIcon>
          <Circle
            relative
            top={top}
            color={perSW(permissionValue)}
            size={size}
          />
          {IconComponent && <IconComponent />}
        </ContainerCircleIcon>
      </Tooltip>
    );
  };

  const getIconComponent = (permissionKey) => {
    switch (permissionKey) {
      case "sobrellenado":
        return PermOverFill;
      case "parada de emergencia":
        return PermEmergencyStop;
      case "tierra":
        return PermGrounding;
      case "hombre_muerto":
        return Perm3;
      case "fire&gas":
        return Perm4;
      case "scada":
        return PermWhiteAlert;
      case "atm explosivas":
        return otherview ? PermAtmExplosivas2 : PermAtmExplosivas;
      case "atm peligrosas":
        return otherview ? PermAtmPeligrosas2 : PermAtmPeligrosas;
      // Add more cases for other keys if needed
      default:
        return PermWhiteAlert;
    }
  };

  const getTooltipTitle = (permissionKey) => {
    switch (permissionKey) {
      case "sobrellenado":
        return "Sobrellenado";
      case "parada de emergencia":
        return "Parada de emergencia";
      case "tierra":
        return "Puesta a tierra";
      case "hombre_muerto":
        return "Hombre muerto";
      case "fire&gas":
        return "Fire & Gas";
      // case "shutdown":
      //   return "Shutdown";
      case "atm explosivas":
        return "ATM Explosivas";
      case "atm peligrosas":
        return "ATM Peligrosas";
      case "scada":
        return "SCADA";
      // Add more cases for other keys if needed
      default:
        return "Desconocido";
    }
  };

  return (
    <ContainerIcon width={width} height={height}>
      <ContainerIcons>
        {renderCircleIcon("sobrellenado")}
        {renderCircleIcon("hombre_muerto")}
        {renderCircleIcon("parada de emergencia")}
        {renderCircleIcon("tierra")}
      </ContainerIcons>
      <ContainerIcons>
        {renderCircleIcon("atm peligrosas")}
        {renderCircleIcon("fire&gas")}
        {renderCircleIcon("atm explosivas")}
        {renderCircleIcon("scada")}
      </ContainerIcons>
      {/* <ContainerIcons>
        {renderCircleIcon("Valvula")}
        {renderCircleIcon("bomba")}
        {renderCircleIcon("shutdown")}
      </ContainerIcons> */}
    </ContainerIcon>
  );
};
