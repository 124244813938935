import styled from "styled-components";

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  svg {
    font-size: 32px;
    cursor: pointer;
    fill: ${({ theme }) => theme.primary.gray3};
    &:hover {
      font-size: 34px;
      transition: 0.3s ease-in-out;
    }
  }
`;

export const ContainerCardDetail = styled.div`
  margin: 0 auto;
  width: 90%;
  min-width: 380px;
  max-width: 1200px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const Text = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "12px")};
  font-weight: 700;
  ${({ uppercase }) => uppercase && `text-transform: uppercase;`}
  ${({ color }) => color && `color: ${color};`}
  ${({ margin }) => margin && `margin: ${margin};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
`;

export const ContainerImages = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid ${({ theme }) => theme.primary.black};
`;

export const ContainerCircle = styled.div`
  margin-top: 15px;
`;

export const ContainerBadge = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerImagesTank = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  svg {
    position: relative;
    width: 350px;
    transform: scale(1.6);
    left: -261px;
    top: 23px;
  }
`;

export const ContainerBomb = styled.div`
  align-self: end;
  position: relative;
  right: 290px;
  top: 12px;

  > svg {
    ${({ showBlink, done }) =>
      showBlink &&
      done &&
      `
        animation: blink 2s linear infinite;
        @keyframes blink {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            opacity: 0;
          }
        }

      `};
    width: 60px;
    height: 60px;
  }
`;



// export const ContainerCarTank = styled.div`
 
 
 
  
//   border-radius: 14px;
 

//   &::before {
//     content: "";
//     position: absolute;
//     width: 100%;
//     height: ${({ percent }) => (percent ? `${percent}%` : "")};
//     background: ${({ theme }) => theme.primary.black};
//   }
// `;

export const ContainerTank = styled.div`
  display: flex;
  align-items: center;
  margin-left: 64px;
`;
export const ContainerCarTank = styled.div`
  position: relative;
  width: ${({ cantidad }) => `calc(337px / ${cantidad})`};
  height: 60px;
  bottom: 17px;
  right: -128px;
  top: 31px;
  border-radius: 2px;
  background: ${({ theme }) => theme.primary.gradient} !important;
  border: 1px solid ${({ theme }) => theme.primary.dark}; /* Añade un borde de 1px sólido */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Añade una sombra ligera */
  transform: rotate(180deg);
`;
export const BarraCarTank = styled.div`
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: ${({ percent }) => (percent ? `${percent}%` : "")};
    background: ${({ theme }) => theme.primary.black};
  }
  `;

  export const CenteredText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  z-index: 20;
  color: ${({ theme }) => theme.primary.white};
  font-weight: 700;
  font-size: ${({ cantidad }) => `calc(60px / ${cantidad})`};
`;

export const SpanStyle = styled.span`
  position: relative;
  bottom: 20px;
  right: 300px;
  z-index: 20;
  color: ${({ theme }) => theme.primary.white};
  font-weight: 700;
  font-size: 20px;
`;

export const ContainerTankComplete = styled.div`
  display: flex;
`;
