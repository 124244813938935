import { Button } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useCliente, usePlantas } from "../../../hooks";
import { alertas } from "../../Comons/Alertas/alertas";
export function AddEditClientes(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Clientes } = props;
  const { addCliente, updateCliente } = useCliente();
  const { PlantasDB, getPlantasDB } = usePlantas();
  const [loadingPlantas, setLoadingPlantas] = useState(false);

  const handleOpenAutocomplete = () => {
    if (PlantasDB === null) {
      setLoadingPlantas(true);
      getPlantasDB()
        .then(() => setLoadingPlantas(false))
        .catch((error) => {
          setLoadingPlantas(false);
          toast.error(error.message, {
            position: "top-center",
          });
        });
    }
  };

  useEffect(() => {
    if (Clientes) {
      handleOpenAutocomplete();
    }
  }, [Clientes, handleOpenAutocomplete]);

  const formik = useFormik({
    initialValues: initialValues(Clientes),
    validationSchema: Yup.object(validationSchema()),
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (Clientes) {
          await updateCliente(Clientes.id_cliente, formValue);
        } else {
          await addCliente(formValue);
        }
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        {/* Fila 1 */}
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="NIT de cliente"
            name="nit_cliente"
            placeholder="NIT cliente"
            {...formik.getFieldProps("nit_cliente")}
            error={
              formik.touched.nit_cliente && Boolean(formik.errors.nit_cliente)
            }
            helperText={formik.touched.nit_cliente && formik.errors.nit_cliente}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="Razón social"
            name="razon_social"
            placeholder="Razón social"
            {...formik.getFieldProps("razon_social")}
            error={
              formik.touched.razon_social && Boolean(formik.errors.razon_social)
            }
            helperText={formik.touched.razon_social && formik.errors.razon_social}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            label="Código"
            name="codigo"
            placeholder="Código"
            {...formik.getFieldProps("codigo")}
            error={
              formik.touched.codigo && Boolean(formik.errors.codigo)
            }
            helperText={formik.touched.codigo && formik.errors.codigo}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            required
            fullWidth
            label="Email"
            name="id_empresa.email"
            placeholder="Empresa@mail.com"
            {...formik.getFieldProps("id_empresa.email")}
            error={
              formik.touched.id_empresa?.email && Boolean(formik.errors.id_empresa?.email)
            }
            helperText={formik.touched.id_empresa?.email && formik.errors.id_empresa?.email}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            required
            fullWidth
            label="Celular de contacto"
            name="id_empresa.celular"
            placeholder="Celular"
            {...formik.getFieldProps("id_empresa.celular")}
            error={
              formik.touched.id_empresa?.celular && Boolean(formik.errors.id_empresa?.celular)
            }
            helperText={formik.touched.id_empresa?.celular && formik.errors.id_empresa?.celular}
         
          />
        </Grid>

        {/* Fila 2 */}
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Teléfono de contacto"
            name="id_empresa.telefono"
            placeholder="Teléfono"
            {...formik.getFieldProps("id_empresa.telefono")}
            error={
              formik.touched.id_empresa?.telefono && Boolean(formik.errors.id_empresa?.telefono)
            }
            helperText={formik.touched.id_empresa?.telefono && formik.errors.id_empresa?.telefono}
            
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Área"
            name="id_empresa.area"
            placeholder="Área"
            {...formik.getFieldProps("id_empresa.area")}
            error={
              formik.touched.id_empresa?.area && Boolean(formik.errors.id_empresa?.area)
            }
            helperText={formik.touched.id_empresa?.area && formik.errors.id_empresa?.area}
          />
        </Grid>

        {/* Fila 3 */}

        <Grid item xs={12} md={12}>
          <Autocomplete
            multiple
            id="plantas"
            options={PlantasDB || []}
            disableCloseOnSelect
            getOptionLabel={(option) => option?.razon_social || ""}
            value={formik.values.plantas.map((plantId) =>
              PlantasDB?.find((plant) => plant?.id_myemp === plantId)
            )}
            onChange={(_, values) => {
              const plantasIds = values.map((value) => value.id_myemp);
              formik.setFieldValue("plantas", plantasIds);
            }}
            onOpen={handleOpenAutocomplete}
            onClose={() => setLoadingPlantas(false)}
            loading={loadingPlantas}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.razon_social}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ubicaciones"
                variant="outlined"
                error={formik.touched.plantas && Boolean(formik.errors.plantas)}
                helperText={formik.touched.plantas && formik.errors.plantas}
                sx={{ background: "#fcfcfc" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.cliente}
                onChange={formik.handleChange}
                name="cliente"
                color="primary"
              />
            }
            label="Es cliente"
          />
          <Tooltip
            title="Establece el socio de negocio como cliente"
            placement="top"
          >
            <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.proveedores}
                onChange={formik.handleChange}
                name="proveedores"
                color="primary"
              />
            }
            label="Es proveedor"
          />
          <Tooltip
            title="Establece el socio de negocio como como proveedor"
            placement="top"
          >
            <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
          </Tooltip>
        </Grid>

        {/* Fila 4 */}
        <Grid item xs={12} sm={12}>
          <Button
            type="submit"
            variant="contained"
            className="btn btn-danger"
            fullWidth
          >
            {Clientes ? "Actualizar" : "Crear"}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

function initialValues(data) {
  return {
    nit_cliente: data?.nit_cliente || "",
    razon_social: data?.razon_social || "",
    plantas: data?.plantas || [],
    codigo: data?.codigo || [],
    id_empresa: {
      email: data?.id_empresa?.email || "",
      celular: data?.id_empresa?.celular || "",
      telefono: data?.id_empresa?.telefono || "",
      area: data?.id_empresa?.area || "",
    },
    proveedores: data?.proveedores || false,
    cliente: data?.cliente || false,
  };
}

function validationSchema() {
  return {
    nit_cliente: Yup.number().required("El NIT del cliente es requerido"),
    razon_social: Yup.string().required("La razón social es requerida"),
    codigo: Yup.string().required("El código es requerida"),
    id_empresa: Yup.object().shape({
      email: Yup.string()
        .email("Ingrese un Email válido")
        .required("El campo Email es requerido"),
      celular: Yup.string()
        .matches(/^[0-9]+$/, "Debe contener solo dígitos")
        .min(8, "La longitud debe ser mayor o igual a 8")
        .max(16, "La longitud debe ser menor o igual a 16")
        .required("El campo Celular es requerido"),
      telefono: Yup.string()
        .matches(/^[0-9]+$/, "Debe contener solo dígitos")
        .min(7, "La longitud debe ser mayor o igual a 7")
        .max(10, "La longitud debe ser menor o igual a 10")
        .required("El campo Teléfono es requerido"),
      area: Yup.string(),
    }),
    plantas: Yup.array()
      .required("Al menos una planta debe ser seleccionada")
      .min(1, "Al menos una planta debe ser seleccionada"),
    proveedores: Yup.boolean(),
    cliente: Yup.boolean(),
  };
}

