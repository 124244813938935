import React, { useEffect, useState } from "react";

import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { useFormik, FormikProvider } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  useAditivos,
  useControladores,
  useProductoBase,
  useProductoMezcla,
} from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";


const indexOptions = [
  { key: '031', value: '031', text: '031' },
  { key: '032', value: '032', text: '032' },
  { key: '033', value: '033', text: '033' },
  { key: '034', value: '034', text: '034' },
  { key: '035', value: '035', text: '035' },
  { key: '036', value: '036', text: '036' },
  { key: '037', value: '037', text: '037' },
  { key: '038', value: '038', text: '038' },
  { key: '039', value: '039', text: '039' },
  { key: '040', value: '040', text: '040' },
  { key: '041', value: '041', text: '041' },
  { key: '042', value: '042', text: '042' },
  { key: '043', value: '043', text: '043' },
  { key: '044', value: '044', text: '044' },
  { key: '045', value: '045', text: '045' },
  { key: '046', value: '046', text: '046' },
  { key: '047', value: '047', text: '047' },
  { key: '048', value: '048', text: '048' },
  { key: '049', value: '049', text: '049' },
  { key: '050', value: '050', text: '050' },
  { key: '051', value: '051', text: '051' },
  { key: '052', value: '052', text: '052' },
  { key: '053', value: '053', text: '053' },
  { key: '054', value: '054', text: '054' },
  { key: '055', value: '055', text: '055' },
  { key: '056', value: '056', text: '056' },
  { key: '057', value: '057', text: '057' },
  { key: '058', value: '058', text: '058' },
  { key: '059', value: '059', text: '059' },
  { key: '060', value: '060', text: '060' },
  { key: '061', value: '061', text: '061' },
  { key: '062', value: '062', text: '062' },
  { key: '063', value: '063', text: '063' },
  { key: '064', value: '064', text: '064' },
  { key: '065', value: '065', text: '065' },
  { key: '066', value: '066', text: '066' },
  { key: '067', value: '067', text: '067' },
  { key: '068', value: '068', text: '068' },
  { key: '069', value: '069', text: '069' },
  { key: '070', value: '070', text: '070' },
  { key: '071', value: '071', text: '071' },
  { key: '072', value: '072', text: '072' },
  { key: '073', value: '073', text: '073' },
  { key: '074', value: '074', text: '074' },
  { key: '075', value: '075', text: '075' },
  { key: '076', value: '076', text: '076' },
  { key: '077', value: '077', text: '077' },
  { key: '078', value: '078', text: '078' },
  { key: '079', value: '079', text: '079' },
  { key: '080', value: '080', text: '080' },
  { key: '081', value: '081', text: '081' },
  { key: '082', value: '082', text: '082' },
  { key: '083', value: '083', text: '083' },
  { key: '084', value: '084', text: '084' },
  { key: '085', value: '085', text: '085' },
  { key: '086', value: '086', text: '086' },
  { key: '087', value: '087', text: '087' },
  { key: '088', value: '088', text: '088' },
  { key: '089', value: '089', text: '089' },
  { key: '090', value: '090', text: '090' },
  { key: '091', value: '091', text: '091' },
  { key: '092', value: '092', text: '092' },
  { key: '093', value: '093', text: '093' },
  { key: '094', value: '094', text: '094' },
  { key: '095', value: '095', text: '095' },
  { key: '096', value: '096', text: '096' },
  { key: '097', value: '097', text: '097' },
  { key: '098', value: '098', text: '098' },
  { key: '099', value: '099', text: '099' }
];

const options = ["031", "032", "033", "034", "035", "036"];

function validationSchema() {
  return Yup.object().shape({
    index: Yup.string().when("isBase", {
      is: true,
      then: Yup.string().required('El campo "index" es obligatorio'),
      otherwise: Yup.string(),
    }),
    code: Yup.string().when("isBase", {
      is: true,
      then: Yup.string()
        .required('El campo "code" es obligatorio')
        .max(6, 'El campo "Code" no puede tener más de 6 caracteres'),
      otherwise: Yup.string().max(
        6,
        'El campo "Code" no puede tener más de 6 caracteres'
      ),
    }),

    multiload_name: Yup.string()
      .max(
        25,
        'El campo "Descripcion Larga" no puede tener más de 25 caracteres'
      )
      .required('El campo "Descripcion Larga" es obligatorio'),
    nombre: Yup.string()
      .max(10, 'El campo "Descripcion" no puede tener más de 10 caracteres')
      .required('El campo "Descripcion" es obligatorio'),
    nombre_comercial: Yup.string().required(
      'El campo "nombre_comercial" es obligatorio'
    ),
    color: Yup.string(),
    productos_base: Yup.array().when("isBase", {
      is: false,
      then: Yup.array()
        .of(
          Yup.object().shape({
            producto_base: Yup.string().required("Requerido"),
            porcentaje: Yup.number()
              .required("Requerido")
              .min(0, "El valor mínimo es 0")
              .max(100, "El valor máximo es 100"),
          })
        )
        .test(
          "sum-100",
          "El porcentaje total debe ser 100%",
          function (values) {
            const sumProductosBase = values.reduce(
              (acc, curr) => acc + Number(curr?.porcentaje),
              0
            );
            // const sumAditivos = this.parent.aditivo.reduce(
            //   (acc, curr) => acc + Number(curr.aditivo_porcentaje),
            //   0
            // );
            return sumProductosBase === 100;
          }
        ),
      otherwise: Yup.array(),
    }),
    muestra: Yup.string(),
    aditivo: Yup.array().of(
      Yup.object().shape({
        aditivos: Yup.string().required("Requerido"),
        porcentaje: Yup.number()
          .required("Requerido")
          .min(0, "El valor mínimo es 0")
          .max(6.6, "El valor máximo es 6.6%"),
      })
    ),
  });
}

function initialValues(data) {

  return {
    index: data?.index || "",
    code: data?.code || "",
    multiload_name: data?.multiload_name || "",
    nombre: data?.nombre || "",
    nombre_comercial: data?.nombre_comercial || "",
    color: data?.color || "",
    productos_base: data?.productos_base || [{}],
    aditivo: data?.aditivo || [],
    aditivo_marcador: data?.aditivo_marcador || [],
    controlador: data?.controlador || [{}],
    muestra: data?.muestra || "",
    setMLII: true,
    longDesc: data?.longDesc || "",
    shortDesc: data?.shortDesc || "",
    operation: data?.operation || "",
    isBase: data?.operation == "2" || data?.operation == "3" ? true : false,
    isBaseWAdd: data?.operation == "3" ? true : false,
    assigned: data?.assigned || false,
    authorized: data?.authorized || false,
    is_updating: false,
    aditive: [],
    comp_index: data?.index || "",
  };
}

export function AddEditProductosMezcla(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, ProductosMezcla } = props;
  const { addProductoMezcla, updateProductoMezcla, getProductoMezcla, ProductoMezcla: ProductoMezclaData } = useProductoMezcla();
  const { ProductoBaseDB, getProductoBaseDB } = useProductoBase();
  const { AditivosDB, getAditivosDB } = useAditivos();
  const { CtrlCompFlujo, getControladorCompFlujo } = useControladores();
  const [selectedControllers, setSelectedControllers] = useState({});
  const [selectedBaseProdID, setSelectedBaseProdID] = useState([]);
  const [selectedAdd, setSelectedAdd] = useState([]);


  useEffect(() => {
    getProductoBaseDB();
    getAditivosDB();
    getControladorCompFlujo();
    getProductoMezcla();
  }, []);


  const productoMezclaResults = ProductoMezclaData?.results || [];
  const indexUsados = new Set(productoMezclaResults.map((producto) => producto.index));
  const indexProductoActual = ProductosMezcla?.index;
  const indexOptionsFiltradas = indexOptions.filter((opt) => !indexUsados.has(opt.key) || opt.key === indexProductoActual);

  const formik = useFormik({
    initialValues: initialValues(ProductosMezcla),
    validationSchema: validationSchema(), // <-- Change this line
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {
        formValues.longDesc = formValues.nombre_comercial;
        formValues.shortDesc = formValues.multiload_name;

        if (formValues.isBase) {
          if (formValues.isBaseWAdd) {
            formValues.operation = "3";
            formValues.aditive = selectedAdd;
          } else {
            formValues.operation = "2";
          }

          const base_prod = {
            producto_base: selectedBaseProdID,
            porcentaje: "100",
          };

          // Verificar si ya existe un producto base con el mismo índice
          const existingIndex = formValues.productos_base?.findIndex(
            (item) => item.index === formValues.index
          );

          if (existingIndex !== -1) {
            formValues.productos_base[existingIndex] = base_prod;
          } else {
            formValues.productos_base = [
              ...(formValues.productos_base || []),
              base_prod,
            ];
          }
        } else {
          formValues.operation = "4";
        }

        // if (ProductosMezcla && formValues.setMLII) {
        //   formValues.is_updating = true;
        //   await handleAddMultiLdProd(formValues);
        // } else if (formValues.setMLII) {
        //   await handleAddMultiLdProd(formValues);
        // }

        if (formValues.operation === "3") {
          formValues.index = formValues.comp_index;
        }

        if (ProductosMezcla) {
          await updateProductoMezcla(ProductosMezcla.id, formValues);
        } else {
          await addProductoMezcla(formValues);
        }

        Successful();
        onClose();
        onRefetch();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  const buttonStyle = {
    backgroundColor: "rgb(204, 48, 43)",
    color: "white",
  };

  useEffect(() => {
    if (formik.values.controlador && formik.values.controlador.length > 0) {
      const newSelectedControllers = {};

      formik.values.controlador.forEach((item, index) => {
        const selectedArms = CtrlCompFlujo?.find(
          (ctrl) => ctrl.id_controlador === item.controlador
        )?.brazos || [];

        newSelectedControllers[index] = selectedArms;
      });

      setSelectedControllers(newSelectedControllers);
    }
  }, [formik.values.controlador, CtrlCompFlujo]);

  const handleControllerChange = (index, value) => {
    const id_controlador = value?.id_controlador || null;

    formik.setFieldValue(`controlador[${index}].controlador`, id_controlador);
    formik.setFieldValue(`controlador[${index}].brazo`, null);

    if (id_controlador) {
      const selectedArms = CtrlCompFlujo.find(
        (ctrl) => ctrl.id_controlador === id_controlador
      )?.brazos || [];

      setSelectedControllers((prev) => ({
        ...prev,
        [index]: selectedArms,
      }));
    } else {
      setSelectedControllers((prev) => {
        const newState = { ...prev };
        delete newState[index];
        return newState;
      });
    }
  };

  const handleArmChange = (index, value) => {
    formik.setFieldValue(`controlador[${index}].brazo`, value ? value.id : null);
  };

  console.log("Formik: ", formik.values)

  return (
    <FormikProvider value={formik}>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* primera fila */}
          {/* <Grid item xs={12} sm={4}>
            {formik.values.isBaseWAdd ||
              (formik.values.setMLII && !formik.values.isBase) ? (
              <>
                <Autocomplete
                  id="comp_index"
                  options={indexOptionsFiltradas}
                  getOptionLabel={(option) => option.text}
                  value={
                    indexOptionsFiltradas.find((opt) => opt.key === formik.values.comp_index) || null
                  }
                  onChange={(_, value) => {
                    formik.setFieldValue("comp_index", value?.key || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Index"
                      fullWidth
                      name="comp_index"
                      variant="outlined"
                      error={
                        formik.errors.comp_index &&
                        Boolean(formik.errors.comp_index)
                      }
                      helperText={
                        formik.touched.comp_index && formik.errors.comp_index
                      }
                      sx={{ background: "#fcfcfc" }}
                    />
                  )}
                />
              </>
            ) : (
              <Autocomplete
                id="index"
                options={indexOptionsFiltradas}
                getOptionLabel={(option) => option.text}
                value={indexOptionsFiltradas.find((opt) => opt.key === formik.values.index) || null}
                onChange={(_, value) => {
                  formik.setFieldValue("index", value?.key || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Index"
                    variant="outlined"
                    fullWidth
                    error={formik.touched.index && Boolean(formik.errors.index)}
                    helperText={formik.touched.index && formik.errors.index}
                    disabled={formik.values.isBase}
                  />
                )}
              />
            )}
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="index"
              options={indexOptionsFiltradas}
              getOptionLabel={(option) => option.text}
              value={indexOptionsFiltradas.find((opt) => opt.key === formik.values.index) || null}
              onChange={(_, value) => {
                formik.setFieldValue("index", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Index"
                  variant="outlined"
                  fullWidth
                  error={formik.touched.index && Boolean(formik.errors.index)}
                  helperText={formik.touched.index && formik.errors.index}
                  disabled={formik.values.isBase}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="code"
              name="code"
              label="Code"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.code}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
              disabled={formik.values.isBase}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="color"
              name="color"
              label="Color"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.color}
              error={formik.touched.color && Boolean(formik.errors.color)}
              helperText={formik.touched.color && formik.errors.color}
            />
          </Grid>

          {/* segunda fila */}
          <Grid item xs={12} sm={4}>
            <TextField
              id="nombre_comercial"
              name="nombre_comercial"
              label="Nombre Comercial"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.nombre_comercial}
              error={
                formik.touched.nombre_comercial &&
                Boolean(formik.errors.nombre_comercial)
              }
              helperText={
                formik.touched.nombre_comercial &&
                formik.errors.nombre_comercial
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="nombre"
              name="nombre"
              label="Descripcion"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.nombre}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="multiload_name"
              name="multiload_name"
              label="Descripcion Larga"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.multiload_name}
              error={
                formik.touched.multiload_name &&
                Boolean(formik.errors.multiload_name)
              }
              helperText={
                formik.touched.multiload_name && formik.errors.multiload_name
              }
            />
          </Grid>

          {/* tercer fila */}
          {/* <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isBase}
                  onChange={(e) => {
                    formik.handleChange(e);
                    if (e.target.checked || !e.target.checked) {
                      formik.setFieldValue("isBaseWAdd", false);
                      formik.setFieldValue("productos_base", []);
                      formik.setFieldValue("aditivo", []);
                      formik.setFieldValue("aditivo_marcador", []);
                      formik.setFieldValue("muestra", "");
                      formik.setFieldValue("aditive", []);
                    }
                    formik.validateForm(); // Validar formulario nuevamente al cambiar isBase
                  }}
                  name="isBase"
                  color="primary"
                />
              }
              label="Crear Producto Simple"
            />
            <Tooltip
              title="Establece el porcentaje del componente al 100%"
              placement="top"
            >
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid> */}
          {/* <Grid item xs={12} sm={4}>
            {formik.values.isBase ? (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.isBaseWAdd}
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (!e.target.checked) {
                          formik.setFieldValue("aditivo", []);
                          formik.setFieldValue("aditive", []);
                        }
                      }}
                      name="isBaseWAdd"
                      color="primary"
                    />
                  }
                  label="Añadir aditivo"
                />
                <Tooltip
                  title="Crear componente base al 100% con un aditivo"
                  placement="top"
                >
                  <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
                </Tooltip>
              </>
            ) : (
              []
            )}
          </Grid> */}
          {/* <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.setMLII}
                  onChange={formik.handleChange}
                  name="setMLII"
                  color="primary"
                />
              }
              label="Crear en MLII"
            />
            <Tooltip
              title="Crear mezcla o componente base en el MLII"
              placement="top"
            >
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid> */}
        </Grid>
        <br />


        { /* Sección para crear mas de un controlador y brazos */}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Controladores y brazos
                </Typography>
                {formik.values.controlador.map((variable, index) => {
                  const availableArms = selectedControllers[index]?.filter(
                    (arm) =>
                      !formik.values.controlador.some(
                        (ctrl, i) =>
                          i !== index &&
                          ctrl.controlador === variable.controlador &&
                          ctrl.brazo === arm.index
                      )
                  ) || [];

                  return (
                    <React.Fragment key={index}>
                      <Grid container spacing={2}>
                        {/* Selector de Controlador */}
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            required
                            options={CtrlCompFlujo}
                            getOptionLabel={(option) =>
                              `${option.modelo} ${option.marca}`
                            }
                            value={
                              CtrlCompFlujo && CtrlCompFlujo.find(
                                (opt) => opt.id_controlador === variable.controlador
                              ) || null
                            }
                            onChange={(_, value) =>
                              handleControllerChange(index, value)
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Controlador"
                                variant="outlined"
                                required
                              />
                            )}
                          />
                        </Grid>

                        {/* Selector de Brazo */}
                        <Grid item xs={12} sm={5}>
                          <Autocomplete
                            options={
                              selectedControllers[index]
                                ? selectedControllers[index].filter(
                                  (arm) =>
                                    !formik.values.controlador.some(
                                      (item, idx) =>
                                        idx !== index && item.controlador === variable.controlador && item.brazo === arm.id
                                    )
                                )
                                : []
                            }
                            getOptionLabel={(option) => option.nombre_brazo || ""}
                            value={
                              selectedControllers[index] &&
                              selectedControllers[index].find((opt) => opt.id === variable.brazo) || null
                            }
                            onChange={(_, value) => handleArmChange(index, value)}
                            renderInput={(params) => (
                              <TextField {...params} label="Brazo" variant="outlined" required />
                            )}
                          />
                        </Grid>

                        {/* Botón para eliminar */}
                        <Grid item xs={12} sm={1} style={{ display: "flex", alignItems: "flex-end" }}>
                          <Tooltip title="Eliminar" arrow>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() =>
                                formik.setFieldValue(
                                  "controlador",
                                  formik.values.controlador.filter((_, i) => i !== index)
                                )
                              }
                              disabled={formik.values.controlador.length === 1}
                            >
                              <DeleteIcon />
                            </Button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                      <br />
                    </React.Fragment>
                  );
                })}
                {/* Botón para agregar un nuevo controlador */}
                <Grid item xs={12}>
                  <Tooltip title="Agregar" arrow>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={() =>
                        formik.setFieldValue("controlador", [
                          ...formik.values.controlador,
                          { controlador: null, brazo: null },
                        ])
                      }
                    >
                      <AddIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.assigned}
                  onChange={formik.handleChange}
                  name="assigned"
                  color="primary"
                />
              }
              label="Asignar el componente en el MLII"
            />
            <Tooltip
              title="Establece el componente creado como asignado en el MLII"
              placement="top"
            >
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.authorized}
                  onChange={formik.handleChange}
                  name="authorized"
                  color="primary"
                />
              }
              label="Autorizar el componente en el MLII"
            />
            <Tooltip
              title="Establece el aditivo creado como autorizado en el MLII"
              placement="top"
            >
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid>
        </Grid> */}

        {/* sección para elegir el producto base en caso de que sea un producto 100% */}
        {formik.values.isBase ? (
          <>
            {/* <Grid item xs={12}>
              <Autocomplete
                id="index"
                options={ProductoBaseDB?.filter(
                  (producto) => producto.venta === true
                )}
                getOptionLabel={(option) =>
                  option.nombre ? option.nombre : ""
                }
                value={
                  ProductoBaseDB?.find(
                    (opt) => opt.index === formik.values?.index
                  ) || null
                }
                onChange={(_, value) => {
                  formik.setFieldValue("index", value ? value.index : "");
                  const selectedOption = ProductoBaseDB?.find(
                    (option) => option.index === value.index
                  );
                  formik.setFieldValue("code", selectedOption.code);
                  setSelectedBaseProdID(selectedOption.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Componente"
                    variant="outlined"
                    name="index"
                    required
                    error={formik.errors.index && Boolean(formik.errors.index)}
                    helperText=""
                    sx={{ background: "#fcfcfc" }}
                  />
                )}
              />
            </Grid> */}
          </>
        ) : formik.values.isBaseWAdd ? (
          <>
            <Grid item xs={12}>
              <Autocomplete
                id="comp_index"
                options={ProductoBaseDB?.filter(
                  (producto) => producto.venta === true
                )}
                getOptionLabel={(option) =>
                  option.nombre ? option.nombre : ""
                }
                value={
                  ProductoBaseDB?.find(
                    (opt) => opt.id === formik.values?.comp_index
                  ) || null
                }
                onChange={(_, value) => {
                  formik.setFieldValue("comp_index", value ? value.id : "");
                  const selectedOption = ProductoBaseDB?.find(
                    (option) => option.id === value.id
                  );
                  formik.setFieldValue("code", selectedOption.code);
                  setSelectedBaseProdID(selectedOption.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Componente"
                    variant="outlined"
                    name="comp_index"
                    required
                    error={
                      formik.errors.comp_index &&
                      Boolean(formik.errors.comp_index)
                    }
                    helperText=""
                    sx={{ background: "#fcfcfc" }}
                  />
                )}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
        <br />
        {!formik.values.isBase ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Componentes
                    </Typography>
                    {formik.values.productos_base.map((variable, index) => (
                      <React.Fragment key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Autocomplete
                              required
                              id={`productos_base[${index}].producto_base`}
                              name={`productos_base[${index}].producto_base`}
                              label="Producto base"
                              variant="outlined"
                              fullWidth
                              options={ProductoBaseDB?.filter(
                                (producto) => producto.venta === true
                              )}
                              getOptionLabel={(comp_base) =>
                                comp_base.nombre || ""
                              }
                              value={
                                ProductoBaseDB?.find(
                                  (option) =>
                                    option.id === variable.producto_base
                                ) || null
                              }
                              onChange={(event, selectedOption) => {
                                formik.setFieldValue(
                                  `productos_base[${index}].producto_base`,
                                  selectedOption ? selectedOption.id : ""
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label="Componente base"
                                  variant="outlined"
                                />
                              )}
                              error={
                                formik.errors?.productos_base &&
                                Boolean(formik.errors?.productos_base)
                              }
                              helperText={formik.errors?.productos_base}
                            />
                          </Grid>

                          <Grid item xs={5}>
                            <TextField
                              required
                              id={`productos_base[${index}].porcentaje`}
                              name={`productos_base[${index}].porcentaje`}
                              label="Porcentaje de componente"
                              variant="outlined"
                              fullWidth
                              type="number"
                              onChange={(e) => {
                                const value = e.target.value;
                                // Convierte el valor a flotante
                                formik.setFieldValue(`productos_base[${index}].porcentaje`, value === "" ? "" : parseFloat(value) || 0);
                              }}
                              value={variable.porcentaje}
                              error={
                                formik.errors?.productos_base &&
                                Boolean(formik.errors?.productos_base)
                              }
                              helperText={formik.errors?.productos_base}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            style={{ display: "flex", alignItems: "flex-end" }}
                          >
                            <Tooltip title="Eliminar" arrow>
                              <span>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  style={{
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "0",
                                    margin: "0",
                                    color: "rgba(252, 0, 0, 0.8)",
                                    border: "1px solid rgba(252, 0, 0, 0.8)",
                                  }}
                                  disabled={
                                    formik.values.productos_base.length === 1
                                  }
                                  onClick={() =>
                                    formik.setFieldValue(
                                      "productos_base",
                                      formik.values?.productos_base?.filter(
                                        (_, i) => i !== index
                                      )
                                    )
                                  }
                                >
                                  <DeleteIcon style={{ margin: "0" }} />
                                </Button>
                              </span>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <br />
                      </React.Fragment>
                    ))}
                    <Grid item xs={12}>
                      <Tooltip title="Agregar" arrow>
                        <Button
                          fullWidth
                          type="button"
                          style={{
                            color: "rgb(25, 118, 210)",
                            border: "1px solid rgb(25, 118, 210)",
                            "&:hover": {
                              color: "rgba(221, 223, 221, 0.62)",
                              border: "1px solid rgba(221, 223, 221, 0.62)",
                            },
                          }}
                          onClick={() =>
                            formik.setFieldValue("productos_base", [
                              ...formik.values.productos_base,
                              {
                                producto_base: null,
                                porcentaje: null,
                              },
                            ])
                          }
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <br />
          </>
        ) : (
          <></>
        )}

        {/* Sección de aditivos */}
        {!formik.values.isBase || formik.values.isBaseWAdd ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Aditivos
                    </Typography>
                    {formik.values.aditivo.map((variable, index) => (
                      <React.Fragment key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Autocomplete
                              id={`aditivo[${index}].aditivos`}
                              name={`aditivo[${index}].aditivos`}
                              label="Aditivos"
                              variant="outlined"
                              fullWidth
                              options={AditivosDB}
                              getOptionLabel={(aditivo) => aditivo.nombre || ""}
                              value={
                                AditivosDB?.find(
                                  (option) => option.id === variable.aditivos
                                ) || null
                              }
                              onChange={(event, selectedOption) => {
                                formik.setFieldValue(
                                  `aditivo[${index}].aditivos`,
                                  selectedOption ? selectedOption.id : ""
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Aditivos"
                                  variant="outlined"
                                />
                              )}
                              error={
                                formik.errors?.aditivo?.[index]?.aditivo &&
                                Boolean(
                                  formik.errors?.aditivo?.[index]?.aditivo
                                )
                              }
                              helperText={
                                formik.errors?.aditivo?.[index]?.aditivo
                              }
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              id={`aditivo[${index}].porcentaje`}
                              name={`aditivo[${index}].porcentaje`}
                              label="Porcentaje de aditivo"
                              variant="outlined"
                              fullWidth
                              onChange={formik.handleChange}
                              value={variable.porcentaje}
                              error={
                                formik.errors?.aditivo?.[index]
                                  ?.porcentaje &&
                                Boolean(
                                  formik.errors?.aditivo?.[index]
                                    ?.porcentaje
                                )
                              }
                              helperText={
                                formik.errors?.aditivo?.[index]
                                  ?.porcentaje
                              }
                            />
                          </Grid>

                          <Grid item xs={1}>
                            <Tooltip title="Eliminar" arrow>
                              <Button
                                variant="outlined"
                                color="secondary"
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "0",
                                  margin: "0",
                                  color: "rgba(252, 0, 0, 0.8)",
                                  border: "1px solid rgba(252, 0, 0, 0.8)",
                                }}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "aditivo",
                                    formik.values.aditivo?.filter(
                                      (_, i) => i !== index
                                    )
                                  )
                                }
                              >
                                <DeleteIcon style={{ margin: "0" }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <br />
                      </React.Fragment>
                    ))}

                    <Grid item xs={12}>
                      <Tooltip title="Agregar" arrow>
                        <Button
                          fullWidth
                          type="button"
                          style={{
                            color: "rgb(25, 118, 210)",
                            border: "1px solid rgb(25, 118, 210)",
                            "&:hover": {
                              color: "rgba(221, 223, 221, 0.62)",
                              border: "1px solid rgba(221, 223, 221, 0.62)",
                            },
                          }}
                          onClick={() =>
                            formik.setFieldValue("aditivo", [
                              ...formik.values.aditivo,
                              {
                                aditivos: "",
                                porcentaje: "",
                              },
                            ])
                          }
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <br />
          </>
        ) : (
          <></>
        )}

        {/* Sección de aditivos marcadores */}
        {!formik.values.isBase ? (
          <>
            {/* <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Aditivos Marcadores
                    </Typography>
                    {formik.values.aditivo_marcador.map((variable, index) => (
                      <React.Fragment key={index}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Autocomplete
                              id={`aditivo_marcador[${index}].aditivo_marcador`}
                              name={`aditivo_marcador[${index}].aditivo_marcador`}
                              label="Aditivo marcador"
                              variant="outlined"
                              fullWidth
                              options={AditivosDB?.filter(
                                (aditivo) => aditivo.tipo === true
                              )}
                              getOptionLabel={(aditivo) => aditivo.nombre || ""}
                              value={
                                AditivosDB?.find(
                                  (option) =>
                                    option.nombre === variable.aditivo_marcador
                                ) || null
                              }
                              onChange={(event, selectedOption) => {
                                formik.setFieldValue(
                                  `aditivo_marcador[${index}].aditivo_marcador`,
                                  selectedOption ? selectedOption.nombre : ""
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Aditivo marcador"
                                  variant="outlined"
                                />
                              )}
                              error={
                                formik.errors?.aditivo_marcador?.[index]
                                  ?.aditivo_marcador &&
                                Boolean(
                                  formik.errors?.aditivo_marcador?.[index]
                                    ?.aditivo_marcador
                                )
                              }
                              helperText={
                                formik.errors?.aditivo_marcador?.[index]
                                  ?.aditivo_marcador
                              }
                            />
                          </Grid>

                          <Grid item xs={5}>
                            <TextField
                              id={`aditivo_marcador[${index}].aditivo_porcentaje_marcador`}
                              name={`aditivo_marcador[${index}].aditivo_porcentaje_marcador`}
                              label="Porcentaje de aditivo marcador"
                              variant="outlined"
                              fullWidth
                              onChange={formik.handleChange}
                              value={variable.aditivo_porcentaje_marcador}
                              error={
                                formik.errors?.aditivo_marcador?.[index]
                                  ?.aditivo_porcentaje_marcador &&
                                Boolean(
                                  formik.errors?.aditivo_marcador?.[index]
                                    ?.aditivo_porcentaje_marcador
                                )
                              }
                              helperText={
                                formik.errors?.aditivo_marcador?.[index]
                                  ?.aditivo_porcentaje_marcador
                              }
                            />
                          </Grid>

                          <Grid item xs={1}>
                            <Tooltip title="Eliminar variable" arrow>
                              <Button
                                variant="outlined"
                                color="secondary"
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  padding: "0",
                                  margin: "0",
                                  color: "rgba(252, 0, 0, 0.8)",
                                  border: "1px solid rgba(252, 0, 0, 0.8)",
                                }}
                                onClick={() =>
                                  formik.setFieldValue(
                                    "aditivo_marcador",
                                    formik.values?.aditivo_marcador?.filter(
                                      (_, i) => i !== index
                                    )
                                  )
                                }
                              >
                                <DeleteIcon style={{ margin: "0" }} />
                              </Button>
                            </Tooltip>
                          </Grid>
                          <Divider />
                        </Grid>
                        <br />
                      </React.Fragment>
                    ))}

                    <Grid item xs={12}>
                      <Tooltip title="Agregar" arrow>
                        <Button
                          fullWidth
                          type="button"
                          style={{
                            color: "rgb(25, 118, 210)",
                            border: "1px solid rgb(25, 118, 210)",
                            "&:hover": {
                              color: "rgba(221, 223, 221, 0.62)",
                              border: "1px solid rgba(221, 223, 221, 0.62)",
                            },
                          }}
                          onClick={() =>
                            formik.setFieldValue("aditivo_marcador", [
                              ...formik.values.aditivo_marcador,
                              {
                                aditivo_marcador: "",
                                aditivo_porcentaje_marcador: "",
                              },
                            ])
                          }
                        >
                          <AddIcon />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid> */}

            {/* Input de marcador */}
            <Grid item xs={12}>
              <TextField
                id="muestra"
                name="muestra"
                label="Muestra"
                variant="outlined"
                fullWidth
                onChange={formik.handleChange}
                value={formik.values.muestra}
                error={formik.touched.muestra && Boolean(formik.errors.muestra)}
                helperText={formik.touched.muestra && formik.errors.muestra}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}

        <br />
        <Button type="submit" variant="contained" fullWidth style={buttonStyle}>
          {ProductosMezcla ? "Actualizar" : "Crear"}
        </Button>
      </form>
    </FormikProvider>
  );
}
