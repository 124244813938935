import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export const columns = [
 
  {
      header: "Index",
      name: "index",
      maxWidth: 1000,
      defaultFlex: 1
  },
  {
      header: "Código",
      name: "code",
      maxWidth: 1000,
      defaultFlex: 1
  },
  
  {
      header: "Descripción corta",
      name: "nombre",
      maxWidth: 1000,
      defaultFlex: 1
  },
  {
    header: "Descripción Larga",
    name: "multiload_name",
    maxWidth: 1000,
    defaultFlex: 1
},
  {
    header: 'Venta',
    name: 'venta',
    render: (rowData) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center', // Centra verticalmente
          justifyContent: 'center', // Centra horizontalmente
        }}
      >
        {rowData.data.venta ? (
          <CheckIcon style={{ color: 'green' }} />
        ) : (
          <ClearIcon style={{ color: 'red' }} />
        )}
      </div>
    ),
  },
];

export const filterValue = [
  { name: 'id', operator: 'equals', type: 'number', value: null },
  { name: 'index', operator: 'startsWith', type: 'string', value: '' },
  { name: 'code', operator: 'startsWith', type: 'string', value: '' },
  { name: 'multiload_name', operator: 'contains', type: 'string', value: '' },
  { name: 'nombre', operator: 'contains', type: 'string', value: '' },
  { name: 'venta', operator: 'equals', type: 'number', value: null }
];
