import { Autocomplete } from "@mui/material";
import React, { useEffect } from "react";

import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import Button from '@mui/material/Button';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { Formik, useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useProductoBase } from "../../../../hooks";
import { alertas } from "../../../Comons/Alertas/alertas";

const IndexOpts = [
  { key: '001', value: '001', text: '001' },
  { key: '002', value: '002', text: '002' },
  { key: '003', value: '003', text: '003' },
  { key: '004', value: '004', text: '004' },
  { key: '005', value: '005', text: '005' },
  { key: '006', value: '006', text: '006' },
  { key: '007', value: '007', text: '007' },
  { key: '008', value: '008', text: '008' },
  { key: '009', value: '009', text: '009' },
  { key: '010', value: '010', text: '010' },
  { key: '011', value: '011', text: '011' },
  { key: '012', value: '012', text: '012' },
  { key: '013', value: '013', text: '013' },
  { key: '014', value: '014', text: '014' },
  { key: '015', value: '015', text: '015' },
]

export function AddEditProductosBase(props) {
  const { Successful } = alertas();
  const { onClose, onRefetch, Productos } = props;
  const { addProductoBase, updateProductoBase, getProductoBase, ProductoBase } = useProductoBase();

  useEffect(() => {
    getProductoBase();
  }, [])

  const productoBaseResults = ProductoBase?.results || [];
  const indexUsados = new Set(productoBaseResults.map((producto) => producto.index));
  const indexProductoActual = Productos?.index;
  const indexOptionsFiltradas = IndexOpts.filter((opt) => !indexUsados.has(opt.key) || opt.key === indexProductoActual);

  const formik = useFormik({
    initialValues: initialValues(Productos),
    validationSchema: Yup.object(newSchema()),
    validateOnChange: false,
    onSubmit: async (formValues) => {
      try {

        formValues.index = formValues.index.padStart(3, '0')

        //console.log(formValues)
        if (Productos) await updateProductoBase(Productos.id, formValues);
        else await addProductoBase(formValues);
        Successful();
        onClose();
        onRefetch();
      } catch (error) {
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });
  const buttonStyle = {
    backgroundColor: "rgb(204, 48, 43)",
    color: "white",
  };




  return (
    <Formik initialValues={initialValues()}>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="index"
              options={indexOptionsFiltradas}
              getOptionLabel={(option) => option.text}
              value={
                indexOptionsFiltradas.find((opt) => opt.key === formik.values.index) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("index", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Índice del componente"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.index && Boolean(formik.errors.index)}
                  helperText={formik.touched.index && formik.errors.index}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="code"
              name="code"
              label="Código"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.code}
              error={formik.touched.code && Boolean(formik.errors.code)}
              helperText={formik.touched.code && formik.errors.code}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="nombre"
              name="nombre"
              label="Descripción corta"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.nombre}
              error={formik.touched.nombre && Boolean(formik.errors.nombre)}
              helperText={formik.touched.nombre && formik.errors.nombre}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="multiload_name"
              name="multiload_name"
              label="Descripcion Larga"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.multiload_name}
              error={
                formik.touched.multiload_name &&
                Boolean(formik.errors.multiload_name)
              }
              helperText={
                formik.touched.multiload_name && formik.errors.multiload_name
              }
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.venta}
                  onChange={formik.handleChange}
                  name="venta"
                  color="primary"
                />
              }
              style={{
                paddingLeft: '10px'
              }}
              label="Establecer producto para la venta"
            />
            <Tooltip title="Indica que el producto está disponible para que sea comercializado" placement="top">
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button type="submit" variant="contained" className="btn btn-danger" color="error" fullWidth>
              {Productos ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Formik>
  );
}

function initialValues(data) {
  return {
    index: data?.index || "",
    code: data?.code || "",
    multiload_name: data?.multiload_name || "",
    nombre: data?.nombre || "",
    venta: data?.venta || false,
  };
}
function newSchema() {
  return {
    index: Yup.string().required('El campo "index" es obligatorio'),
    code: Yup.string()
      .max(6, 'El campo "Codigo" no puede tener más de 6 caracteres')
      .required('El campo "Codigo" es obligatorio'),
    multiload_name: Yup.string()
      .max(25, 'El campo "Descripcion Larga" no puede tener más de 25 caracteres')
      .required('El campo "Descripcion Larga" es obligatorio'),
    nombre: Yup.string()
      .max(10, 'El campo "Descripcion" no puede tener más de 10 caracteres')
      .required('El campo "Descripcion" es obligatorio'),
    venta: Yup.boolean().required('El campo "venta" es obligatorio'),
  };
}

