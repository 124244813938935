import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  Container,
  Unstable_Grid2 as Grid,
  Pagination,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useAditivos, useProductoBase, useControladores } from "../../../hooks";
import { CompaniesSearch } from "../../../sections/companies/companies-search";
import { CardGrafica } from "./card-grafica";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export function GraficaProductos(props) {
  const { data, agregar, actualizar, eliminar } = props;
  const { ProductoBaseDB, getProductoBaseDB } = useProductoBase();
  const { AditivosDB, getAditivosDB } = useAditivos();
  const { CtrlCompFlujo, getControladorCompFlujo } = useControladores();
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const itemsPerPage = 12; // mostrar 12 mezclas por página

  useEffect(() => {
    getProductoBaseDB();
    getAditivosDB();
    getControladorCompFlujo();
  }, []);

  const displayedData = data?.filter((mezcla) =>
    mezcla.nombre.toLowerCase().includes(searchText.toLowerCase())
  );

  const totalItems = displayedData?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedData = displayedData?.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  // Si la página actual es mayor que el total de páginas,
  // establecer la página actual al último número de página.
  if (page > totalPages) {
    setPage(totalPages);
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (searchValue) => {
    setSearchText(searchValue);
    setPage(1); // Resetear la página actual cuando se realiza una búsqueda
  };

  const convertToCSV = (objArray) => {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    const keys = Object.keys(array[0]);

    str += keys.join(",") + "\r\n";

    array.forEach((obj) => {
      let line = "";
      keys.forEach((key, index) => {
        if (index > 0) line += ",";
        if (typeof obj[key] === "object" && obj[key] !== null) {
          line += `"${JSON.stringify(obj[key]).replace(/"/g, '""')}"`;
        } else {
          line += obj[key];
        }
      });
      str += line + "\r\n";
    });

    return str;
  };

  const handleDownload = () => {
    const csv = convertToCSV(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const CHART_HEIGHT = 220; // El valor 150 representa la altura deseada en píxeles.
  const CHART_HEIGHT_OVER = 50; // El valor 200 representa la altura deseada en píxeles.

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={4}
              alignItems="center"
            >
              <Stack spacing={1}>
                <Typography variant="h4">Administración de productos</Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Button
                    color="inherit"
                    startIcon={
                      <SvgIcon fontSize="small">
                        <ArrowDownOnSquareIcon />
                      </SvgIcon>
                    }
                    onClick={handleDownload}
                  >
                    Descargar
                  </Button>
                </Stack>
              </Stack>
              <div>
              <Button
            variant="contained"
            color="secondary"
            className="btn btn-danger"
            startIcon={<AddIcon />}
                onClick={agregar}
              >
                  CREAR PRODUCTO
                </Button>
              </div>
            </Stack>
            <CompaniesSearch onSearchChange={handleSearchChange} />
            <Grid container spacing={4}>
              {paginatedData.map((mezcla) => (
                <Grid xs={12} md={6} lg={4} key={mezcla.id_mezclas}>
                  <CardGrafica
                    mezcla={mezcla}
                    actualizar={actualizar}
                    eliminar={eliminar}
                    CHART_HEIGHT_OVER={CHART_HEIGHT_OVER}
                    CHART_HEIGHT={CHART_HEIGHT}
                    Producto={ProductoBaseDB}
                    Aditivos={AditivosDB}
                    Controladores={CtrlCompFlujo}
                  />
                </Grid>
              ))}
            </Grid>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Pagination
                count={totalPages}
                size="small"
                page={page}
                onChange={handlePageChange}
              />
            </Box>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
