import React, { useCallback, useEffect, useState } from "react";
import { Input, Modal, Form } from "antd";
import { ContainerInput } from "./styles";
import { useFormik } from "formik";
import { useAlarmasTanques } from "../../../../../hooks";
import * as yup from "yup";
// Define validation schema
const validationSchema = yup.object().shape({
  veryHigh: yup
    .number()
    .when("high", (high, schema) => (high ? schema.min(high, "Muy Alto debe ser mayor que Alto") : schema)),
  high: yup
    .number()
    .when("low", (low, schema) => (low ? schema.min(low, "Alto debe ser mayor que Bajo") : schema)),
  low: yup.number().required("Bajo es requerido"),
  veryLow: yup
    .number()
    .when("low", (low, schema) => (low ? schema.max(low, "Muy Bajo debe ser menor que Bajo") : schema)),
});

export const ModalsAlerts = ({
  modalLevel,
  modalTemp,
  modalFlow,
  open,
  setOpen,
  formCompleted,
  alarmasNivelTanque,
  alarmasTemperTanque
}) => {



  const { updateAlarmasNivelTanque, updateAlarmasTemperTanque } = useAlarmasTanques();


  const modalAlamrsTitle = useCallback(() => {
    if (modalLevel) {
      return "ALARMAS NIVEL DE PRODUCTO";
    } else if (modalTemp) {
      return "ALARMAS TEMPERATURA DE PRODUCTO";
    } else if (modalFlow) {
      return "ALARMAS FLUJO DE PRODUCTO";
    }
  }, [modalFlow, modalLevel, modalTemp]);

  const formik = useFormik({
    initialValues: initialValues(alarmasNivelTanque ? alarmasNivelTanque : alarmasTemperTanque),
    validationSchema,
    onSubmit: async (values) => {
      setOpen(false);
      if (alarmasNivelTanque) {
        await updateAlarmasNivelTanque(alarmasNivelTanque?.id_alarmas, values);
      } else if (alarmasTemperTanque) {
        await updateAlarmasTemperTanque(alarmasTemperTanque?.id_alarmas, values);
      }
    },
  });

  return (
    <Modal
      title={modalAlamrsTitle()}
      open={open}
      onOk={formik.handleSubmit}
      onCancel={() => {
        setOpen(false);
      }}
      centered
    >

      <Form layout="vertical" onFinish={formik.handleSubmit}>
        {formCompleted && (
          <ContainerInput>
            <Form.Item
              label="Muy Alto"
              validateStatus={formik.errors.p_nivel_HH && formik.touched.p_nivel_HH ? "error" : ""}
              help={formik.errors.p_nivel_HH && formik.touched.p_nivel_HH ? formik.errors.p_nivel_HH : null}
            >
              <Input
                id="p_nivel_HH"
                name="p_nivel_HH"
                type="number"
                min="0"
                value={formik.values.p_nivel_HH}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </ContainerInput>
        )}
        <ContainerInput>
          <Form.Item
            label="Alto"
            validateStatus={formik.errors.p_nivel_H && formik.touched.p_nivel_H ? "error" : ""}
            help={formik.errors.p_nivel_H && formik.touched.p_nivel_H ? formik.errors.p_nivel_H : null}
          >
            <Input
              id="p_nivel_H"
              name="p_nivel_H"
              type="number"
              min="0"
              value={formik.values.p_nivel_H}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </ContainerInput>
        <ContainerInput>
          <Form.Item
            label="Bajo"
            validateStatus={formik.errors.p_nivel_L && formik.touched.p_nivel_L ? "error" : ""}
            help={formik.errors.p_nivel_L && formik.touched.p_nivel_L ? formik.errors.p_nivel_L : null}
          >
            <Input
              id="p_nivel_L"
              name="p_nivel_L"
              type="number"
              min="0"
              value={formik.values.p_nivel_L}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Form.Item>
        </ContainerInput>
        {formCompleted && (
          <ContainerInput>
            <Form.Item
              label="Muy Bajo"
              validateStatus={formik.errors.p_nivel_LL && formik.touched.p_nivel_LL ? "error" : ""}
              help={formik.errors.p_nivel_LL && formik.touched.p_nivel_LL ? formik.errors.p_nivel_LL : null}
            >
              <Input
                id="p_nivel_LL"
                name="p_nivel_LL"
                type="number"
                min="0"
                value={formik.values.p_nivel_LL}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Form.Item>
          </ContainerInput>
        )}
      </Form>


    </Modal>
  );
};

function initialValues(data) {

  return {
    p_nivel_HH: data?.p_nivel_HH || "",
    p_nivel_H: data?.p_nivel_H || "",
    p_nivel_L: data?.p_nivel_L || "",
    p_nivel_LL: data?.p_nivel_LL || "",
  };
}

function initialValuesTemperatura(data) {

  return {
    p_temperatura_HH: data?.p_temperatura_HH || "",
    p_temperatura_H: data?.p_temperatura_H || "",
    p_temperatura_L: data?.p_temperatura_L || "",
    p_temperatura_LL: data?.p_temperatura_LL || ""
  };
}
