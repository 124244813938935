// TableAlert component detail information cargadero y descargero

import { Table, Button } from "antd";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import {
  dateNow,
  readAlarmsBay,
  readAlarmsComponent,
  readAlarmsMeter,
  readAlarmsPreset,
} from "../../utils/constants";
import { ContainerButtons, ContainerTable, Text } from "./styles";

export const TableAlert = ({ data }) => {
  const [dataTable, setDataTable] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = useMemo(
    () => [
      {
        title: "Fecha y Hora",
        dataIndex: "fecha",
        key: "fecha",
        width: 140,
        render: (text) => (
          <Text margin={0} textAlign={"center"} fontSize={14}>
            {text}
          </Text>
        ),
      },
      {
        title: "Brazo",
        dataIndex: "brazo",
        key: "brazo",
        render: (text) => (
          <Text margin={0} textAlign={"center"} fontSize={14}>
            {text}
          </Text>
        ),
      },
      {
        title: "Componente/Medidor/Preset",
        dataIndex: "componente",
        key: "componente",
        render: (text, record) => (
          <Text margin={0} textAlign={"center"} fontSize={14}>
           {record.tipoAlarma} # {record.componente}  
          </Text>
        ),
      },
      {
        title: "Evento",
        dataIndex: "evento",
        key: "evento",
        render: (text) => (
          <Text margin={0} textAlign={"center"} fontSize={14}>
            {text}
          </Text>
        ),
      },
    ],
    []
  );

  const dataFind = useCallback(({ data, alarms, number,tipoAlarma }) => {
    const dataArray = [];
    Object.entries(data ?? {}).forEach(([key, value], index) => {
      let active ="0"
      let brazo = 0
      let componente = 0
      if (tipoAlarma === "Alarma Bay") {
        // active = alarms[key][value] === 1 ? "Activo" : "No activo";
        brazo = 0
        componente = 0
      } else {
        active = value[0] === "1";
        brazo = parseInt(value[1]);
        componente = parseInt(value[2])
      };

      if (active) {
        dataArray.push({
          id: key + Math.random(),
          key: Math.random() + index + 1,
          number: number + index,
          fecha: dateNow,
          brazo: brazo === 0 ? "" : brazo,
          componente: 0 ? "No aplica" : componente.toString(),
          tipoAlarma: tipoAlarma,
          evento: alarms[key],
        });
      }
    });
    return dataArray;
  }, []);

  const prevDataRef = useRef([]);

  useEffect(() => {
    const dataArray = [];
    
    const present = dataFind({
      data: data?.read_alarm_preset,
      alarms: readAlarmsPreset,
      number: 1,
      tipoAlarma: "Alarma Preset",
    });
    const component = dataFind({
      data: data?.read_alarms_component,
      alarms: readAlarmsComponent,
      number: present.length,
      tipoAlarma: "Alarma Componente",
    });
    const meter = dataFind({
      data: data?.read_alarms_meter,
      alarms: readAlarmsMeter,
      number: present.length + component.length,
      tipoAlarma: "Alarma Medidor",
    });
    const bay = dataFind({
      data: data?.read_alarms_bay,
      alarms: readAlarmsBay,
      number: present.length + component.length + meter.length,
      tipoAlarma: "Alarma Bay",
    });
  
    dataArray.push(...present, ...component, ...meter, ...bay);
  
    const prevEvents = prevDataRef.current.map((e) => e.evento);
    const newAlarms = dataArray.filter((alarm) => !prevEvents.includes(alarm.evento));

    if (newAlarms.length > 0) {
      setDataTable((prev) => [...prev, ...newAlarms]);
      prevDataRef.current = [...prevDataRef.current, ...newAlarms];
    }

  }, [data, dataFind]);

  const handleDeleteSelected = useCallback(() => {
    const newData = dataTable.filter(
      (item) => !selectedRowKeys.includes(item.key)
    );
    setDataTable(newData);
    setSelectedRowKeys([]);
  }, [dataTable, selectedRowKeys]);

  const handleDeleteAll = useCallback(() => {
    setDataTable([]);
  }, []);

  return (
    <ContainerTable>
      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        columns={columns}
        dataSource={dataTable}
        pagination={false}
        scroll={{
          y: 210,
        }}
        bordered
      />
      <ContainerButtons>
        <Button onClick={handleDeleteSelected}>ACK Selección</Button>
        <Button onClick={handleDeleteAll}>ACK Todas</Button>
      </ContainerButtons>
    </ContainerTable>
  );
};



