import React, { useState } from "react";

import {
  Box,

  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import Button from "@mui/material/Button";
import { OverviewTraffic } from "../../../sections/overview/overview-traffic";

export function CardGrafica(props) {
  const {
    mezcla,
    actualizar,
    eliminar,
    CHART_HEIGHT,
    CHART_HEIGHT_OVER,
    Producto,
    Aditivos,
    Controladores,
  } = props;


  // Estado local para controlar la expansión de la tarjeta
  const [expanded, setExpanded] = useState(false);

  // Función para manejar el evento onMouseEnter
  const handleMouseEnter = () => {
    setExpanded(true);
  };

  // Función para manejar el evento onMouseLeave
  const handleMouseLeave = () => {
    setExpanded(false);
  };

  // Crear los datos necesarios para las gráficas a partir de la mezcla
  const chartSeries = [];
  const labels = [];

  if (
    mezcla.productos_base &&
    Array.isArray(mezcla.productos_base) &&
    mezcla.productos_base?.length > 0
  ) {

    mezcla?.productos_base.forEach((producto) => {
      const productoMezcla = Producto?.find(
        (item) => item.id === producto.producto_base
      );

      if (productoMezcla) {
        chartSeries.push(parseInt(producto.porcentaje));
        labels.push(productoMezcla.nombre);
      }
    });
  } else {
    chartSeries.push(100);
    labels.push(mezcla.nombre || "Nombre Predeterminado");
  }

  console.log("Controladores: ", Controladores)
  console.log("mezcla: ", mezcla)

  return (
    <>
      <Card
        sx={{
          position: "relative",
          transform: expanded ? "scale(1.1)" : "scale(1)",
          zIndex: expanded ? 1 : "auto", // Establece el zIndex solo cuando está expandida
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <CardContent sx={{ my: 3 }}>
          <Typography align="center" gutterBottom variant="h6">
            {mezcla?.nombre}
          </Typography>
          <OverviewTraffic
            chartSeries={chartSeries}
            labels={labels}
            sx={{ height: CHART_HEIGHT, width: "100%" }}
            CHART_HEIGHT_OVER={CHART_HEIGHT_OVER}
            t={1}
            mezcla={mezcla}
          />
          {expanded && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <Box>CODIGO:</Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box>
                    <strong>{mezcla.code}</strong>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <Box>INDEX:</Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box>
                    <strong>{mezcla.index}</strong>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <Box>NOMBRE:</Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box>
                    <strong>{mezcla.nombre}</strong>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <Box>N. COMERCIAL</Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box>
                    <strong>{mezcla.nombre_comercial}</strong>
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <Box>MARCA Y MODELO CONTROLADORES</Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box>
                    {mezcla.controlador?.map((controlador, index) => {
                      const productoControlador = Controladores?.find(
                        (ctrl) => ctrl.id_controlador === controlador.controlador
                      );
                      return (
                        <div key={index}>
                          <strong>
                            {productoControlador
                              ? `${productoControlador.marca} - ${productoControlador.modelo}`
                              : "Desconocido"}
                          </strong>{" "}
                        </div>
                      );
                    })}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" color="text.secondary">
                  <Box>IP DE CONTROLADORES</Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box>
                    {mezcla.controlador?.map((controlador, index) => {
                      const productoControlador = Controladores?.find(
                        (ctrl) => ctrl.id_controlador === controlador.controlador
                      );
                      return (
                        <div key={index}>
                          <strong>
                            {productoControlador
                              ? `${productoControlador.ip}`
                              : "Desconocido"}
                          </strong>{" "}
                        </div>
                      );
                    })}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  <Box>PRODUCTOS MEZCLA</Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box>
                    {mezcla.productos_base?.map((mezclaItem, index) => {
                      const productoMezcla = Producto?.find(
                        (producto) => producto.id === mezclaItem.producto_base
                      );
                      return (
                        <div key={index}>
                          -{" "}
                          <strong>
                            {productoMezcla
                              ? productoMezcla.nombre
                              : "Desconocido"}
                          </strong>{" "}
                          - : <strong>{mezclaItem.porcentaje} %</strong>
                        </div>
                      );
                    })}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary">
                  <Box>ADITIVOS</Box>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <Box>
                    {mezcla.aditivo?.length > 0 && (
                      <>
                        {mezcla.aditivo?.map((aditivoItem, index) => {
                          const addMezcla = Aditivos?.find(
                            (add) => add.id === aditivoItem.aditivo
                          );
                          return (
                            <div key={index}>
                              -{" "}
                              <strong>
                                {addMezcla ? addMezcla.nombre : "Desconocido"}
                              </strong>{" "}
                              - :{" "}
                              <strong>{aditivoItem.aditivo_porcentaje}</strong> %
                            </div>
                          );
                        })}
                      </>
                    )}
                  </Box>
                </Typography>
              </Grid>
              {/* <Grid item xs={12}>
              <hr />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                <Box>ADITIVO MARCADOR</Box>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <Box>
                  {mezcla.aditivo_marcador?.length > 0 && (
                    <>
                      {mezcla.aditivo_marcador?.map((marcadorItem, index) => (
                        <div key={index}>
                          - <strong>{marcadorItem.aditivo_marcador}</strong> -
                          Porcentaje Aditivo Marcador:{" "}
                          <strong>
                            {marcadorItem.aditivo_porcentaje_marcador} %
                          </strong>
                        </div>
                      ))}
                    </>
                  )}
                </Box>
              </Typography>
            </Grid> */}

            </Grid>
          )}
        </CardContent>
        <Divider />

        <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Button
              fullWidth
              sx={{
                backgroundColor: "#444444",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                },
              }}
              onClick={() => actualizar(mezcla)}
            >
              Actualizar
            </Button>
            <Button
              fullWidth
              sx={{
                backgroundColor: "#D0302B",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor: "#BEBEBE", // Color gris cuando se hace hover
                },
              }}
              onClick={() => eliminar(mezcla)}
            >
              Eliminar
            </Button>
          </Stack>
        </Box>
      </Card>
    </>
  );
}
