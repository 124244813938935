// this component is used in the Storage module table alert tank detail

import { Table, Button } from "antd";
import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { dateNow } from "../../../../utils/constants";

import { ContainerButtons, ContainerTable, Text } from "./styles";

export const TableAlertTank = ({ data }) => {
  const [dataTable, setDataTable] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = useMemo(
    () => [
      {
        title: "",
        dataIndex: "number",
        key: "number",
        width: 40,
        render: (text) => (
          <Text margin={0} textAlign={"center"}>
            {text}
          </Text>
        ),
      },
      {
        title: "Fecha y Hora",
        dataIndex: "fecha",
        key: "fecha",
        width: 140,
        render: (text) => (
          <Text margin={0} textAlign={"center"}>
            {text}
          </Text>
        ),
      },
      {
        title: "Evento",
        dataIndex: "evento",
        key: "evento",
        render: (text) => (
          <Text margin={0} textAlign={"center"}>
            {text}
          </Text>
        ),
      },
    ],
    []
  );

  const alarmNivelMajor = useCallback(
    (alamr) => {
      if (data?.nivel_prod >= alamr) {
        return true;
      }
      return false;
    },
    [data]
  );

  const alarmNivelMinor = useCallback(
    (alamr) => {
      if (data?.nivel_prod <= alamr) {
        return true;
      }
      return false;
    },
    [data]
  );

  const prevDataRef = useRef([]);

  useEffect(() => {
    let newData = [];
    if (alarmNivelMajor(data?.alarmas?.p_nivel_aa)) {
      newData.push({
        id: Math.random(),
        number: 1,
        fecha: dateNow,
        evento: `Tanque ${data?.id} Nivel Muy Alto`,
        key: Math.random() * 100,
        level: "max",
      });
    }
    if (alarmNivelMajor(data?.alarmas?.p_nivel_a)) {
      newData.push({
        id: Math.random(),
        number: 2,
        fecha: dateNow,
        evento: `Tanque ${data?.id} Nivel Alto`,
        key: Math.random() * 100,
        level: "min",
      });
    }
    if (alarmNivelMinor(data?.alarmas?.p_nivel_b)) {
      newData.push({
        id: Math.random(),
        number: 3,
        fecha: dateNow,
        evento: `Tanque ${data?.id} Nivel Bajo`,
        key: Math.random() * 100,
        level: "min",
      });
    }
    if (alarmNivelMinor(data?.alarmas?.p_nivel_bb)) {
      newData.push({
        id: Math.random(),
        number: 4,
        fecha: dateNow,
        evento: `Tanque ${data?.id} Nivel Muy Bajo`,
        key: Math.random() * 100,
        level: "max",
      });
    }

    const prevEvents = prevDataRef.current.map((e) => e.evento);
    const newAlarms = newData.filter((alarm) => !prevEvents.includes(alarm.evento));

    if (newAlarms.length > 0) {
      setDataTable((prev) => [...prev, ...newAlarms]);
      prevDataRef.current = [...prevDataRef.current, ...newAlarms];
    }

  }, [data, alarmNivelMajor, alarmNivelMinor]);

  const handleDeleteSelected = useCallback(() => {
    const newData = dataTable.filter(
      (item) => !selectedRowKeys.includes(item.key)
    );
    setDataTable(newData);
    setSelectedRowKeys([]);
  }, [dataTable, selectedRowKeys]);

  const handleDeleteAll = useCallback(() => {
    setDataTable([]);
  }, []);

  return (
    <ContainerTable major={dataTable.map((item) => item.level === "max")}>
      <Table
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        rowClassName={(record) => {
          if (record.level === "max") {
            return "max";
          }
          return "min";
        }}
        columns={columns}
        dataSource={dataTable}
        pagination={false}
        scroll={{
          y: 210,
        }}
        bordered
      />
      <ContainerButtons>
        <Button onClick={handleDeleteSelected}>ACK Selección</Button>
        <Button onClick={handleDeleteAll}>ACK Todas</Button>
      </ContainerButtons>
    </ContainerTable>
  );
};
