import { useFormik, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox';
import { Autocomplete } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';

import * as Yup from "yup";
import { useAditivos, useMultiLdProd, useControladores } from "../../../hooks";

import { alertas } from "../../Comons/Alertas/alertas";

const IndexOpts = [
  { key: '016', value: '016', text: '016' },
  { key: '017', value: '017', text: '017' },
  { key: '018', value: '018', text: '018' },
  { key: '019', value: '019', text: '019' },
  { key: '020', value: '020', text: '020' },
  { key: '021', value: '021', text: '021' },
  { key: '022', value: '022', text: '022' },
  { key: '023', value: '023', text: '023' },
  { key: '024', value: '024', text: '024' },
  { key: '025', value: '025', text: '025' },
  { key: '026', value: '026', text: '026' },
  { key: '027', value: '027', text: '027' },
  { key: '028', value: '028', text: '028' },
  { key: '029', value: '029', text: '029' },
  { key: '030', value: '030', text: '030' },

]

export function AddEditAditivos(props) {
  /*
   Componente
 
   -componente de visualizacion del modal de edicion y creacion para Aditivos
   
   -se crea y valida mediante formik y yup
   -Se obtienen datos para dropdown mediante hooks
   -se obtiene los datos (para edicion) y funciones correspondientes mediante props desde su PageAdmin
    */
  const { Successful } = alertas();
  const { onClose, onRefetch, Aditivos } = props;
  const { addAditivos, updateAditivos, getAditivos, Aditivos: aditivosData } = useAditivos();
  const { handleAddMultiLdProd } = useMultiLdProd()
  const { CtrlCompFlujo, getControladorCompFlujo } = useControladores();
  const [selectedController, setSelectedController] = useState('')
  const [selectedArms, setSelectedArms] = useState([])

  useEffect(() => {
    getControladorCompFlujo();
    getAditivos();
  }, [])

  const aditivosResults = aditivosData?.results || [];
  const indexUsados = new Set(aditivosResults.map((producto) => producto.codigo_aditivo));
  const indexAditivoActual = Aditivos?.codigo_aditivo;
  const indexOptionsFiltradas = IndexOpts.filter((opt) => !indexUsados.has(opt.key) || opt.key === indexAditivoActual);

  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Aditivos),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(Aditivos ? updateSchame() : newSchame()),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        // establece el índice de esta forma 000 si se elige que el aditivo sea creado en el MLII
        if (formValue.setMLII) {
          formValue.index = formValue.codigo_aditivo.padStart(3, '0')
        }

        // evita que se cree el aditivo si no hay brazo seleccionado
        if (formValue.setMLII && selectedArms.length <= 0) {
          toast.error("No ha incluido el brazo", {
            position: "top-center",
          });

          return
        }

        // crea el aditivo en el MLII
        if (Aditivos && formValue.setMLII) {
          formValue.is_updating = true

          await handleAddMultiLdProd(formValue)
        } else if (formValue.setMLII) {
          await handleAddMultiLdProd(formValue)
        }


        if (Aditivos) await updateAditivos(Aditivos.id, formValue);
        else await addAditivos(formValue);

        Successful();

        onRefetch();
        onClose();

      } catch (error) {
        if (error?.message) {
          // Muestra un mensaje de error si ocurre algún problema
          toast.error(error.message, {
            position: "top-center",
          });
        }
      }
    },
  });

  return (
    <Formik initialValues={initialValues()}>

      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={2} sm={6}>
            <Autocomplete
              id="codigo_aditivo"
              options={indexOptionsFiltradas}
                getOptionLabel={(option) => option.text}
                value={
                  indexOptionsFiltradas.find((opt) => opt.key === formik.values.codigo_aditivo) || null
                }
              onChange={(_, value) => {
                formik.setFieldValue("codigo_aditivo", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Índice del aditivo"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.codigo_aditivo && Boolean(formik.errors.codigo_aditivo)}
                  helperText={formik.touched.codigo_aditivo && formik.errors.codigo_aditivo}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={2} sm={4}>
            <TextField
              fullWidth
              label="Nombre del Aditivo"
              name="nombre"
              value={formik.values.nombre}
              onChange={formik.handleChange}
              error={formik.errors.nombre}
              required
            />
          </Grid> */}
          <Grid item xs={2} sm={6}>
            <TextField
              fullWidth
              label="Código"
              variant="outlined"
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          {/* <Grid item xs={2} sm={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.tipo}
                  onChange={formik.handleChange}
                  name="tipo"
                  color="primary"
                />
              }
              label="Definir como marcador"
            />
            <Tooltip title="Define al aditivo como marcador" placement="top">
              <HelpIcon style={{ color: "gray", marginBottom: "8px" }} />
            </Tooltip>
          </Grid> */}
          {/* <Grid item xs={2} sm={2}>
            <Autocomplete
              id="controlador"
              options={CtrlCompFlujo}
              getOptionLabel={(option) => option.modelo + " " + option.marca}
              value={
                CtrlCompFlujo?.find(
                  (opt) => opt.id_controlador === formik.values?.controlador
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("controlador", value?.id_controlador || "");
                setSelectedController(value?.id_controlador)
                const selectedArms = CtrlCompFlujo.find((ctrl) => ctrl.id_controlador === value?.id_controlador)
                setSelectedArms(selectedArms?.arms || [])
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Controlador"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.controlador && Boolean(formik.errors.controlador)}
                  helperText={formik.touched.controlador && formik.errors.controlador}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid> */}
          {/* <Grid item xs={2} sm={2}>
            <Autocomplete
              disabled={!selectedController || selectedArms.length <= 0}
              id="arm"
              options={selectedArms}
              getOptionLabel={(option) => option.name ? option.name : ""}
              value={
                selectedArms?.find(
                  (opt) => opt.index === formik.values?.arm
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("arm", value ? value.index : "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Brazo"
                  variant="outlined"
                  name="arm"
                  required
                  error={formik.errors.arm && Boolean(formik.errors.arm)}
                  helperText={selectedArms.length <= 0 ? "No hay brazos" : ""}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />

          </Grid> */}

          <Grid item xs={2} sm={6}>
            <TextField
              fullWidth
              label="Descripción corta"
              variant="outlined"
              name="shortDesc"
              value={formik.values.shortDesc}
              onChange={formik.handleChange}
              required
            />
          </Grid>

          <Grid item xs={2} sm={6}>
            <TextField
              fullWidth
              label="Descripción larga"
              variant="outlined"
              name="longDesc"
              value={formik.values.longDesc}
              onChange={formik.handleChange}
              required
            />
          </Grid>

        </Grid>

        <br />

        {/* <div>
          <div>
            <Typography variant="h6">Variables extras</Typography>
          </div>
          <Container maxWidth="lg">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>

              {formik.values.variables.map((variable, index) => (
                <div key={index}>
                  <Grid container spacing={3}>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label={`Tipo de variable ${index + 1}`}
                        name={`variables[${index}].tipo`}
                        value={variable.tipo}
                        onChange={formik.handleChange}
                        error={formik.errors[`variables[${index}].tipo`]}
                        required
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <TextField
                        fullWidth
                        label={`Valor de variable ${index + 1}`}
                        name={`variables[${index}].valorVariable`}
                        value={variable.valorVariable}
                        onChange={formik.handleChange}
                        error={formik.errors[`variables[${index}].valorVariable`]}
                        required
                      />
                    </Grid>

                    <Grid item xs={1} style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <Tooltip title="Eliminar variable" arrow>
                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{
                            height: '100%', display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0',
                            margin: '0',
                            color: 'rgba(252, 0, 0, 0.8)',
                            border: '1px solid rgba(252, 0, 0, 0.8)',
                          }}
                          onClick={() => formik.setFieldValue('variables', formik.values.variables.filter((_, i) => i !== index))}
                        >
                          <DeleteIcon style={{ margin: '0' }} />
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <br />
                </div>
              ))}

              <br />

              <Tooltip title="Agregar variable" arrow>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  style={{
                    color: 'rgb(25, 118, 210)',
                    border: '1px solid rgb(25, 118, 210)',
                    '&:hover': {
                      color: 'rgba(221, 223, 221, 0.62)',
                      border: '1px solid rgba(221, 223, 221, 0.62)',
                    }
                  }}
                  onClick={() => formik.setFieldValue('variables', [...formik.values.variables, { tipo: '', valorVariable: '' }])}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Paper>
          </Container>

        </div> */}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          className="btn btn-danger"
          color="error"
        >
          {Aditivos ? 'Actualizar' : 'Crear'}
        </Button>

      </form>
    </Formik>
  );
}

// Función para establecer valores iniciales para un objeto
function initialValues(data) {
  // Devuelve un nuevo objeto con las propiedades
  // 'codigo_Aditivos', 'nombre' y 'variables'
  // Si las propiedades no están presentes en el objeto 'data' original,
  // se asignan valores vacíos a ellas
  return {
    codigo_aditivo: data?.codigo_aditivo || "",
    nombre: data?.shortDesc || "",
    variables: data?.variables || [],
    tipo: data?.tipo ? true : false,
    setMLII: data?.setMLII ? true : false,
    index: data?.index || "",
    code: data?.code || "",
    longDesc: data?.longDesc || "",
    shortDesc: data?.shortDesc || "",
    operation: "1",
    controlador: data?.controlador || "",
    assigned: data?.assigned || false,
    authorized: data?.authorized || false,
    arm: data?.arm || "",
    is_updating: false,
  };
}

// Función para crear un nuevo esquema de validación
function newSchame() {
  // Devuelve un objeto con las propiedades 'codigo_Aditivos' y 'nombre'
  // Ambos atributos son requeridos y deben ser un número y una cadena respectivamente
  return {
    codigo_aditivo: Yup.number().required(true).positive('Código del Aditivo debe ser un número positivo')
      .integer('Código del Aditivo must be an integer')
      .min(16, 'Código del Aditivo must be at least 16')
      .max(30, 'Código del Aditivo must be at most 30'),

    //   variables: Yup.object({
    //     tipo: Yup.string().required(true),
    //     valorVariable: Yup.number().required(true),
    // })
  };
}

// Función para actualizar un esquema de validación existente
function updateSchame() {
  // Devuelve un objeto con las propiedades 'codigo_Aditivos' y 'nombre'
  // Ambos atributos son requeridos y deben ser un número y una cadena respectivamente
  return {
    codigo_aditivo: Yup.number().required(true).positive('Código del Aditivo must be a positive number')
      .integer('Código del Aditivo must be an integer')
      .min(16, 'Código del Aditivo must be at least 16')
      .max(30, 'Código del Aditivo must be at most 30'),

    //   variables: Yup.object({
    //     tipo: Yup.string().required(true),
    //     valorVariable: Yup.number().required(true),
    // })
  };
}