// Description: Componente que muestra el detalle de un tanque

import { Button, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useAlarmasTanques } from "../../../../../hooks";

import { NavLink } from "react-router-dom";
import { getStorageId } from "../../../../API/ProcesosApi";
import { eventChannel } from 'redux-saga';
import { ImageTank } from "../ImageTank";
import {
  ContainerButtons,
  ContainerDetail,
  ContainerHeader,
  ContainerTable,
  Title,
} from "./styles";
import { LeftOutlined } from "@ant-design/icons";
import { ModalsAlerts } from "../ModalAlert";
import { TableAlertTank } from "../TableAlertTank";
import { ASGI_API } from "../../../../utils/constants";

const { Column, ColumnGroup } = Table;

export const DetailTank = () => {
  const [data, setData] = useState([]);
  // const [alarm, setAlarm] = useState({
  //   veryHigh: "",
  //   high: "",
  //   low: "",
  //   veryLow: "",
  // });
  const [open, setOpen] = useState(false);
  const [modalLevel, setModalLevel] = useState(false);
  const [modalTemp, setModalTemp] = useState(false);
  const [modalFlow, setModaFlow] = useState(false);
  const [formCompleted, setFormCompleted] = useState(false);

  const { getAlarmasNivelTanque, getAlarmasTemperTanque, alarmasNivelTanque, alarmasTemperTanque } = useAlarmasTanques();
  const [alarm, setAlarm] = useState();

  const idUrl = useMemo(() => {
    const url = window.location.pathname.split("/");
    return url[url.length - 1];
  }, []);

 
  useEffect(() => {
    if (idUrl === null) return;

    // Construir la URL de la conexión WebSocket
    let url = `${ASGI_API}/data/tanques`;
    if (idUrl !== null) {
      url += `?id=${idUrl}`;
    }

    // Crear una instancia del objeto WebSocket
    const socket = new WebSocket(url);

    // Escuchar el evento 'message' para recibir los datos enviados por el servidor
    socket.addEventListener('message', event => {
      const data = JSON.parse(event.data)[0]; // Access the first element of the array
      setData(data);
    });


    // Devolver una función que se ejecutará cuando el componente se desmonte
    return () => {
      // Cerrar la conexión WebSocket
      socket.close();
    };
  }, [idUrl]);

  useEffect(() => {
    if (data?.id_tank && modalLevel) {
      getAlarmasNivelTanque(data?.id_tank);
    } else if (data?.id_tank && modalTemp){
      getAlarmasTemperTanque(data?.id_tank);
    }

  }, [data?.id_tank, modalLevel, modalTemp])

  const dataSourse = useMemo(
    () => [
      {
        key: "1",
        product: "Nivel de prod",
        vacio: `${data?.gov} gal`,
      },
      {
        key: "2",
        product: "Nivel de agua",
        vacio: `${data?.nivel_agua}  gal`,
      },
      {
        key: "3",
        product: "Temperatura",
        vacio: `${data?.temperatura}  °F`,
      },
      {
        key: "4",
        product: "Presión",
        vacio: `${data?.presion}  Bar`,
      },
      {
        key: "5",
        product: "Densidad",
        vacio: `${data?.densidad}  Kg/m3`,
      },
      {
        key: "6",
        product: "Api",
        vacio: `${data?.api}`,
      },
    ],
    [data]
  );

  // console.log("data", data);

  return (
    <>
      <ContainerHeader>
        <NavLink
          to={"/storage"}
          style={{
            marginRight: "85px",
          }}
        >
          <LeftOutlined />
        </NavLink>
        <Title>
          TANQUE <span>{data?.id ?? ""}</span>
        </Title>
      </ContainerHeader>
      <ContainerDetail>
        <div>
          <ImageTank
            data={data && data}
            heightTank={400}
            widthOval={40}
            heightOval={30}
            bottomContainerOval={120}
            leftContainerOval={350}
            fontSizePercent={20}
            rightPercent={265}
            bottomPercent={170}
            detail
          />
        </div>
        <ContainerTable>
          <Table
            bordered
            dataSource={dataSourse}
            pagination={false}
            className="tableTank"
          >
            <ColumnGroup title={`ID Tanque  ${data?.id}`}>
              <Column
                title="Producto"
                dataIndex="product"
                key="product"
                width={140}
              />
              <Column title={data?.producto} dataIndex="vacio" key="vacio" width={200} />
            </ColumnGroup>
          </Table>
        </ContainerTable>
      </ContainerDetail>
      <ContainerButtons>

        {/********************* */}
        <Button
          onClick={() => {
            setOpen(true);
            setModalLevel(true);
            setModalTemp(false);
            setModaFlow(false);
            setFormCompleted(true);
          }}
        >
          Alarmas Nivel
        </Button>

        {/********************* */}
        <Button
          onClick={() => {
            setOpen(true);
            setModalTemp(true);
            setModalLevel(false);
            setModaFlow(false);
            setFormCompleted(true);
          }}
        >
          Alarmas Temper
        </Button>

        {/********************* */}
        <Button
          onClick={() => {
            setOpen(true);
            setModaFlow(true);
            setModalLevel(false);
            setModalTemp(false);
            setFormCompleted(false);
          }}
        >
          Alarmas Flujo
        </Button>

        {(alarmasNivelTanque || alarmasTemperTanque) && (
          <ModalsAlerts
            modalLevel={modalLevel}
            modalTemp={modalTemp}
            modalFlow={modalFlow}
            open={open}
            setOpen={setOpen}
            formCompleted={formCompleted}
            alarmasNivelTanque={alarmasNivelTanque}
            alarmasTemperTanque={alarmasTemperTanque}
          />
        )}

      </ContainerButtons>
      <TableAlertTank data={data} />
    </>
  );
};
