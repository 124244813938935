export const PermAtmPeligrosas = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Capa_1"
    data-name="Capa 1"
    viewBox="240 200 520 520"
    {...props}
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx={471.5}
        cy={491.73}
        r={2.33}
        fx={471.5}
        fy={491.73}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#284158" />
        <stop offset={0.27} stopColor="#263d53" />
        <stop offset={0.54} stopColor="#213347" />
        <stop offset={0.82} stopColor="#182332" />
        <stop offset={0.99} stopColor="#121622" />
      </radialGradient>
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-2"
        cx={573.56}
        cy={626.36}
        r={2.43}
        fx={573.56}
        fy={626.36}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-3"
        cx={491.79}
        cy={453.37}
        r={2.39}
        fx={491.79}
        fy={453.37}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-4"
        cx={454.53}
        cy={517.79}
        r={2.35}
        fx={454.53}
        fy={517.79}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-5"
        cx={464.82}
        cy={632.91}
        r={2.35}
        fx={464.82}
        fy={632.91}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-6"
        cx={474.31}
        cy={622.9}
        r={2.38}
        fx={474.31}
        fy={622.9}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-7"
        cx={584.6}
        cy={560.87}
        r={2.41}
        fx={584.6}
        fy={560.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-8"
        cx={498.37}
        cy={536.04}
        r={2.55}
        fx={498.37}
        fy={536.04}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-9"
        cx={532.81}
        cy={519.61}
        r={2.4}
        fx={532.81}
        fy={519.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-10"
        cx={452.2}
        cy={575.61}
        r={2.4}
        fx={452.2}
        fy={575.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-11"
        cx={570.54}
        cy={588.74}
        r={2.33}
        fx={570.54}
        fy={588.74}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-12"
        cx={606.32}
        cy={589.06}
        r={2.4}
        fx={606.32}
        fy={589.06}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-13"
        cx={426.79}
        cy={592.18}
        r={2.42}
        fx={426.79}
        fy={592.18}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-14"
        cx={404.2}
        cy={597.17}
        r={2.41}
        fx={404.2}
        fy={597.17}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-15"
        cx={488.41}
        cy={524.8}
        r={2.36}
        fx={488.41}
        fy={524.8}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-16"
        cx={548.67}
        cy={524.34}
        r={2.43}
        fx={548.67}
        fy={524.34}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-17"
        cx={432.86}
        cy={597.99}
        r={2.36}
        fx={432.86}
        fy={597.99}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-18"
        cx={459.73}
        cy={604.78}
        r={2.35}
        fx={459.73}
        fy={604.78}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-19"
        cx={457.58}
        cy={509.59}
        r={2.4}
        fx={457.58}
        fy={509.59}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-20"
        cx={499.36}
        cy={608.64}
        r={2.38}
        fx={499.36}
        fy={608.64}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-21"
        cx={398.8}
        cy={611.63}
        r={2.35}
        fx={398.8}
        fy={611.63}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-22"
        cx={477.62}
        cy={615.01}
        r={2.39}
        fx={477.62}
        fy={615.01}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-23"
        cx={568.01}
        cy={620.79}
        r={2.42}
        fx={568.01}
        fy={620.79}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-24"
        cx={515.09}
        cy={527.51}
        r={2.38}
        fx={515.09}
        fy={527.51}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-25"
        cx={577.7}
        cy={583.74}
        r={2.38}
        fx={577.7}
        fy={583.74}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-26"
        cx={529.78}
        cy={489.14}
        r={2.39}
        fx={529.78}
        fy={489.14}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-27"
        cx={626.48}
        cy={620.76}
        r={2.41}
        fx={626.48}
        fy={620.76}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-28"
        cx={445.15}
        cy={621.25}
        r={2.4}
        fx={445.15}
        fy={621.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-29"
        cx={495.72}
        cy={491.32}
        r={2.39}
        fx={495.72}
        fy={491.32}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-30"
        cx={414.22}
        cy={588.42}
        r={2.39}
        fx={414.22}
        fy={588.42}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-31"
        cx={511.35}
        cy={454.1}
        r={2.38}
        fx={511.35}
        fy={454.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-32"
        cx={458.65}
        cy={559.36}
        r={2.41}
        fx={458.65}
        fy={559.36}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-33"
        cx={497.27}
        cy={459.94}
        r={2.33}
        fx={497.27}
        fy={459.94}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-34"
        cx={516.51}
        cy={461.38}
        r={2.35}
        fx={516.51}
        fy={461.38}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-35"
        cx={565.08}
        cy={519.2}
        r={2.4}
        fx={565.08}
        fy={519.2}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-36"
        cx={588.23}
        cy={580.86}
        r={2.34}
        fx={588.23}
        fy={580.86}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-37"
        cx={446.18}
        cy={522.33}
        r={2.34}
        fx={446.18}
        fy={522.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-38"
        cx={531}
        cy={471}
        r={2.38}
        fx={531}
        fy={471}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-39"
        cx={521.57}
        cy={472.25}
        r={2.38}
        fx={521.57}
        fy={472.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-40"
        cx={480.57}
        cy={473.71}
        r={2.35}
        fx={480.57}
        fy={473.71}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-41"
        cx={463.36}
        cy={522.64}
        r={2.34}
        fx={463.36}
        fy={522.64}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-42"
        cx={469.79}
        cy={579.26}
        r={2.34}
        fx={469.79}
        fy={579.26}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-43"
        cx={485.3}
        cy={479.69}
        r={2.39}
        fx={485.3}
        fy={479.69}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-44"
        cx={551.74}
        cy={588.63}
        r={2.41}
        fx={551.74}
        fy={588.63}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-45"
        cx={479.76}
        cy={552.28}
        r={2.33}
        fx={479.76}
        fy={552.28}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-46"
        cx={565.49}
        cy={553.12}
        r={2.36}
        fx={565.49}
        fy={553.12}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-47"
        cx={580.31}
        cy={553.25}
        r={2.34}
        fx={580.31}
        fy={553.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-48"
        cx={405.34}
        cy={605.71}
        r={2.41}
        fx={405.34}
        fy={605.71}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-49"
        cx={578.7}
        cy={544.01}
        r={2.39}
        fx={578.7}
        fy={544.01}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-50"
        cx={479.25}
        cy={488.35}
        r={2.36}
        fx={479.25}
        fy={488.35}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-51"
        cx={507.23}
        cy={489.4}
        r={2.37}
        fx={507.23}
        fy={489.4}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-52"
        cx={388.22}
        cy={624.42}
        r={2.4}
        fx={388.22}
        fy={624.42}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-53"
        cx={458.98}
        cy={637.37}
        r={2.4}
        fx={458.98}
        fy={637.37}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-54"
        cx={564.57}
        cy={526.76}
        r={2.34}
        fx={564.57}
        fy={526.76}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-55"
        cx={502.69}
        cy={496.33}
        r={2.34}
        fx={502.69}
        fy={496.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-56"
        cx={440.71}
        cy={631.43}
        r={2.35}
        fx={440.71}
        fy={631.43}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-57"
        cx={454.71}
        cy={528.2}
        r={2.41}
        fx={454.71}
        fy={528.2}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-58"
        cx={461.91}
        cy={497.72}
        r={2.4}
        fx={461.91}
        fy={497.72}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-59"
        cx={379.86}
        cy={631.43}
        r={2.35}
        fx={379.86}
        fy={631.43}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-60"
        cx={431.87}
        cy={557.72}
        r={2.39}
        fx={431.87}
        fy={557.72}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-61"
        cx={406.98}
        cy={622.78}
        r={2.39}
        fx={406.98}
        fy={622.78}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-62"
        cx={468.52}
        cy={546.46}
        r={2.35}
        fx={468.52}
        fy={546.46}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-63"
        cx={526.76}
        cy={514.32}
        r={2.39}
        fx={526.76}
        fy={514.32}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-64"
        cx={518.8}
        cy={503.34}
        r={2.4}
        fx={518.8}
        fy={503.34}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-65"
        cx={540.33}
        cy={617.71}
        r={2.36}
        fx={540.33}
        fy={617.71}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-66"
        cx={590.28}
        cy={571.64}
        r={2.4}
        fx={590.28}
        fy={571.64}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-67"
        cx={586.35}
        cy={547.09}
        r={2.35}
        fx={586.35}
        fy={547.09}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-68"
        cx={446.12}
        cy={532.62}
        r={2.35}
        fx={446.12}
        fy={532.62}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-69"
        cx={430.45}
        cy={547.85}
        r={2.34}
        fx={430.45}
        fy={547.85}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-70"
        cx={574.56}
        cy={548.72}
        r={2.34}
        fx={574.56}
        fy={548.72}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-71"
        cx={459.26}
        cy={549.17}
        r={2.35}
        fx={459.26}
        fy={549.17}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-72"
        cx={407.56}
        cy={614.39}
        r={2.36}
        fx={407.56}
        fy={614.39}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-73"
        cx={603.78}
        cy={597.22}
        r={2.35}
        fx={603.78}
        fy={597.22}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-74"
        cx={475.51}
        cy={571.16}
        r={2.33}
        fx={475.51}
        fy={571.16}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-75"
        cx={515.57}
        cy={606.92}
        r={2.37}
        fx={515.57}
        fy={606.92}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-76"
        cx={474.59}
        cy={537.91}
        r={2.35}
        fx={474.59}
        fy={537.91}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-77"
        cx={485.12}
        cy={515.04}
        r={2.4}
        fx={485.12}
        fy={515.04}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-78"
        cx={591.18}
        cy={605.86}
        r={2.35}
        fx={591.18}
        fy={605.86}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-79"
        cx={552.65}
        cy={517.28}
        r={2.4}
        fx={552.65}
        fy={517.28}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-80"
        cx={482.68}
        cy={453.9}
        r={2.34}
        fx={482.68}
        fy={453.9}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-81"
        cx={590.51}
        cy={554.39}
        r={2.39}
        fx={590.51}
        fy={554.39}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-82"
        cx={485.65}
        cy={464.61}
        r={2.36}
        fx={485.65}
        fy={464.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-83"
        cx={475.07}
        cy={467.19}
        r={2.39}
        fx={475.07}
        fy={467.19}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-84"
        cx={487.63}
        cy={554.51}
        r={2.41}
        fx={487.63}
        fy={554.51}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-85"
        cx={489.38}
        cy={473.1}
        r={2.36}
        fx={489.38}
        fy={473.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-86"
        cx={504.15}
        cy={477.88}
        r={2.36}
        fx={504.15}
        fy={477.88}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-87"
        cx={465.42}
        cy={608.53}
        r={2.34}
        fx={465.42}
        fy={608.53}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-88"
        cx={521.5}
        cy={486.84}
        r={2.33}
        fx={521.5}
        fy={486.84}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-89"
        cx={537.86}
        cy={486.08}
        r={2.34}
        fx={537.86}
        fy={486.08}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-90"
        cx={518.01}
        cy={495.49}
        r={2.37}
        fx={518.01}
        fy={495.49}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-91"
        cx={559.86}
        cy={496.69}
        r={2.35}
        fx={559.86}
        fy={496.69}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-92"
        cx={550.54}
        cy={497.63}
        r={2.41}
        fx={550.54}
        fy={497.63}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-93"
        cx={472.1}
        cy={501.53}
        r={2.39}
        fx={472.1}
        fy={501.53}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-94"
        cx={503.03}
        cy={506.33}
        r={2.36}
        fx={503.03}
        fy={506.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-95"
        cx={551.9}
        cy={507.33}
        r={2.39}
        fx={551.9}
        fy={507.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-96"
        cx={479.85}
        cy={506.97}
        r={2.39}
        fx={479.85}
        fy={506.97}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-97"
        cx={511.15}
        cy={509.82}
        r={2.34}
        fx={511.15}
        fy={509.82}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-98"
        cx={494.99}
        cy={510.96}
        r={2.36}
        fx={494.99}
        fy={510.96}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-99"
        cx={536.59}
        cy={511}
        r={2.4}
        fx={536.59}
        fy={511}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-100"
        cx={487.29}
        cy={489.53}
        r={2.35}
        fx={487.29}
        fy={489.53}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-101"
        cx={512.16}
        cy={518.4}
        r={2.38}
        fx={512.16}
        fy={518.4}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-102"
        cx={494.88}
        cy={519.27}
        r={2.35}
        fx={494.88}
        fy={519.27}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-103"
        cx={478.42}
        cy={520.53}
        r={2.37}
        fx={478.42}
        fy={520.53}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-104"
        cx={507.24}
        cy={523.87}
        r={2.39}
        fx={507.24}
        fy={523.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-105"
        cx={471.81}
        cy={525.96}
        r={2.38}
        fx={471.81}
        fy={525.96}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-106"
        cx={526.9}
        cy={526.61}
        r={2.35}
        fx={526.9}
        fy={526.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-107"
        cx={496.4}
        cy={528.52}
        r={2.43}
        fx={496.4}
        fy={528.52}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-108"
        cx={576.16}
        cy={530.3}
        r={2.36}
        fx={576.16}
        fy={530.3}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-109"
        cx={535.44}
        cy={529.42}
        r={2.36}
        fx={535.44}
        fy={529.42}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-110"
        cx={566.36}
        cy={533.36}
        r={2.35}
        fx={566.36}
        fy={533.36}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-111"
        cx={581.56}
        cy={534.49}
        r={2.39}
        fx={581.56}
        fy={534.49}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-112"
        cx={418.58}
        cy={581.48}
        r={2.35}
        fx={418.58}
        fy={581.48}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-113"
        cx={558.06}
        cy={538.59}
        r={2.34}
        fx={558.06}
        fy={538.59}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-114"
        cx={572.42}
        cy={539.68}
        r={2.41}
        fx={572.42}
        fy={539.68}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-115"
        cx={549.81}
        cy={539.78}
        r={2.36}
        fx={549.81}
        fy={539.78}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-116"
        cx={438.47}
        cy={541.44}
        r={2.33}
        fx={438.47}
        fy={541.44}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-117"
        cx={481.31}
        cy={544.92}
        r={2.37}
        fx={481.31}
        fy={544.92}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-118"
        cx={441.3}
        cy={550.15}
        r={2.36}
        fx={441.3}
        fy={550.15}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-119"
        cx={566.94}
        cy={577.82}
        r={2.35}
        fx={566.94}
        fy={577.82}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-120"
        cx={410.83}
        cy={578.54}
        r={2.34}
        fx={410.83}
        fy={578.54}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-121"
        cx={470.72}
        cy={512.82}
        r={2.35}
        fx={470.72}
        fy={512.82}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-122"
        cx={559.89}
        cy={582.3}
        r={2.35}
        fx={559.89}
        fy={582.3}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-123"
        cx={426.4}
        cy={583.8}
        r={2.35}
        fx={426.4}
        fy={583.8}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-124"
        cx={441.86}
        cy={563.81}
        r={2.36}
        fx={441.86}
        fy={563.81}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-125"
        cx={592.8}
        cy={564.6}
        r={2.38}
        fx={592.8}
        fy={564.6}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-126"
        cx={405.93}
        cy={589.22}
        r={2.36}
        fx={405.93}
        fy={589.22}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-127"
        cx={438.81}
        cy={589.88}
        r={2.36}
        fx={438.81}
        fy={589.88}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-128"
        cx={559.46}
        cy={590.87}
        r={2.37}
        fx={559.46}
        fy={590.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-129"
        cx={468.82}
        cy={565.31}
        r={2.4}
        fx={468.82}
        fy={565.31}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-130"
        cx={541.97}
        cy={595.74}
        r={2.33}
        fx={541.97}
        fy={595.74}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-131"
        cx={549.89}
        cy={596.65}
        r={2.32}
        fx={549.89}
        fy={596.65}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-132"
        cx={567.27}
        cy={596.65}
        r={2.34}
        fx={567.27}
        fy={596.65}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-133"
        cx={563.16}
        cy={566.71}
        r={2.34}
        fx={563.16}
        fy={566.71}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-134"
        cx={613.03}
        cy={597.3}
        r={2.34}
        fx={613.03}
        fy={597.3}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-135"
        cx={454.33}
        cy={568.23}
        r={2.34}
        fx={454.33}
        fy={568.23}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-136"
        cx={557.47}
        cy={598.81}
        r={2.35}
        fx={557.47}
        fy={598.81}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-137"
        cx={394.91}
        cy={602.47}
        r={2.41}
        fx={394.91}
        fy={602.47}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-138"
        cx={469.36}
        cy={557.15}
        r={2.38}
        fx={469.36}
        fy={557.15}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-139"
        cx={551.87}
        cy={604.48}
        r={2.37}
        fx={551.87}
        fy={604.48}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-140"
        cx={529.69}
        cy={605.02}
        r={2.39}
        fx={529.69}
        fy={605.02}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-141"
        cx={540.25}
        cy={605.1}
        r={2.32}
        fx={540.25}
        fy={605.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-142"
        cx={560.37}
        cy={605.61}
        r={2.4}
        fx={560.37}
        fy={605.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-143"
        cx={610.35}
        cy={607.03}
        r={2.35}
        fx={610.35}
        fy={607.03}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-144"
        cx={472.52}
        cy={609.23}
        r={2.35}
        fx={472.52}
        fy={609.23}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-145"
        cx={437.65}
        cy={571.57}
        r={2.34}
        fx={437.65}
        fy={571.57}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-146"
        cx={454.68}
        cy={609.76}
        r={2.39}
        fx={454.68}
        fy={609.76}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-147"
        cx={545.98}
        cy={610.03}
        r={2.34}
        fx={545.98}
        fy={610.03}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-148"
        cx={522.08}
        cy={610.63}
        r={2.32}
        fx={522.08}
        fy={610.63}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-149"
        cx={507.79}
        cy={611.19}
        r={2.3}
        fx={507.79}
        fy={611.19}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-150"
        cx={534.36}
        cy={612.06}
        r={2.39}
        fx={534.36}
        fy={612.06}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-151"
        cx={620.5}
        cy={612.74}
        r={2.4}
        fx={620.5}
        fy={612.74}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-152"
        cx={446.75}
        cy={614.18}
        r={2.37}
        fx={446.75}
        fy={614.18}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-153"
        cx={587.31}
        cy={614.38}
        r={2.35}
        fx={587.31}
        fy={614.38}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-154"
        cx={597.41}
        cy={615.1}
        r={2.35}
        fx={597.41}
        fy={615.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-155"
        cx={389.52}
        cy={615.45}
        r={2.34}
        fx={389.52}
        fy={615.45}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-156"
        cx={460.21}
        cy={616.9}
        r={2.36}
        fx={460.21}
        fy={616.9}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-157"
        cx={469.27}
        cy={617}
        r={2.34}
        fx={469.27}
        fy={617}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-158"
        cx={399.03}
        cy={620.22}
        r={2.35}
        fx={399.03}
        fy={620.22}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-159"
        cx={558.4}
        cy={557.87}
        r={2.36}
        fx={558.4}
        fy={557.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-160"
        cx={589.33}
        cy={621.25}
        r={2.35}
        fx={589.33}
        fy={621.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-161"
        cx={518.31}
        cy={622.81}
        r={2.35}
        fx={518.31}
        fy={622.81}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-162"
        cx={462.72}
        cy={623.43}
        r={2.33}
        fx={462.72}
        fy={623.43}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-163"
        cx={617.1}
        cy={623.09}
        r={2.33}
        fx={617.1}
        fy={623.09}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-164"
        cx={413.73}
        cy={602.92}
        r={2.37}
        fx={413.73}
        fy={602.92}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-165"
        cx={553.04}
        cy={625.64}
        r={2.34}
        fx={553.04}
        fy={625.64}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-166"
        cx={479.77}
        cy={563.04}
        r={2.33}
        fx={479.77}
        fy={563.04}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-167"
        cx={469.81}
        cy={628.24}
        r={2.35}
        fx={469.81}
        fy={628.24}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-168"
        cx={403.94}
        cy={630.86}
        r={2.37}
        fx={403.94}
        fy={630.86}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-169"
        cx={509.91}
        cy={630.39}
        r={2.37}
        fx={509.91}
        fy={630.39}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-170"
        cx={575.85}
        cy={575.25}
        r={2.34}
        fx={575.85}
        fy={575.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-171"
        cx={454.32}
        cy={633.72}
        r={2.32}
        fx={454.32}
        fy={633.72}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-172"
        cx={450.41}
        cy={641.6}
        r={2.36}
        fx={450.41}
        fy={641.6}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-173"
        cx={527.39}
        cy={455.17}
        r={2.36}
        fx={527.39}
        fy={455.17}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-174"
        cx={496.84}
        cy={468.57}
        r={2.36}
        fx={496.84}
        fy={468.57}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-175"
        cx={495.71}
        cy={480.75}
        r={2.36}
        fx={495.71}
        fy={480.75}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-176"
        cx={452.9}
        cy={620.21}
        r={2.36}
        fx={452.9}
        fy={620.21}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-177"
        cx={580.01}
        cy={618.79}
        r={2.36}
        fx={580.01}
        fy={618.79}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-178"
        cx={450.62}
        cy={542.67}
        r={2.35}
        fx={450.62}
        fy={542.67}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-179"
        cx={556.92}
        cy={572.01}
        r={2.34}
        fx={556.92}
        fy={572.01}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-180"
        cx={489.7}
        cy={537.76}
        r={2.34}
        fx={489.7}
        fy={537.76}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-181"
        cx={425.41}
        cy={576.14}
        r={2.36}
        fx={425.41}
        fy={576.14}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-182"
        cx={418.71}
        cy={571.07}
        r={2.34}
        fx={418.71}
        fy={571.07}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-183"
        cx={505.34}
        cy={621.46}
        r={2.38}
        fx={505.34}
        fy={621.46}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-184"
        cx={494.74}
        cy={502.54}
        r={2.33}
        fx={494.74}
        fy={502.54}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-185"
        cx={447.07}
        cy={582.82}
        r={2.35}
        fx={447.07}
        fy={582.82}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-186"
        cx={509.64}
        cy={499.4}
        r={2.36}
        fx={509.64}
        fy={499.4}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-187"
        cx={566.92}
        cy={511.56}
        r={2.34}
        fx={566.92}
        fy={511.56}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-188"
        cx={571.27}
        cy={558.47}
        r={2.37}
        fx={571.27}
        fy={558.47}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-189"
        cx={556.46}
        cy={524.87}
        r={2.35}
        fx={556.46}
        fy={524.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-190"
        cx={540.8}
        cy={624.2}
        r={2.35}
        fx={540.8}
        fy={624.2}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-191"
        cx={436.91}
        cy={581.1}
        r={2.33}
        fx={436.91}
        fy={581.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-192"
        cx={519.91}
        cy={512.23}
        r={2.4}
        fx={519.91}
        fy={512.23}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-193"
        cx={457.83}
        cy={628.42}
        r={2.34}
        fx={457.83}
        fy={628.42}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-194"
        cx={502.11}
        cy={518.47}
        r={2.39}
        fx={502.11}
        fy={518.47}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-195"
        cx={448.37}
        cy={629.44}
        r={2.35}
        fx={448.37}
        fy={629.44}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-196"
        cx={415.02}
        cy={630.55}
        r={2.35}
        fx={415.02}
        fy={630.55}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-197"
        cx={482.89}
        cy={498.33}
        r={2.35}
        fx={482.89}
        fy={498.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-198"
        cx={527.86}
        cy={505.05}
        r={2.35}
        fx={527.86}
        fy={505.05}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-199"
        cx={540.7}
        cy={496.1}
        r={2.34}
        fx={540.7}
        fy={496.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-200"
        cx={498.03}
        cy={630.31}
        r={2.34}
        fx={498.03}
        fy={630.31}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-201"
        cx={520.16}
        cy={449.99}
        r={2.34}
        fx={520.16}
        fy={449.99}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-202"
        cx={533.56}
        cy={630.38}
        r={2.33}
        fx={533.56}
        fy={630.38}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-203"
        cx={601.25}
        cy={607.2}
        r={2.35}
        fx={601.25}
        fy={607.2}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-204"
        cx={551.82}
        cy={578.18}
        r={2.37}
        fx={551.82}
        fy={578.18}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-205"
        cx={545.28}
        cy={491.23}
        r={2.35}
        fx={545.28}
        fy={491.23}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-206"
        cx={449.3}
        cy={556.58}
        r={2.35}
        fx={449.3}
        fy={556.58}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-207"
        cx={513.52}
        cy={617.35}
        r={2.35}
        fx={513.52}
        fy={617.35}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-208"
        cx={461.8}
        cy={485.98}
        r={2.38}
        fx={461.8}
        fy={485.98}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-209"
        cx={541.3}
        cy={504.32}
        r={2.36}
        fx={541.3}
        fy={504.32}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-210"
        cx={552.06}
        cy={483.54}
        r={2.33}
        fx={552.06}
        fy={483.54}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-211"
        cx={474.58}
        cy={481.59}
        r={2.34}
        fx={474.58}
        fy={481.59}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-212"
        cx={466.8}
        cy={535.59}
        r={2.35}
        fx={466.8}
        fy={535.59}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-213"
        cx={607.37}
        cy={703.5}
        r={5.54}
        fx={607.37}
        fy={703.5}
      />
      <style>{".cls-148{stroke-width:0;fill:#1d1d1b}"}</style>
    </defs>
    <rect
      width={431.43}
      height={654.74}
      x={286.65}
      y={129.23}
      rx={76.35}
      ry={76.35}
      style={{
        fill: "#fff",
        stroke: "#1d1d1b",
        strokeMiterlimit: 10,
        strokeWidth: 21,
      }}
    />
    <rect
      width={317.89}
      height={324.69}
      x={343.42}
      y={425.33}
      rx={46.15}
      ry={46.15}
      style={{
        fill: "#f49a33",
        strokeWidth: 19,
        stroke: "#1d1d1b",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M482.83 593.07c-3.3 2.8-5.78 5.37-10.1 3.35-1.53-.71-3.65-.47-5.44-.24-15.44 2.02-30.9 20.09-30.49 35.54.22 8.42 5.52 13.63 13.92 12.97 12.07-.95 20.1-8.36 26.49-17.82 4.23-6.27 6.45-13.17 5.24-20.9-.41-2.63.29-3.23 5.33-5.58 3.66 7.39 3.96 14.83.51 22.26-2.16 4.63-5.04 8.93-7.17 13.58-1.26 2.75-2.11 5.85-2.35 8.86-.38 4.83.06 9.72-.17 14.58-.14 2.99.76 4.65 3.55 6.1 17.25 8.97 34.4 18.14 51.54 27.32 7.45 3.99 9.97 11.22 6.38 17.76-3.67 6.7-11 8.65-18.35 4.76-20.43-10.83-40.82-21.75-61.26-32.56-4.92-2.6-7.36-6.54-7.41-12.04-.03-3.15.09-6.31-.1-9.46-.04-.76-1.02-1.46-1.57-2.18-.53.76-1.54 1.53-1.51 2.27.21 6.98-1.24 14.22 3.73 20.48.82 1.04.6.66 2.92 2.98 8.7 4.35 12.77 6.3 20.37 10.28 15.94 8.35 31.84 16.74 47.76 25.13.07.04.05.22.15.7-1.29.07-2.52.19-3.76.2-34.19.01-68.38.05-102.57 0-21.66-.03-34.21-21.46-23.67-40.31 15.25-27.29 30.51-54.57 45.82-81.83 7.67-13.65 24.24-17.96 37.4-9.81 1.54.95 2.9 2.19 4.79 3.64ZM525.41 590.52c-3.95-10.87-11.6-14.32-21.97-9.53-4.74 2.19-9.17 5.04-14.13 7.82-2.53-2.8-5.34-5.54-7.69-8.64-1.89-2.49-1.61-5.41.82-7.44 7.18-5.98 11.78-13.48 14.89-22.26 1.5-4.25 3.53-8.98 9.24-9.71.36-.05.82-.3.99-.6 3.48-5.89 9.49-5.59 14.99-5.67 6.79-.09 12.81 2.57 17.79 7.28 9.83 9.31 11.52 23.31 4.14 34.82-2.83 4.42-5.84 8.72-8.83 13.04-4.33 6.28-5.67 6.36-10.37.75l.13.13Zm9.38-15.98c-1.6-1.04-2.65-2.26-3.5-2.14-.95.13-1.73 1.48-2.59 2.3.94.89 1.78 2.23 2.86 2.49.67.16 1.73-1.36 3.23-2.65Z"
      className="cls-148"
    />
    <path
      d="M532.9 721.37c7.21-3.5 11.61-8.4 11.8-16.32.2-7.95-4.6-12.68-11.09-16.79 1.2-.08 2.4-.22 3.6-.22 19.97 0 39.94.03 59.92 0 4.02 0 7.53.7 9.6 4.69.59 1.13 1.62 2.09 2.61 2.93 5.12 4.38 6.68 9.61 3.73 15.81-2.96 6.22-7.87 9.81-14.88 9.85-15.37.1-30.75.03-46.12.03h-19.18Zm75.46-12.12c2.96-.43 5.09-1.8 4.08-4.99-.9-2.86-3.16-2.63-5.44-1.56-.35.17-.78.47-1.09.38-.81-.23-1.79-.44-2.29-1.02-.33-.39-.16-1.5.16-2.09.46-.84 1.25-1.49 1.9-2.22-2.54.42-4.25 1.68-3.43 4.34.79 2.59 2.79 2.54 5.1 1.89 1.17-.33 2.56.12 3.84.21-.51 1.31-.96 2.65-1.57 3.92-.23.47-.83.76-1.26 1.13ZM525.28 590.39c-2.47 6.86-9.2 8.54-17.12 4.3-7.4 5.59-8.75 5.67-13 .2 7.3-3.9 14.1-8.89 22.73-8.53 2.56.11 5.01 2.7 7.52 4.16l-.13-.13Z"
      className="cls-148"
    />
    <path
      d="M474.03 491.54c-1.36 1.12-2.17 2.32-2.95 2.3-.77-.02-2.06-1.24-2.11-1.99-.04-.75 1.1-2.11 1.86-2.22.77-.12 1.72.97 3.19 1.91Z"
      style={{
        fill: "url(#radial-gradient)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M573.44 629.1c-.98-1.55-2.06-2.51-1.95-3.29.12-.82 1.39-1.47 2.16-2.2.71.79 1.91 1.54 1.97 2.38.05.79-1.1 1.66-2.18 3.12Z"
      style={{
        fill: "url(#radial-gradient-2)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M491.64 450.69c1.08 1.4 2.23 2.23 2.2 3.03-.03.8-1.23 1.56-1.92 2.33-.77-.69-2.06-1.31-2.18-2.1-.11-.79.95-1.74 1.89-3.25Z"
      style={{
        fill: "url(#radial-gradient-3)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M457.1 517.94c-1.46.96-2.41 2.07-3.16 1.95-.81-.13-2-1.44-1.97-2.19.03-.75 1.34-1.97 2.15-2.01.77-.05 1.61 1.15 2.98 2.26Z"
      style={{
        fill: "url(#radial-gradient-4)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M467.38 632.78c-1.35 1.09-2.19 2.31-2.95 2.25-.83-.06-2.14-1.27-2.17-2.03-.04-.75 1.18-2.07 1.99-2.2.74-.12 1.69 1.01 3.12 1.98Z"
      style={{
        fill: "url(#radial-gradient-5)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M476.97 623.07c-1.47.93-2.43 2.02-3.21 1.89-.8-.13-1.42-1.42-2.11-2.21.79-.69 1.56-1.9 2.37-1.93.78-.03 1.61 1.16 2.95 2.24Z"
      style={{
        fill: "url(#radial-gradient-6)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M584.46 563.58c-.97-1.52-2.05-2.47-1.93-3.24.13-.82 1.39-1.46 2.16-2.18.71.78 1.92 1.52 1.98 2.34.05.78-1.11 1.63-2.21 3.07Z"
      style={{
        fill: "url(#radial-gradient-7)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M498.46 533.16c1.03 1.65 2.11 2.63 2.08 3.58-.02.75-1.41 1.46-2.19 2.19-.76-.87-1.95-1.66-2.14-2.65-.13-.68 1.11-1.63 2.25-3.12Z"
      style={{
        fill: "url(#radial-gradient-8)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M535.51 519.4c-1.38 1.12-2.21 2.31-2.98 2.28-.83-.03-1.61-1.22-2.41-1.91.7-.78 1.32-2.06 2.13-2.21.76-.14 1.74.92 3.26 1.84Z"
      style={{
        fill: "url(#radial-gradient-9)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M454.89 575.47c-1.39 1.09-2.24 2.26-3.01 2.21-.82-.05-1.58-1.25-2.36-1.95.71-.77 1.33-2.05 2.14-2.18.76-.12 1.72.96 3.22 1.91Z"
      style={{
        fill: "url(#radial-gradient-10)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M573.08 588.61c-1.37 1.1-2.21 2.29-2.96 2.24-.81-.06-2.1-1.28-2.12-2.02-.02-.76 1.16-2.06 1.96-2.19.74-.12 1.68 1 3.11 1.97Z"
      style={{
        fill: "url(#radial-gradient-11)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M606.23 591.75c-.97-1.49-2.07-2.43-1.96-3.21.11-.81 1.39-1.45 2.15-2.17.7.77 1.91 1.51 1.96 2.32.05.78-1.09 1.64-2.15 3.06Z"
      style={{
        fill: "url(#radial-gradient-12)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M426.72 589.42c1.06 1.5 2.17 2.39 2.1 3.17-.07.83-1.27 1.57-1.97 2.35-.74-.74-1.97-1.42-2.08-2.25-.11-.78.97-1.72 1.95-3.26Z"
      style={{
        fill: "url(#radial-gradient-13)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M406.92 597.39c-1.57.92-2.54 1.95-3.31 1.81-.81-.14-1.44-1.39-2.15-2.15.8-.69 1.56-1.87 2.4-1.92.77-.04 1.61 1.13 3.06 2.26Z"
      style={{
        fill: "url(#radial-gradient-14)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M490.98 524.99c-1.48.95-2.46 2.05-3.21 1.91-.81-.15-1.97-1.49-1.93-2.25.04-.74 1.38-1.93 2.2-1.97.77-.03 1.59 1.18 2.95 2.31Z"
      style={{
        fill: "url(#radial-gradient-15)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M548.8 521.57c.96 1.57 2.01 2.52 1.9 3.31-.12.82-1.35 1.49-2.1 2.22-.7-.79-1.9-1.54-1.96-2.37-.05-.8 1.08-1.68 2.16-3.16Z"
      style={{
        fill: "url(#radial-gradient-16)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M433.1 595.41c.93 1.5 2.02 2.49 1.87 3.23-.16.82-1.51 1.98-2.26 1.92-.75-.06-1.91-1.41-1.95-2.24-.04-.75 1.19-1.57 2.35-2.92Z"
      style={{
        fill: "url(#radial-gradient-17)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M459.77 607.36c-1.06-1.43-2.21-2.31-2.13-3.07.08-.81 1.31-2.09 2.04-2.1.76 0 2.03 1.21 2.14 2.02.1.76-1.03 1.68-2.05 3.15Z"
      style={{
        fill: "url(#radial-gradient-18)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M457.65 512.31c-1.04-1.44-2.15-2.3-2.1-3.08.05-.82 1.21-1.57 1.89-2.35.76.71 2.04 1.36 2.15 2.16.11.78-.97 1.73-1.94 3.27Z"
      style={{
        fill: "url(#radial-gradient-19)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M499.45 605.96c.99 1.63 2.07 2.63 1.93 3.41-.15.81-1.41 1.72-2.34 1.94-.43.1-1.76-1.35-1.72-2.05.04-.89 1.05-1.73 2.13-3.3Z"
      style={{
        fill: "url(#radial-gradient-20)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M401.37 611.75c-1.45.98-2.39 2.1-3.15 1.99-.81-.12-2-1.43-1.98-2.19.02-.75 1.33-1.97 2.13-2.03.77-.05 1.62 1.14 2.99 2.23Z"
      style={{
        fill: "url(#radial-gradient-21)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M480.31 615.19c-1.52.93-2.49 1.99-3.26 1.86-.8-.13-1.42-1.4-2.12-2.17.78-.69 1.54-1.89 2.35-1.93.79-.03 1.62 1.13 3.03 2.24Z"
      style={{
        fill: "url(#radial-gradient-22)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M567.82 623.53c-.93-1.55-1.98-2.52-1.85-3.3.13-.82 1.39-1.46 2.16-2.17.7.79 1.9 1.56 1.94 2.39.04.79-1.13 1.64-2.24 3.08Z"
      style={{
        fill: "url(#radial-gradient-23)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M515.05 524.9c1.06 1.39 2.24 2.27 2.17 3.04-.07.83-1.28 2.15-2.05 2.18-.75.04-2.12-1.2-2.21-1.99-.09-.8 1.06-1.74 2.08-3.23Z"
      style={{
        fill: "url(#radial-gradient-24)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M580.36 583.98c-1.51.9-2.49 1.95-3.24 1.81-.81-.16-1.4-1.43-2.09-2.21.8-.68 1.57-1.87 2.39-1.9.76-.03 1.57 1.17 2.94 2.31Z"
      style={{
        fill: "url(#radial-gradient-25)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M529.89 486.48c.98 1.47 2.09 2.41 1.96 3.14-.14.82-1.39 1.46-2.15 2.17-.72-.76-1.92-1.48-2-2.3-.07-.74 1.1-1.6 2.19-3.01Z"
      style={{
        fill: "url(#radial-gradient-26)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M626.24 623.5c-.9-1.56-1.9-2.53-1.78-3.31.13-.81 1.36-1.45 2.1-2.16.7.79 1.9 1.56 1.94 2.38.04.79-1.13 1.63-2.27 3.09Z"
      style={{
        fill: "url(#radial-gradient-27)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M447.83 621.37c-1.47.96-2.42 2.07-3.19 1.95-.81-.12-1.45-1.4-2.17-2.17.78-.71 1.52-1.94 2.34-1.99.78-.05 1.63 1.13 3.02 2.21Z"
      style={{
        fill: "url(#radial-gradient-28)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M495.86 494.03c-1.08-1.43-2.22-2.29-2.17-3.06.06-.82 1.24-1.57 1.94-2.35.75.71 2.01 1.36 2.12 2.16.11.78-.95 1.73-1.89 3.25Z"
      style={{
        fill: "url(#radial-gradient-29)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M414.38 585.73c.93 1.52 1.99 2.49 1.86 3.26-.13.8-1.41 1.42-2.19 2.12-.67-.79-1.85-1.57-1.88-2.38-.02-.79 1.13-1.63 2.2-3.01Z"
      style={{
        fill: "url(#radial-gradient-30)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M513.99 453.97c-1.35 1.07-2.19 2.25-2.95 2.2-.82-.05-1.56-1.27-2.33-1.97.71-.77 1.35-2.04 2.17-2.17.75-.12 1.69.99 3.11 1.94Z"
      style={{
        fill: "url(#radial-gradient-31)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M458.86 562.09c-1.13-1.43-2.31-2.27-2.27-3.04.05-.84 1.24-1.61 1.93-2.41.77.71 2.02 1.34 2.17 2.16.13.77-.91 1.74-1.83 3.28Z"
      style={{
        fill: "url(#radial-gradient-32)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M494.71 459.76c1.47-.92 2.44-2.01 3.21-1.88.79.13 1.94 1.47 1.91 2.23-.03.71-1.4 1.87-2.21 1.9-.78.02-1.59-1.16-2.91-2.25Z"
      style={{
        fill: "url(#radial-gradient-33)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M516.62 463.97c-1.08-1.4-2.26-2.26-2.2-3.03.06-.81 1.27-2.12 2.02-2.15.74-.03 2.04 1.21 2.15 2.01.11.78-1 1.71-1.97 3.17Z"
      style={{
        fill: "url(#radial-gradient-34)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M567.77 519.38c-1.51.93-2.48 2-3.25 1.87-.81-.14-1.43-1.42-2.13-2.2.79-.69 1.56-1.89 2.38-1.92.78-.03 1.61 1.15 3 2.25Z"
      style={{
        fill: "url(#radial-gradient-35)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M588.29 578.27c1.01 1.48 2.11 2.4 2.01 3.16-.11.8-1.37 2.01-2.12 2.01-.73 0-1.96-1.28-2.03-2.08-.07-.77 1.07-1.64 2.14-3.08Z"
      style={{
        fill: "url(#radial-gradient-36)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M448.77 522.47c-1.51.95-2.45 2.01-3.23 1.9-.79-.11-2.01-1.42-1.96-2.13.05-.75 1.34-1.93 2.14-1.98.77-.05 1.62 1.11 3.04 2.2Z"
      style={{
        fill: "url(#radial-gradient-37)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M533.67 471.11c-1.49.97-2.4 2.03-3.15 1.93-.81-.11-1.47-1.32-2.19-2.06.75-.72 1.45-1.95 2.26-2.03.76-.07 1.63 1.08 3.08 2.16Z"
      style={{
        fill: "url(#radial-gradient-38)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M524.24 472.15c-1.41 1.06-2.28 2.21-3.06 2.16-.8-.06-1.52-1.29-2.28-2.01.73-.74 1.39-1.98 2.2-2.08.77-.1 1.7.99 3.14 1.94Z"
      style={{
        fill: "url(#radial-gradient-39)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M483.16 473.59c-1.41 1.09-2.27 2.26-3.03 2.2-.82-.06-2.12-1.27-2.14-2.01-.03-.74 1.19-2.03 2-2.15.76-.11 1.7.99 3.17 1.96Z"
      style={{
        fill: "url(#radial-gradient-40)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M465.92 522.74c-1.43.97-2.36 2.09-3.13 1.98-.8-.11-2.01-1.41-1.99-2.16.02-.74 1.33-1.96 2.13-2.01.78-.05 1.63 1.13 2.99 2.19Z"
      style={{
        fill: "url(#radial-gradient-41)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M469.76 581.86c-1.01-1.48-2.11-2.39-2.03-3.17.09-.8 1.35-2.04 2.08-2.03.74 0 1.96 1.27 2.04 2.07.07.77-1.05 1.66-2.09 3.12Z"
      style={{
        fill: "url(#radial-gradient-42)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M487.99 479.79c-1.48.96-2.41 2.04-3.19 1.94-.81-.11-1.46-1.36-2.18-2.11.76-.71 1.5-1.94 2.3-1.99.79-.05 1.65 1.1 3.07 2.16Z"
      style={{
        fill: "url(#radial-gradient-43)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M554.43 588.46c-1.38 1.1-2.22 2.3-3.01 2.26-.82-.03-1.59-1.26-2.38-1.96.71-.79 1.33-2.09 2.15-2.22.77-.12 1.75.97 3.24 1.91Z"
      style={{
        fill: "url(#radial-gradient-44)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M482.34 552.13c-1.38 1.09-2.21 2.25-2.98 2.21-.81-.04-2.14-1.22-2.17-1.94-.03-.75 1.15-2.05 1.95-2.18.76-.12 1.71.96 3.2 1.91Z"
      style={{
        fill: "url(#radial-gradient-45)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M565.67 555.7c-1.12-1.38-2.33-2.23-2.28-3 .05-.81 1.26-2.12 2.03-2.17.73-.05 2.05 1.17 2.18 1.98.12.76-.98 1.72-1.93 3.19Z"
      style={{
        fill: "url(#radial-gradient-46)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M580.07 550.68c1.14 1.37 2.34 2.17 2.32 2.95-.02.8-1.18 2.14-1.92 2.2-.73.06-2.07-1.1-2.22-1.89-.14-.76.91-1.74 1.82-3.26Z"
      style={{
        fill: "url(#radial-gradient-47)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M405.47 603.03c.97 1.48 2.07 2.43 1.96 3.21-.12.81-1.41 1.45-2.19 2.17-.71-.78-1.94-1.53-1.99-2.36-.05-.78 1.14-1.63 2.22-3.02Z"
      style={{
        fill: "url(#radial-gradient-48)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M581.38 544.13c-1.52.96-2.47 2.04-3.24 1.92-.8-.12-1.42-1.4-2.13-2.17.77-.69 1.51-1.88 2.32-1.93.77-.05 1.62 1.09 3.04 2.17Z"
      style={{
        fill: "url(#radial-gradient-49)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M481.89 488.36c-1.55 1.02-2.41 2-3.3 2.03-.68.02-2.07-1.32-1.96-1.78.22-.92 1.16-2.17 1.96-2.29.78-.12 1.74 1.01 3.31 2.04Z"
      style={{
        fill: "url(#radial-gradient-50)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M506.98 492.05c-.92-1.66-1.83-2.6-1.78-3.48.04-.68 1.48-1.95 1.95-1.81.89.27 2.06 1.31 2.14 2.12.07.77-1.13 1.64-2.3 3.16Z"
      style={{
        fill: "url(#radial-gradient-51)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M390.91 624.59c-1.49.93-2.46 2.02-3.24 1.9-.8-.12-1.43-1.42-2.13-2.2.79-.7 1.56-1.91 2.38-1.95.78-.04 1.62 1.16 2.99 2.25Z"
      style={{
        fill: "url(#radial-gradient-52)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M458.9 634.68c1.07 1.43 2.22 2.31 2.16 3.09-.07.81-1.3 1.53-2.02 2.3-.76-.73-2.01-1.39-2.12-2.21-.1-.77 1-1.7 1.98-3.17Z"
      style={{
        fill: "url(#radial-gradient-53)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M564.38 529.32c-.94-1.49-2.03-2.46-1.9-3.21.14-.8 1.48-1.95 2.23-1.91.74.04 1.92 1.37 1.95 2.18.03.76-1.17 1.58-2.29 2.94Z"
      style={{
        fill: "url(#radial-gradient-54)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M505.27 496.44c-1.45.97-2.38 2.07-3.14 1.96-.81-.11-2.05-1.4-2.01-2.11.04-.76 1.31-1.97 2.12-2.04.76-.06 1.63 1.11 3.03 2.19Z"
      style={{
        fill: "url(#radial-gradient-55)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M440.94 634.01c-1.15-1.39-2.34-2.21-2.32-2.98.03-.8 1.21-2.14 1.94-2.18.75-.05 2.07 1.12 2.22 1.92.14.75-.92 1.73-1.84 3.25Z"
      style={{
        fill: "url(#radial-gradient-56)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M457.43 528.29c-1.52.98-2.47 2.07-3.24 1.96-.82-.12-1.47-1.38-2.19-2.14.78-.7 1.51-1.89 2.34-1.95.78-.06 1.64 1.07 3.09 2.14Z"
      style={{
        fill: "url(#radial-gradient-57)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M461.8 495.04c1.07 1.39 2.24 2.24 2.2 3.02-.05.82-1.27 1.57-1.98 2.35-.77-.72-2.07-1.36-2.18-2.17-.11-.78 1-1.73 1.96-3.2Z"
      style={{
        fill: "url(#radial-gradient-58)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M380.12 634.01c-1.16-1.38-2.38-2.19-2.35-2.95.03-.81 1.19-2.14 1.94-2.2.73-.06 2.08 1.09 2.23 1.89.15.75-.91 1.74-1.82 3.27Z"
      style={{
        fill: "url(#radial-gradient-59)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M432.07 560.42c-1.11-1.42-2.28-2.25-2.24-3.03.04-.82 1.23-1.58 1.92-2.37.77.7 2.03 1.32 2.15 2.13.12.78-.92 1.74-1.84 3.28Z"
      style={{
        fill: "url(#radial-gradient-60)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M407.06 620.1c.98 1.5 2.08 2.44 1.97 3.2-.12.8-1.38 1.44-2.15 2.15-.71-.76-1.92-1.48-1.97-2.28-.05-.79 1.09-1.64 2.15-3.07Z"
      style={{
        fill: "url(#radial-gradient-61)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M468.66 543.87c.95 1.46 2.05 2.41 1.94 3.19-.11.8-1.43 2.02-2.17 1.98-.75-.04-1.96-1.36-2-2.16-.04-.79 1.14-1.64 2.22-3.01Z"
      style={{
        fill: "url(#radial-gradient-62)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M526.64 511.65c1.07 1.36 2.25 2.21 2.19 2.98-.06.83-1.27 1.57-1.97 2.36-.77-.72-2.05-1.36-2.18-2.17-.12-.76.99-1.71 1.96-3.16Z"
      style={{
        fill: "url(#radial-gradient-63)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M518.94 500.66c.96 1.51 2.03 2.45 1.92 3.21-.12.81-1.37 1.45-2.12 2.17-.71-.77-1.93-1.49-1.99-2.31-.06-.76 1.1-1.62 2.2-3.07Z"
      style={{
        fill: "url(#radial-gradient-64)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M540.46 620.3c-1.09-1.4-2.27-2.26-2.22-3.03.06-.81 1.27-2.12 2.02-2.15.74-.03 2.04 1.19 2.16 2 .11.76-.99 1.71-1.96 3.18Z"
      style={{
        fill: "url(#radial-gradient-65)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M592.96 571.47c-1.38 1.1-2.22 2.28-3 2.24-.82-.04-1.58-1.25-2.36-1.94.71-.78 1.33-2.07 2.14-2.2.77-.12 1.73.96 3.22 1.9Z"
      style={{
        fill: "url(#radial-gradient-66)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M588.92 547.28c-1.47.93-2.45 2.03-3.21 1.9-.8-.14-1.97-1.48-1.93-2.24.04-.74 1.39-1.92 2.21-1.95.77-.03 1.59 1.18 2.93 2.29Z"
      style={{
        fill: "url(#radial-gradient-67)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M448.72 532.74c-1.49.97-2.44 2.07-3.21 1.95-.8-.11-2.01-1.43-1.98-2.17.03-.74 1.34-1.95 2.15-2 .78-.04 1.64 1.13 3.04 2.21Z"
      style={{
        fill: "url(#radial-gradient-68)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M433.02 548.02c-1.47.95-2.42 2.04-3.18 1.92-.8-.13-1.99-1.44-1.95-2.18.04-.76 1.34-1.97 2.14-2.01.77-.04 1.61 1.15 2.99 2.27Z"
      style={{
        fill: "url(#radial-gradient-69)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M577.15 548.61c-1.39 1.07-2.25 2.23-3.02 2.18-.81-.05-2.12-1.26-2.14-2-.03-.74 1.19-2.03 2-2.14.77-.11 1.7 1 3.17 1.96Z"
      style={{
        fill: "url(#radial-gradient-70)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M461.85 549.35c-1.49.94-2.47 2.04-3.22 1.9-.81-.14-1.99-1.47-1.95-2.23.04-.73 1.39-1.91 2.21-1.95.77-.03 1.6 1.16 2.96 2.27Z"
      style={{
        fill: "url(#radial-gradient-71)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M407.65 616.99c-1.07-1.41-2.25-2.28-2.19-3.05.06-.82 1.28-2.13 2.03-2.15.75-.02 2.05 1.22 2.16 2.03.1.78-1.02 1.72-2 3.18Z"
      style={{
        fill: "url(#radial-gradient-72)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M601.16 597.44c1.52-1.16 2.4-2.35 3.15-2.27.8.09 1.83 1.27 2.07 2.17.13.48-1.14 1.88-1.83 1.92-.86.04-1.78-.89-3.4-1.82Z"
      style={{
        fill: "url(#radial-gradient-73)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M478.07 571.25c-1.48.99-2.39 2.08-3.17 1.99-.78-.09-1.97-1.38-1.95-2.11.02-.75 1.27-1.99 2.04-2.04.78-.05 1.64 1.09 3.08 2.16Z"
      style={{
        fill: "url(#radial-gradient-74)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M518.17 607.07c-1.47.96-2.42 2.07-3.19 1.95-.81-.13-2.04-1.45-2-2.19.04-.76 1.37-1.97 2.19-2.02.78-.05 1.63 1.16 3 2.26Z"
      style={{
        fill: "url(#radial-gradient-75)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M474.42 535.32c1.1 1.37 2.3 2.21 2.26 2.98-.04.81-1.24 2.13-2 2.18-.73.05-2.07-1.16-2.19-1.96-.12-.77.98-1.73 1.93-3.2Z"
      style={{
        fill: "url(#radial-gradient-76)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M485.28 512.36c.94 1.49 2.02 2.45 1.9 3.23-.12.8-1.41 1.43-2.18 2.14-.7-.79-1.91-1.55-1.95-2.38-.04-.78 1.14-1.62 2.23-2.99Z"
      style={{
        fill: "url(#radial-gradient-77)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M593.81 605.92c-1.55 1-2.42 1.99-3.29 1.98-.7 0-2.08-1.39-1.96-1.84.24-.91 1.22-2.11 2.05-2.24.74-.12 1.68 1.04 3.2 2.09Z"
      style={{
        fill: "url(#radial-gradient-78)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M552.45 514.59c1.1 1.38 2.28 2.2 2.26 2.99-.03.81-1.22 1.59-1.9 2.39-.79-.69-2.08-1.31-2.21-2.11-.13-.78.94-1.76 1.86-3.28Z"
      style={{
        fill: "url(#radial-gradient-79)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M485.24 453.59c-1.43 1.19-2.19 2.27-3.07 2.38-.65.08-2.11-1.15-2.06-1.69.09-.91.95-2.25 1.74-2.45.74-.19 1.79.87 3.39 1.76Z"
      style={{
        fill: "url(#radial-gradient-80)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M593.2 554.48c-1.52.97-2.45 2.04-3.23 1.94-.8-.11-1.44-1.36-2.15-2.11.76-.7 1.49-1.89 2.3-1.95.78-.05 1.64 1.07 3.08 2.12Z"
      style={{
        fill: "url(#radial-gradient-81)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M488.31 464.8c-1.67.93-2.6 1.85-3.48 1.81-.68-.03-1.97-1.48-1.83-1.91.3-.89 1.33-2.02 2.14-2.09.77-.07 1.66 1.08 3.17 2.2Z"
      style={{
        fill: "url(#radial-gradient-82)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M477.73 467.12c-1.36 1.04-2.22 2.2-3 2.15-.81-.05-1.55-1.27-2.32-1.98.71-.77 1.36-2.06 2.16-2.16.78-.1 1.72 1.02 3.16 1.99Z"
      style={{
        fill: "url(#radial-gradient-83)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M487.6 557.26c-.99-1.5-2.07-2.42-1.99-3.21.09-.82 1.3-1.53 2.02-2.28.72.77 1.93 1.49 2.01 2.31.08.79-1.03 1.7-2.04 3.18Z"
      style={{
        fill: "url(#radial-gradient-84)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M489.62 470.44c.91 1.65 1.82 2.58 1.77 3.46-.04.7-1.48 2-1.89 1.85-.9-.31-2.05-1.33-2.12-2.14-.07-.77 1.11-1.66 2.25-3.17Z"
      style={{
        fill: "url(#radial-gradient-85)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M504.01 480.52c-.96-1.6-1.91-2.49-1.89-3.36.01-.7 1.39-2.04 1.82-1.92.91.26 2.13 1.23 2.24 2.03.1.76-1.06 1.69-2.17 3.25Z"
      style={{
        fill: "url(#radial-gradient-86)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M467.98 608.47c-1.49 1.09-2.43 2.27-3.15 2.16-.8-.13-1.79-1.38-1.96-2.28-.1-.55 1.2-1.93 1.86-1.92.88.02 1.73 1.02 3.25 2.04Z"
      style={{
        fill: "url(#radial-gradient-87)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M524.07 486.77c-1.42 1.06-2.28 2.21-3.04 2.14-.81-.07-2.09-1.27-2.11-2-.01-.75 1.18-2.02 1.99-2.13.75-.11 1.68 1 3.16 1.99Z"
      style={{
        fill: "url(#radial-gradient-88)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M537.92 483.53c1.01 1.43 2.14 2.34 2.04 3.1-.1.8-1.38 2.02-2.13 2-.76-.02-2-1.27-2.07-2.07-.07-.75 1.1-1.63 2.16-3.03Z"
      style={{
        fill: "url(#radial-gradient-89)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M518.2 498.1c-1.13-1.4-2.33-2.25-2.29-3.02.04-.82 1.23-2.13 2-2.19.73-.05 2.06 1.16 2.19 1.97.12.77-.96 1.74-1.9 3.24Z"
      style={{
        fill: "url(#radial-gradient-90)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M559.77 499.28c-1.01-1.56-2.14-2.55-1.98-3.26.18-.82 1.43-1.76 2.34-1.92.53-.09 1.85 1.3 1.82 1.98-.04.86-1.08 1.69-2.18 3.2Z"
      style={{
        fill: "url(#radial-gradient-91)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M550.35 494.94c1.12 1.41 2.31 2.26 2.27 3.05-.04.81-1.28 1.56-2 2.34-.77-.72-2.02-1.36-2.15-2.18-.12-.77.95-1.73 1.88-3.21Z"
      style={{
        fill: "url(#radial-gradient-92)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M474.8 501.45c-1.44 1.05-2.31 2.17-3.09 2.11-.82-.07-1.54-1.27-2.31-1.98.72-.74 1.38-1.98 2.18-2.08.78-.1 1.71.97 3.22 1.95Z"
      style={{
        fill: "url(#radial-gradient-93)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M505.68 506.42c-1.61.99-2.5 1.94-3.38 1.94-.69 0-2.04-1.39-1.91-1.84.25-.9 1.24-2.1 2.04-2.2.77-.1 1.7 1.03 3.25 2.1Z"
      style={{
        fill: "url(#radial-gradient-94)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M552.13 510.02c-1.14-1.41-2.32-2.23-2.29-3.01.03-.81 1.23-1.58 1.91-2.36.77.69 2.04 1.29 2.18 2.09.13.77-.9 1.74-1.81 3.28Z"
      style={{
        fill: "url(#radial-gradient-95)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M479.92 504.26c.98 1.5 2.05 2.42 1.96 3.21-.1.81-1.34 1.48-2.07 2.21-.71-.76-1.92-1.49-1.98-2.3-.06-.79 1.06-1.67 2.1-3.12Z"
      style={{
        fill: "url(#radial-gradient-96)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M513.72 510.01c-1.58.96-2.47 1.93-3.33 1.9-.67-.02-1.93-1.42-1.81-1.95.21-.9 1.23-2.1 2.04-2.22.72-.1 1.62 1.12 3.1 2.27Z"
      style={{
        fill: "url(#radial-gradient-97)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M497.65 510.74c-1.51 1.13-2.41 2.31-3.18 2.24-.81-.08-1.83-1.24-2.13-2.14-.14-.42 1.16-1.86 1.85-1.89.89-.04 1.81.88 3.46 1.8Z"
      style={{
        fill: "url(#radial-gradient-98)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M536.38 508.27c1.11 1.43 2.26 2.26 2.22 3.05-.03.83-1.19 1.61-1.86 2.41-.76-.7-2.02-1.31-2.15-2.12-.13-.79.88-1.76 1.78-3.34Z"
      style={{
        fill: "url(#radial-gradient-99)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M487.17 492.11c-.99-1.57-2.12-2.57-1.96-3.28.18-.81 1.45-1.75 2.36-1.88.54-.08 1.86 1.31 1.81 1.98-.05.87-1.1 1.68-2.22 3.19Z"
      style={{
        fill: "url(#radial-gradient-100)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M514.81 518.32c-1.36 1.04-2.22 2.21-3 2.15-.81-.06-1.53-1.29-2.3-2 .72-.76 1.37-2.03 2.17-2.13.78-.09 1.7 1.02 3.13 1.98Z"
      style={{
        fill: "url(#radial-gradient-101)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M497.46 519.14c-1.36 1.08-2.21 2.28-2.98 2.23-.82-.05-2.13-1.25-2.17-2.01-.04-.74 1.18-2.06 1.98-2.18.76-.11 1.71 1 3.16 1.96Z"
      style={{
        fill: "url(#radial-gradient-102)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M481.08 520.38c-1.49 1.09-2.39 2.26-3.17 2.18-.83-.09-1.86-1.24-2.14-2.14-.14-.45 1.19-1.9 1.88-1.92.89-.03 1.81.93 3.42 1.88Z"
      style={{
        fill: "url(#radial-gradient-103)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M507.25 521.18c1.03 1.49 2.14 2.39 2.04 3.15-.1.81-1.33 1.48-2.07 2.22-.73-.74-1.94-1.42-2.04-2.23-.09-.76 1.02-1.65 2.06-3.13Z"
      style={{
        fill: "url(#radial-gradient-104)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M474.49 525.88c-1.44 1.06-2.31 2.19-3.08 2.12-.81-.07-1.53-1.28-2.28-2 .72-.74 1.38-1.98 2.19-2.09.76-.1 1.68.98 3.17 1.96Z"
      style={{
        fill: "url(#radial-gradient-105)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M526.85 529.2c-1.02-1.55-2.16-2.53-2.02-3.25.16-.81 1.42-1.76 2.33-1.92.53-.09 1.86 1.28 1.83 1.95-.04.87-1.07 1.7-2.14 3.22Z"
      style={{
        fill: "url(#radial-gradient-106)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M496.42 531.27c-1.04-1.51-2.15-2.42-2.08-3.23.07-.81 1.33-1.51 2.06-2.26.73.77 1.96 1.48 2.06 2.33.09.77-1.03 1.69-2.04 3.17Z"
      style={{
        fill: "url(#radial-gradient-107)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M576.39 532.95c-1.14-1.52-2.17-2.31-2.25-3.19-.06-.7 1.18-2.19 1.62-2.11.92.17 2.21 1.02 2.4 1.81.18.76-.85 1.8-1.77 3.49Z"
      style={{
        fill: "url(#radial-gradient-108)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M535.62 526.82c.96 1.58 1.93 2.48 1.91 3.35-.02.67-1.43 1.96-1.95 1.83-.9-.21-2.12-1.25-2.22-2.05-.09-.75 1.12-1.65 2.26-3.13Z"
      style={{
        fill: "url(#radial-gradient-109)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M566.42 530.75c1.01 1.56 2.14 2.54 2 3.29-.15.8-1.41 1.76-2.32 1.93-.51.09-1.84-1.3-1.81-1.98.04-.88 1.06-1.72 2.12-3.24Z"
      style={{
        fill: "url(#radial-gradient-110)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M581.44 531.81c1.09 1.44 2.24 2.3 2.18 3.07-.06.81-1.28 1.54-1.99 2.3-.76-.71-2.01-1.35-2.12-2.15-.11-.77.96-1.7 1.93-3.22Z"
      style={{
        fill: "url(#radial-gradient-111)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M418.45 578.89c1.11 1.52 2.15 2.33 2.21 3.21.04.66-1.26 2.05-1.79 1.97-.9-.14-2.18-1.07-2.34-1.86-.15-.74.94-1.73 1.92-3.32Z"
      style={{
        fill: "url(#radial-gradient-112)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M560.69 538.53c-1.51 1.05-2.44 2.18-3.21 2.08-.81-.11-1.79-1.3-2.04-2.2-.12-.45 1.24-1.83 1.93-1.83.88 0 1.76.98 3.32 1.95Z"
      style={{
        fill: "url(#radial-gradient-113)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M572.36 542.37c-1-1.48-2.11-2.41-2.01-3.18.11-.82 1.37-1.48 2.13-2.21.72.76 1.96 1.47 2.03 2.29.07.78-1.08 1.66-2.14 3.1Z"
      style={{
        fill: "url(#radial-gradient-114)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M549.74 537.16c1.09 1.56 2.23 2.5 2.12 3.25-.12.81-1.33 1.8-2.23 2.01-.5.12-1.87-1.2-1.88-1.88 0-.88.97-1.76 1.98-3.37Z"
      style={{
        fill: "url(#radial-gradient-115)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M441.01 541.31c-1.38 1.1-2.22 2.28-2.98 2.23-.79-.05-2.06-1.27-2.09-2.01-.03-.75 1.14-2.05 1.93-2.17.75-.11 1.69.99 3.14 1.95Z"
      style={{
        fill: "url(#radial-gradient-116)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M481.56 547.59c-1.11-1.35-2.29-2.15-2.28-2.93.01-.81 1.17-1.61 1.83-2.41.79.68 2.08 1.26 2.22 2.05.14.78-.89 1.76-1.77 3.29Z"
      style={{
        fill: "url(#radial-gradient-117)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M443.9 550.3c-1.48.96-2.44 2.07-3.2 1.95-.82-.13-2.01-1.44-1.99-2.21.02-.74 1.37-1.95 2.18-1.99.78-.04 1.62 1.15 3.01 2.25Z"
      style={{
        fill: "url(#radial-gradient-118)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M569.52 577.62c-1.37 1.12-2.2 2.32-2.98 2.29-.81-.03-2.13-1.22-2.18-1.96-.05-.74 1.13-2.08 1.92-2.21.77-.13 1.73.95 3.23 1.88Z"
      style={{
        fill: "url(#radial-gradient-119)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M413.4 578.37c-1.47 1.13-2.36 2.33-3.13 2.26-.78-.07-1.81-1.33-2-2.22-.12-.54 1.15-1.94 1.8-1.94.89 0 1.78.95 3.33 1.91Z"
      style={{
        fill: "url(#radial-gradient-120)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M470.73 510.24c1.04 1.53 2.19 2.5 2.06 3.23-.15.8-1.4 1.77-2.31 1.93-.53.1-1.87-1.26-1.85-1.93.03-.87 1.04-1.71 2.1-3.23Z"
      style={{
        fill: "url(#radial-gradient-121)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M560.2 579.69c.89 1.63 1.81 2.57 1.75 3.44-.05.68-1.49 1.91-1.99 1.76-.89-.25-2.07-1.32-2.13-2.12-.06-.75 1.17-1.61 2.37-3.08Z"
      style={{
        fill: "url(#radial-gradient-122)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M426.46 581.2c1 1.5 2.1 2.42 1.99 3.19-.11.8-1.37 2.01-2.12 2.01-.72 0-1.94-1.3-2-2.1-.06-.78 1.07-1.65 2.13-3.1Z"
      style={{
        fill: "url(#radial-gradient-123)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M441.58 561.15c1.15 1.46 2.35 2.32 2.3 3.08-.06.83-1.18 1.89-2.07 2.22-.42.16-1.92-1.12-1.97-1.81-.06-.88.85-1.83 1.74-3.49Z"
      style={{
        fill: "url(#radial-gradient-124)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M592.77 567.26c-1-1.45-2.11-2.36-2.02-3.12.1-.81 1.34-1.48 2.09-2.21.72.75 1.93 1.45 2.01 2.26.08.76-1.05 1.64-2.08 3.06Z"
      style={{
        fill: "url(#radial-gradient-125)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M408.59 589.12c-1.55 1.07-2.38 2.08-3.26 2.12-.7.04-2.14-1.24-2.05-1.68.2-.92 1.08-2.19 1.88-2.34.77-.15 1.77.92 3.43 1.9Z"
      style={{
        fill: "url(#radial-gradient-126)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M441.47 590.07c-1.64.93-2.57 1.87-3.46 1.84-.69-.03-2-1.48-1.86-1.92.28-.89 1.32-2.04 2.14-2.12.77-.07 1.66 1.09 3.18 2.2Z"
      style={{
        fill: "url(#radial-gradient-127)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M562.13 591.15c-1.54.88-2.53 1.9-3.28 1.74-.8-.17-1.38-1.44-2.05-2.23.8-.66 1.6-1.82 2.42-1.84.77-.01 1.57 1.18 2.92 2.32Z"
      style={{
        fill: "url(#radial-gradient-128)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M468.65 562.58c1.09 1.44 2.23 2.29 2.19 3.06-.05.83-1.21 1.6-1.88 2.39-.76-.7-2.01-1.32-2.14-2.13-.13-.78.9-1.74 1.84-3.33Z"
      style={{
        fill: "url(#radial-gradient-129)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M544.52 595.5c-1.45 1.16-2.22 2.23-3.09 2.33-.64.07-2.05-1.2-2-1.76.09-.9.98-2.21 1.77-2.4.72-.18 1.74.9 3.32 1.83Z"
      style={{
        fill: "url(#radial-gradient-130)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M552.39 596.77c-1.41.98-2.35 2.11-3.06 1.99-.8-.14-1.96-1.44-1.94-2.2.02-.74 1.29-1.97 2.09-2.03.73-.06 1.57 1.15 2.91 2.24Z"
      style={{
        strokeWidth: 0,
        fill: "url(#radial-gradient-131)",
      }}
    />
    <path
      d="M569.83 596.39c-1.43 1.18-2.3 2.43-3.03 2.36-.8-.08-1.86-1.29-2.07-2.18-.13-.55 1.09-1.98 1.76-2.01.86-.04 1.78.91 3.34 1.84Z"
      style={{
        fill: "url(#radial-gradient-132)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M565.73 566.65c-1.51 1.08-2.34 2.1-3.22 2.15-.64.04-2-1.29-1.91-1.84.14-.9 1.09-2.19 1.88-2.33.75-.13 1.72 1 3.26 2.02Z"
      style={{
        fill: "url(#radial-gradient-133)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M615.61 597.39c-1.55 1-2.41 1.98-3.28 1.98-.66 0-1.97-1.35-1.86-1.87.19-.91 1.16-2.16 1.96-2.28.74-.11 1.67 1.07 3.18 2.16Z"
      style={{
        fill: "url(#radial-gradient-134)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M454.2 565.66c1.09 1.4 2.26 2.25 2.21 3.02-.05.8-1.25 2.09-1.99 2.12-.74.03-2.04-1.16-2.16-1.95-.11-.76.97-1.7 1.94-3.19Z"
      style={{
        fill: "url(#radial-gradient-135)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M557.66 596.2c.95 1.59 1.9 2.49 1.87 3.36-.02.68-1.41 1.98-1.89 1.85-.91-.24-2.14-1.24-2.23-2.04-.09-.75 1.11-1.66 2.26-3.17Z"
      style={{
        fill: "url(#radial-gradient-136)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M395.04 599.78c.97 1.49 2.08 2.45 1.96 3.22-.12.81-1.42 1.45-2.2 2.16-.71-.78-1.93-1.53-1.98-2.36-.05-.78 1.13-1.63 2.22-3.03Z"
      style={{
        fill: "url(#radial-gradient-137)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M469.16 559.82c-.93-1.54-1.97-2.5-1.84-3.26.14-.8 1.4-1.4 2.16-2.09.69.77 1.89 1.52 1.93 2.33.04.77-1.13 1.6-2.25 3.02Z"
      style={{
        fill: "url(#radial-gradient-138)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M552.1 607.15c-1.16-1.55-2.34-2.44-2.26-3.22.08-.81 1.26-1.83 2.16-2.11.46-.14 1.89 1.15 1.91 1.84.04.89-.88 1.82-1.81 3.49Z"
      style={{
        fill: "url(#radial-gradient-139)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M529.66 602.34c1.03 1.44 2.14 2.32 2.07 3.1-.07.81-1.28 1.52-1.99 2.28-.74-.73-1.99-1.4-2.08-2.19-.09-.79 1-1.7 1.99-3.19Z"
      style={{
        fill: "url(#radial-gradient-140)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M542.82 604.93c-1.39 1.11-2.2 2.27-2.97 2.24-.8-.03-2.13-1.2-2.16-1.9-.03-.76 1.1-2.07 1.89-2.21.75-.13 1.72.93 3.23 1.87Z"
      style={{
        fill: "url(#radial-gradient-141)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M560.2 602.9c1.09 1.42 2.24 2.26 2.19 3.04-.04.83-1.22 1.59-1.89 2.38-.77-.7-2.03-1.32-2.16-2.13-.12-.78.92-1.74 1.86-3.29Z"
      style={{
        fill: "url(#radial-gradient-142)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M610.46 609.62c-1.1-1.52-2.27-2.44-2.17-3.19.11-.8 1.34-1.8 2.24-2 .52-.11 1.9 1.19 1.9 1.86 0 .88-.98 1.76-1.97 3.33Z"
      style={{
        fill: "url(#radial-gradient-143)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M475.11 609.13c-1.5 1.1-2.32 2.14-3.19 2.19-.67.04-2.06-1.28-1.98-1.81.15-.91 1.08-2.18 1.89-2.36.72-.15 1.72.97 3.28 1.98Z"
      style={{
        fill: "url(#radial-gradient-144)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M437.48 574.15c-.94-1.49-2.01-2.44-1.88-3.19.13-.8 1.43-2 2.15-1.95.75.05 1.93 1.34 1.98 2.15.05.76-1.13 1.6-2.24 3Z"
      style={{
        fill: "url(#radial-gradient-145)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M454.92 607.06c.9 1.55 1.93 2.54 1.79 3.31-.14.8-1.42 1.4-2.2 2.08-.68-.79-1.86-1.57-1.88-2.39-.02-.78 1.15-1.6 2.29-3.01Z"
      style={{
        fill: "url(#radial-gradient-146)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M548.54 610.21c-1.54.96-2.43 1.94-3.29 1.92-.67-.02-1.94-1.42-1.82-1.97.2-.9 1.23-2.12 2.04-2.22.72-.09 1.62 1.13 3.07 2.27Z"
      style={{
        fill: "url(#radial-gradient-147)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M524.64 610.85c-1.52.92-2.48 1.95-3.24 1.82-.78-.13-1.94-1.45-1.88-2.16.06-.75 1.35-1.93 2.13-1.95.78-.02 1.59 1.15 2.99 2.29Z"
      style={{
        fill: "url(#radial-gradient-148)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M510.34 611.78c-1.73.73-2.86 1.64-3.6 1.4-.75-.25-1.5-1.64-1.51-2.54 0-.52 1.56-1.59 2.22-1.45.86.18 1.53 1.3 2.88 2.59Z"
      style={{
        fill: "url(#radial-gradient-149)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M537.06 611.91c-1.42 1.08-2.26 2.22-3.04 2.18-.82-.05-1.58-1.21-2.37-1.89.7-.76 1.32-2.02 2.12-2.14.78-.12 1.73.92 3.28 1.86Z"
      style={{
        fill: "url(#radial-gradient-150)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M623.19 612.54c-1.4 1.12-2.23 2.3-3 2.26-.83-.05-1.59-1.24-2.38-1.94.7-.77 1.33-2.03 2.14-2.17.76-.13 1.72.93 3.24 1.85Z"
      style={{
        fill: "url(#radial-gradient-151)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M447.01 611.51c.9 1.67 1.82 2.61 1.77 3.49-.04.69-1.51 1.98-1.94 1.83-.89-.31-2.03-1.35-2.1-2.18-.06-.77 1.12-1.65 2.27-3.14Z"
      style={{
        fill: "url(#radial-gradient-152)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M589.95 614.23c-1.47 1.08-2.37 2.25-3.14 2.17-.82-.09-1.85-1.24-2.14-2.13-.14-.44 1.19-1.89 1.88-1.91.89-.03 1.8.93 3.39 1.87Z"
      style={{
        fill: "url(#radial-gradient-153)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M597.45 612.46c1 1.56 2.1 2.53 1.97 3.3-.13.81-1.36 1.74-2.27 1.97-.43.11-1.77-1.31-1.75-2.01.03-.88 1.03-1.73 2.05-3.26Z"
      style={{
        fill: "url(#radial-gradient-154)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M389.18 612.87c1.22 1.45 2.46 2.29 2.4 3.04-.06.8-1.24 1.87-2.13 2.12-.51.14-1.96-1.06-2.01-1.74-.06-.86.85-1.8 1.73-3.42Z"
      style={{
        fill: "url(#radial-gradient-155)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M460.32 619.57c-1.08-1.57-2.22-2.51-2.12-3.28.11-.81 1.31-1.75 2.23-2.05.39-.13 1.78 1.25 1.79 1.95 0 .88-.93 1.78-1.9 3.38Z"
      style={{
        fill: "url(#radial-gradient-156)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M469.35 614.44c.99 1.44 2.11 2.36 2.01 3.12-.11.8-1.39 2.01-2.14 2-.74-.01-1.98-1.29-2.04-2.09-.06-.77 1.1-1.63 2.16-3.03Z"
      style={{
        fill: "url(#radial-gradient-157)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M401.6 620.27c-1.54 1.03-2.4 2.03-3.27 2.04-.67 0-1.97-1.35-1.87-1.91.15-.89 1.16-2.15 1.95-2.27.74-.11 1.67 1.05 3.2 2.13Z"
      style={{
        fill: "url(#radial-gradient-158)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M558.6 555.27c.95 1.61 2.03 2.64 1.85 3.37-.19.8-1.48 1.71-2.39 1.84-.52.07-1.79-1.37-1.74-2.04.07-.88 1.13-1.67 2.28-3.17Z"
      style={{
        fill: "url(#radial-gradient-159)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M591.92 621.2c-1.52 1.07-2.47 2.23-3.21 2.12-.81-.13-1.8-1.37-1.98-2.28-.11-.52 1.23-1.9 1.9-1.88.88.02 1.75 1.02 3.28 2.04Z"
      style={{
        fill: "url(#radial-gradient-160)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M520.89 622.6c-1.47 1.15-2.36 2.37-3.11 2.29-.8-.08-1.83-1.31-2.05-2.2-.13-.53 1.13-1.94 1.79-1.96.87-.03 1.78.92 3.36 1.87Z"
      style={{
        fill: "url(#radial-gradient-161)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M460.2 623.44c1.38-1.04 2.27-2.21 3.01-2.12.8.09 2.03 1.36 2.04 2.11 0 .76-1.23 2.03-2.02 2.12-.75.08-1.64-1.08-3.02-2.11Z"
      style={{
        fill: "url(#radial-gradient-162)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M614.5 623.32c1.45-1.13 2.23-2.18 3.1-2.27.67-.07 2.14 1.16 2.07 1.65-.13.92-.99 2.24-1.77 2.42-.75.17-1.79-.88-3.41-1.8Z"
      style={{
        fill: "url(#radial-gradient-163)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M416.34 603.06c-1.49.97-2.45 2.07-3.23 1.96-.81-.12-2.03-1.44-2-2.18.04-.75 1.36-1.96 2.18-2.01.78-.05 1.64 1.14 3.04 2.23Z"
      style={{
        fill: "url(#radial-gradient-164)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M555.59 625.85c-1.48.93-2.45 2.01-3.2 1.88-.79-.14-1.93-1.47-1.89-2.24.03-.73 1.37-1.94 2.15-1.95.79-.01 1.6 1.19 2.95 2.31Z"
      style={{
        fill: "url(#radial-gradient-165)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M482.33 563.23c-1.49.92-2.45 2-3.22 1.87-.79-.13-1.95-1.47-1.91-2.22.04-.72 1.39-1.89 2.19-1.91.78-.02 1.59 1.16 2.94 2.26Z"
      style={{
        fill: "url(#radial-gradient-166)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M472.39 628.07c-1.36 1.11-2.19 2.32-2.96 2.28-.81-.04-2.14-1.24-2.18-2-.04-.75 1.15-2.08 1.95-2.21.76-.13 1.72.98 3.19 1.93Z"
      style={{
        fill: "url(#radial-gradient-167)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M406.61 631.07c-1.52.9-2.47 1.93-3.24 1.8-.8-.13-1.4-1.38-2.09-2.14.78-.68 1.54-1.86 2.34-1.89.78-.03 1.6 1.13 2.99 2.23Z"
      style={{
        fill: "url(#radial-gradient-168)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M509.78 627.72c1.08 1.41 2.22 2.25 2.18 3.01-.05.81-1.23 1.55-1.92 2.32-.77-.7-2.03-1.31-2.15-2.11-.12-.77.95-1.71 1.9-3.23Z"
      style={{
        fill: "url(#radial-gradient-169)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M578.41 575.28c-1.51 1.04-2.48 2.21-3.2 2.07-.8-.16-1.76-1.43-1.91-2.34-.09-.55 1.26-1.9 1.91-1.87.88.04 1.71 1.07 3.2 2.13Z"
      style={{
        fill: "url(#radial-gradient-170)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M456.83 633.89c-1.45.96-2.39 2.06-3.13 1.94-.78-.13-1.91-1.45-1.88-2.21.03-.75 1.3-1.98 2.07-2.01.76-.04 1.58 1.16 2.94 2.28Z"
      style={{
        fill: "url(#radial-gradient-171)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M450.19 644.22c-.93-1.64-1.87-2.56-1.83-3.45.02-.67 1.46-1.92 1.97-1.79.89.23 2.08 1.29 2.15 2.08.07.77-1.12 1.65-2.28 3.15Z"
      style={{
        fill: "url(#radial-gradient-172)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M527.48 452.54c1.01 1.61 1.97 2.5 1.97 3.37 0 .68-1.37 2-1.87 1.89-.9-.21-2.11-1.2-2.22-2.01-.11-.75 1.04-1.68 2.13-3.25Z"
      style={{
        fill: "url(#radial-gradient-173)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M499.47 468.78c-1.69.94-2.72 1.98-3.49 1.81-.78-.17-1.65-1.47-1.79-2.38-.07-.49 1.36-1.74 2.02-1.68.89.09 1.7 1.1 3.25 2.24Z"
      style={{
        fill: "url(#radial-gradient-174)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M495.63 483.4c-1.01-1.63-2.09-2.62-1.95-3.37.16-.8 1.4-1.73 2.31-1.91.48-.1 1.79 1.29 1.76 1.97-.04.88-1.04 1.72-2.13 3.31Z"
      style={{
        fill: "url(#radial-gradient-175)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M452.75 622.88c-.95-1.66-1.89-2.57-1.86-3.46.02-.68 1.46-1.99 1.89-1.85.9.29 2.05 1.3 2.14 2.12.08.77-1.07 1.67-2.17 3.2Z"
      style={{
        fill: "url(#radial-gradient-176)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M579.88 616.12c1.08 1.56 2.22 2.49 2.12 3.25-.11.82-1.28 1.76-2.19 2.07-.37.13-1.78-1.22-1.8-1.91-.01-.88.91-1.78 1.87-3.42Z"
      style={{
        fill: "url(#radial-gradient-177)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M453.27 542.9c-1.63.91-2.55 1.84-3.42 1.79-.7-.04-2-1.47-1.86-1.9.29-.89 1.31-2.05 2.13-2.13.76-.07 1.65 1.11 3.15 2.24Z"
      style={{
        fill: "url(#radial-gradient-178)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M559.53 572.41c-1.66.83-2.61 1.7-3.47 1.62-.68-.06-1.89-1.51-1.74-1.96.3-.89 1.35-2.06 2.15-2.1.76-.04 1.59 1.2 3.06 2.44Z"
      style={{
        fill: "url(#radial-gradient-179)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M492.28 537.5c-1.39 1.16-2.2 2.36-2.96 2.33-.81-.03-2.14-1.17-2.2-1.92-.06-.73 1.09-2.06 1.89-2.22.75-.15 1.73.89 3.27 1.8Z"
      style={{
        fill: "url(#radial-gradient-180)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M425.52 578.8c-1.09-1.57-2.23-2.49-2.13-3.26.1-.81 1.29-1.8 2.19-2.06.44-.13 1.85 1.2 1.86 1.87 0 .89-.93 1.79-1.91 3.45Z"
      style={{
        fill: "url(#radial-gradient-181)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M421.29 570.89c-1.42 1.13-2.25 2.29-3.01 2.25-.8-.04-2.1-1.22-2.14-1.95-.04-.74 1.12-2.04 1.92-2.17.75-.13 1.71.93 3.23 1.87Z"
      style={{
        fill: "url(#radial-gradient-182)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M508 621.3c-1.39 1.1-2.22 2.25-3 2.22-.81-.04-1.55-1.23-2.33-1.92.69-.77 1.3-2.04 2.08-2.16.77-.12 1.72.93 3.24 1.87Z"
      style={{
        fill: "url(#radial-gradient-183)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M497.3 502.78c-1.52.91-2.51 1.97-3.24 1.81-.8-.17-1.94-1.48-1.89-2.22.05-.72 1.39-1.86 2.2-1.89.75-.03 1.55 1.16 2.92 2.31Z"
      style={{
        fill: "url(#radial-gradient-184)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M449.71 582.94c-1.65.98-2.65 2.04-3.42 1.89-.79-.15-1.69-1.42-1.86-2.34-.09-.46 1.34-1.74 2.02-1.7.89.06 1.71 1.06 3.26 2.14Z"
      style={{
        fill: "url(#radial-gradient-185)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M512.25 499.49c-1.57 1.01-2.45 1.99-3.32 1.98-.68 0-2.01-1.37-1.9-1.87.21-.91 1.19-2.14 2-2.26.74-.11 1.68 1.06 3.21 2.15Z"
      style={{
        fill: "url(#radial-gradient-186)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M566.94 514.16c-1.06-1.56-2.19-2.5-2.08-3.25.11-.78 1.37-1.76 2.26-1.93.52-.1 1.88 1.22 1.86 1.86-.03.89-1.01 1.75-2.04 3.32Z"
      style={{
        fill: "url(#radial-gradient-187)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M571.22 555.8c1.07 1.61 2.18 2.55 2.07 3.32-.11.81-1.32 1.78-2.22 2.01-.45.12-1.82-1.24-1.82-1.93 0-.88.96-1.77 1.97-3.41Z"
      style={{
        fill: "url(#radial-gradient-188)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M556.48 522.24c1.01 1.59 1.98 2.46 2 3.35.01.67-1.34 2.02-1.81 1.91-.9-.21-2.13-1.18-2.23-1.97-.1-.79 1.01-1.73 2.04-3.3Z"
      style={{
        fill: "url(#radial-gradient-189)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M543.38 624.09c-1.37 1.07-2.23 2.26-3 2.21-.82-.06-2.14-1.26-2.17-2.01-.03-.75 1.2-2.06 2.01-2.17.77-.11 1.72 1.01 3.16 1.98Z"
      style={{
        fill: "url(#radial-gradient-190)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M439.47 581.19c-1.57 1-2.54 2.1-3.29 1.96-.78-.14-1.71-1.42-1.85-2.31-.08-.54 1.27-1.85 1.9-1.8.89.07 1.71 1.07 3.24 2.16Z"
      style={{
        fill: "url(#radial-gradient-191)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M519.99 514.94c-1.07-1.5-2.18-2.37-2.11-3.15.07-.81 1.27-1.52 1.98-2.28.75.72 1.99 1.37 2.1 2.18.11.77-.97 1.7-1.96 3.25Z"
      style={{
        fill: "url(#radial-gradient-192)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M457.78 631.04c-1.02-1.59-1.98-2.45-1.98-3.31 0-.69 1.33-2.03 1.8-1.92.9.21 2.11 1.16 2.24 1.96.12.73-1 1.68-2.07 3.26Z"
      style={{
        fill: "url(#radial-gradient-193)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M502.41 521.15c-1.17-1.38-2.38-2.18-2.36-2.94.02-.82 1.2-1.61 1.87-2.41.79.67 2.07 1.24 2.24 2.05.15.75-.87 1.75-1.75 3.31Z"
      style={{
        fill: "url(#radial-gradient-194)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M448.37 632.09c-1.02-1.59-2.13-2.55-2.01-3.33.13-.8 1.36-1.72 2.28-1.97.41-.11 1.75 1.3 1.73 2-.02.88-1 1.74-2.01 3.29Z"
      style={{
        fill: "url(#radial-gradient-195)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M414.75 633.21c-.9-1.69-1.79-2.62-1.73-3.5.04-.68 1.49-1.95 1.91-1.81.89.31 2.03 1.34 2.08 2.15.06.78-1.11 1.65-2.26 3.16Z"
      style={{
        fill: "url(#radial-gradient-196)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M485.5 498.14c-1.51 1.14-2.4 2.32-3.14 2.23-.81-.1-1.85-1.28-2.07-2.17-.13-.51 1.15-1.91 1.84-1.94.86-.04 1.76.92 3.37 1.87Z"
      style={{
        fill: "url(#radial-gradient-197)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M527.83 502.42c1.06 1.6 2.16 2.55 2.04 3.3-.12.79-1.34 1.76-2.23 1.95-.49.11-1.83-1.22-1.82-1.89.01-.88.97-1.74 2.01-3.36Z"
      style={{
        fill: "url(#radial-gradient-198)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M540.82 493.48c.96 1.59 1.91 2.48 1.89 3.35-.01.69-1.41 2.02-1.85 1.89-.89-.26-2.06-1.25-2.16-2.06-.09-.76 1.05-1.67 2.12-3.18Z"
      style={{
        fill: "url(#radial-gradient-199)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M497.93 627.69c1.07 1.53 2.07 2.35 2.12 3.23.04.68-1.25 2.09-1.7 2-.91-.19-2.17-1.09-2.32-1.87-.14-.76.93-1.75 1.9-3.36Z"
      style={{
        fill: "url(#radial-gradient-200)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M520.43 447.35c.89 1.68 1.79 2.62 1.73 3.49-.05.68-1.51 1.93-1.95 1.78-.88-.31-1.99-1.35-2.06-2.17-.06-.76 1.12-1.62 2.28-3.11Z"
      style={{
        fill: "url(#radial-gradient-201)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M533.27 627.76c1.16 1.49 2.34 2.34 2.27 3.08-.07.82-1.18 1.83-2.05 2.15-.4.15-1.85-1.09-1.91-1.78-.07-.86.81-1.79 1.69-3.46Z"
      style={{
        fill: "url(#radial-gradient-202)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M603.86 607.06c-1.53 1.11-2.43 2.27-3.18 2.18-.8-.1-1.84-1.29-2.04-2.17-.12-.52 1.16-1.91 1.84-1.93.87-.02 1.77.94 3.38 1.92Z"
      style={{
        fill: "url(#radial-gradient-203)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M551.87 575.53c1.03 1.63 2 2.51 1.99 3.38 0 .69-1.37 2.03-1.85 1.91-.9-.22-2.1-1.19-2.24-2.01-.12-.74 1.01-1.69 2.09-3.28Z"
      style={{
        fill: "url(#radial-gradient-204)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M545.05 493.89c-.91-1.68-1.82-2.62-1.77-3.49.04-.69 1.49-1.97 1.92-1.82.88.3 2 1.33 2.08 2.15.07.76-1.09 1.64-2.23 3.16Z"
      style={{
        fill: "url(#radial-gradient-205)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M449.46 559.19c-1.13-1.51-2.31-2.42-2.22-3.17.1-.8 1.32-1.82 2.21-2.03.51-.12 1.91 1.16 1.92 1.84.02.87-.94 1.77-1.92 3.36Z"
      style={{
        fill: "url(#radial-gradient-206)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M516.14 617.13c-1.49 1.15-2.38 2.35-3.13 2.28-.8-.08-1.84-1.28-2.08-2.17-.13-.49 1.13-1.91 1.8-1.94.88-.03 1.79.9 3.41 1.84Z"
      style={{
        fill: "url(#radial-gradient-207)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M461.67 483.3c1.08 1.44 2.21 2.29 2.15 3.05-.06.82-1.23 1.55-1.92 2.32-.75-.71-1.99-1.33-2.12-2.14-.12-.76.93-1.7 1.89-3.23Z"
      style={{
        fill: "url(#radial-gradient-208)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M541.05 501.66c1.17 1.55 2.34 2.44 2.27 3.2-.08.81-1.23 1.84-2.12 2.12-.46.14-1.89-1.12-1.93-1.8-.05-.88.86-1.82 1.78-3.51Z"
      style={{
        fill: "url(#radial-gradient-209)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M554.64 483.29c-1.38 1.14-2.19 2.32-2.97 2.3-.8-.01-2.13-1.15-2.2-1.89-.06-.72 1.09-2.06 1.87-2.2.77-.14 1.75.89 3.29 1.79Z"
      style={{
        fill: "url(#radial-gradient-210)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M474.86 484.16c-1.19-1.46-2.27-2.22-2.38-3.09-.08-.65 1.17-2.1 1.72-2.04.91.09 2.24.96 2.44 1.75.19.73-.87 1.77-1.78 3.38Z"
      style={{
        fill: "url(#radial-gradient-211)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M469.42 535.85c-1.65.91-2.58 1.82-3.46 1.77-.67-.03-1.92-1.46-1.78-1.94.26-.89 1.3-2.07 2.09-2.13.78-.06 1.64 1.13 3.14 2.3Z"
      style={{
        fill: "url(#radial-gradient-212)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M534.79 574.54c-1.5 1.29-2.56 2.81-3.23 2.65-1.09-.25-1.92-1.6-2.86-2.49.86-.82 1.64-2.17 2.59-2.3.86-.12 1.9 1.09 3.5 2.14Z"
      style={{
        fill: "#fff",
        strokeWidth: 0,
      }}
    />
    <path
      d="M608.36 709.25c.43-.37 1.04-.66 1.26-1.13.61-1.26 1.06-2.61 1.57-3.92-1.29-.09-2.68-.54-3.84-.21-2.31.65-4.31.69-5.1-1.89-.81-2.66.89-3.92 3.43-4.34-.65.73-1.45 1.38-1.9 2.22-.32.59-.49 1.7-.16 2.09.5.58 1.47.8 2.29 1.02.3.08.73-.22 1.09-.38 2.28-1.07 4.54-1.3 5.44 1.56 1.01 3.19-1.12 4.56-4.08 4.99Z"
      style={{
        fill: "url(#radial-gradient-213)",
        strokeWidth: 0,
      }}
    />
  </svg>
)

export const PermAtmPeligrosas2 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    id="Capa_1"
    data-name="Capa 1"
    viewBox="275 230 460 460"
    {...props}
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx={471.5}
        cy={491.73}
        r={2.33}
        fx={471.5}
        fy={491.73}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#284158" />
        <stop offset={0.27} stopColor="#263d53" />
        <stop offset={0.54} stopColor="#213347" />
        <stop offset={0.82} stopColor="#182332" />
        <stop offset={0.99} stopColor="#121622" />
      </radialGradient>
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-2"
        cx={573.56}
        cy={626.36}
        r={2.43}
        fx={573.56}
        fy={626.36}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-3"
        cx={491.79}
        cy={453.37}
        r={2.39}
        fx={491.79}
        fy={453.37}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-4"
        cx={454.53}
        cy={517.79}
        r={2.35}
        fx={454.53}
        fy={517.79}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-5"
        cx={464.82}
        cy={632.91}
        r={2.35}
        fx={464.82}
        fy={632.91}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-6"
        cx={474.31}
        cy={622.9}
        r={2.38}
        fx={474.31}
        fy={622.9}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-7"
        cx={584.6}
        cy={560.87}
        r={2.41}
        fx={584.6}
        fy={560.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-8"
        cx={498.37}
        cy={536.04}
        r={2.55}
        fx={498.37}
        fy={536.04}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-9"
        cx={532.81}
        cy={519.61}
        r={2.4}
        fx={532.81}
        fy={519.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-10"
        cx={452.2}
        cy={575.61}
        r={2.4}
        fx={452.2}
        fy={575.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-11"
        cx={570.54}
        cy={588.74}
        r={2.33}
        fx={570.54}
        fy={588.74}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-12"
        cx={606.32}
        cy={589.06}
        r={2.4}
        fx={606.32}
        fy={589.06}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-13"
        cx={426.79}
        cy={592.18}
        r={2.42}
        fx={426.79}
        fy={592.18}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-14"
        cx={404.2}
        cy={597.17}
        r={2.41}
        fx={404.2}
        fy={597.17}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-15"
        cx={488.41}
        cy={524.8}
        r={2.36}
        fx={488.41}
        fy={524.8}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-16"
        cx={548.67}
        cy={524.34}
        r={2.43}
        fx={548.67}
        fy={524.34}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-17"
        cx={432.86}
        cy={597.99}
        r={2.36}
        fx={432.86}
        fy={597.99}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-18"
        cx={459.73}
        cy={604.78}
        r={2.35}
        fx={459.73}
        fy={604.78}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-19"
        cx={457.58}
        cy={509.59}
        r={2.4}
        fx={457.58}
        fy={509.59}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-20"
        cx={499.36}
        cy={608.64}
        r={2.38}
        fx={499.36}
        fy={608.64}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-21"
        cx={398.8}
        cy={611.63}
        r={2.35}
        fx={398.8}
        fy={611.63}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-22"
        cx={477.62}
        cy={615.01}
        r={2.39}
        fx={477.62}
        fy={615.01}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-23"
        cx={568.01}
        cy={620.79}
        r={2.42}
        fx={568.01}
        fy={620.79}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-24"
        cx={515.09}
        cy={527.51}
        r={2.38}
        fx={515.09}
        fy={527.51}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-25"
        cx={577.7}
        cy={583.74}
        r={2.38}
        fx={577.7}
        fy={583.74}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-26"
        cx={529.78}
        cy={489.14}
        r={2.39}
        fx={529.78}
        fy={489.14}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-27"
        cx={626.48}
        cy={620.76}
        r={2.41}
        fx={626.48}
        fy={620.76}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-28"
        cx={445.15}
        cy={621.25}
        r={2.4}
        fx={445.15}
        fy={621.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-29"
        cx={495.72}
        cy={491.32}
        r={2.39}
        fx={495.72}
        fy={491.32}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-30"
        cx={414.22}
        cy={588.42}
        r={2.39}
        fx={414.22}
        fy={588.42}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-31"
        cx={511.35}
        cy={454.1}
        r={2.38}
        fx={511.35}
        fy={454.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-32"
        cx={458.65}
        cy={559.36}
        r={2.41}
        fx={458.65}
        fy={559.36}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-33"
        cx={497.27}
        cy={459.94}
        r={2.33}
        fx={497.27}
        fy={459.94}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-34"
        cx={516.51}
        cy={461.38}
        r={2.35}
        fx={516.51}
        fy={461.38}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-35"
        cx={565.08}
        cy={519.2}
        r={2.4}
        fx={565.08}
        fy={519.2}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-36"
        cx={588.23}
        cy={580.86}
        r={2.34}
        fx={588.23}
        fy={580.86}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-37"
        cx={446.18}
        cy={522.33}
        r={2.34}
        fx={446.18}
        fy={522.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-38"
        cx={531}
        cy={471}
        r={2.38}
        fx={531}
        fy={471}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-39"
        cx={521.57}
        cy={472.25}
        r={2.38}
        fx={521.57}
        fy={472.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-40"
        cx={480.57}
        cy={473.71}
        r={2.35}
        fx={480.57}
        fy={473.71}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-41"
        cx={463.36}
        cy={522.64}
        r={2.34}
        fx={463.36}
        fy={522.64}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-42"
        cx={469.79}
        cy={579.26}
        r={2.34}
        fx={469.79}
        fy={579.26}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-43"
        cx={485.3}
        cy={479.69}
        r={2.39}
        fx={485.3}
        fy={479.69}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-44"
        cx={551.74}
        cy={588.63}
        r={2.41}
        fx={551.74}
        fy={588.63}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-45"
        cx={479.76}
        cy={552.28}
        r={2.33}
        fx={479.76}
        fy={552.28}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-46"
        cx={565.49}
        cy={553.12}
        r={2.36}
        fx={565.49}
        fy={553.12}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-47"
        cx={580.31}
        cy={553.25}
        r={2.34}
        fx={580.31}
        fy={553.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-48"
        cx={405.34}
        cy={605.71}
        r={2.41}
        fx={405.34}
        fy={605.71}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-49"
        cx={578.7}
        cy={544.01}
        r={2.39}
        fx={578.7}
        fy={544.01}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-50"
        cx={479.25}
        cy={488.35}
        r={2.36}
        fx={479.25}
        fy={488.35}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-51"
        cx={507.23}
        cy={489.4}
        r={2.37}
        fx={507.23}
        fy={489.4}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-52"
        cx={388.22}
        cy={624.42}
        r={2.4}
        fx={388.22}
        fy={624.42}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-53"
        cx={458.98}
        cy={637.37}
        r={2.4}
        fx={458.98}
        fy={637.37}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-54"
        cx={564.57}
        cy={526.76}
        r={2.34}
        fx={564.57}
        fy={526.76}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-55"
        cx={502.69}
        cy={496.33}
        r={2.34}
        fx={502.69}
        fy={496.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-56"
        cx={440.71}
        cy={631.43}
        r={2.35}
        fx={440.71}
        fy={631.43}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-57"
        cx={454.71}
        cy={528.2}
        r={2.41}
        fx={454.71}
        fy={528.2}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-58"
        cx={461.91}
        cy={497.72}
        r={2.4}
        fx={461.91}
        fy={497.72}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-59"
        cx={379.86}
        cy={631.43}
        r={2.35}
        fx={379.86}
        fy={631.43}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-60"
        cx={431.87}
        cy={557.72}
        r={2.39}
        fx={431.87}
        fy={557.72}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-61"
        cx={406.98}
        cy={622.78}
        r={2.39}
        fx={406.98}
        fy={622.78}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-62"
        cx={468.52}
        cy={546.46}
        r={2.35}
        fx={468.52}
        fy={546.46}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-63"
        cx={526.76}
        cy={514.32}
        r={2.39}
        fx={526.76}
        fy={514.32}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-64"
        cx={518.8}
        cy={503.34}
        r={2.4}
        fx={518.8}
        fy={503.34}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-65"
        cx={540.33}
        cy={617.71}
        r={2.36}
        fx={540.33}
        fy={617.71}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-66"
        cx={590.28}
        cy={571.64}
        r={2.4}
        fx={590.28}
        fy={571.64}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-67"
        cx={586.35}
        cy={547.09}
        r={2.35}
        fx={586.35}
        fy={547.09}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-68"
        cx={446.12}
        cy={532.62}
        r={2.35}
        fx={446.12}
        fy={532.62}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-69"
        cx={430.45}
        cy={547.85}
        r={2.34}
        fx={430.45}
        fy={547.85}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-70"
        cx={574.56}
        cy={548.72}
        r={2.34}
        fx={574.56}
        fy={548.72}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-71"
        cx={459.26}
        cy={549.17}
        r={2.35}
        fx={459.26}
        fy={549.17}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-72"
        cx={407.56}
        cy={614.39}
        r={2.36}
        fx={407.56}
        fy={614.39}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-73"
        cx={603.78}
        cy={597.22}
        r={2.35}
        fx={603.78}
        fy={597.22}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-74"
        cx={475.51}
        cy={571.16}
        r={2.33}
        fx={475.51}
        fy={571.16}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-75"
        cx={515.57}
        cy={606.92}
        r={2.37}
        fx={515.57}
        fy={606.92}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-76"
        cx={474.59}
        cy={537.91}
        r={2.35}
        fx={474.59}
        fy={537.91}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-77"
        cx={485.12}
        cy={515.04}
        r={2.4}
        fx={485.12}
        fy={515.04}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-78"
        cx={591.18}
        cy={605.86}
        r={2.35}
        fx={591.18}
        fy={605.86}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-79"
        cx={552.65}
        cy={517.28}
        r={2.4}
        fx={552.65}
        fy={517.28}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-80"
        cx={482.68}
        cy={453.9}
        r={2.34}
        fx={482.68}
        fy={453.9}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-81"
        cx={590.51}
        cy={554.39}
        r={2.39}
        fx={590.51}
        fy={554.39}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-82"
        cx={485.65}
        cy={464.61}
        r={2.36}
        fx={485.65}
        fy={464.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-83"
        cx={475.07}
        cy={467.19}
        r={2.39}
        fx={475.07}
        fy={467.19}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-84"
        cx={487.63}
        cy={554.51}
        r={2.41}
        fx={487.63}
        fy={554.51}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-85"
        cx={489.38}
        cy={473.1}
        r={2.36}
        fx={489.38}
        fy={473.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-86"
        cx={504.15}
        cy={477.88}
        r={2.36}
        fx={504.15}
        fy={477.88}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-87"
        cx={465.42}
        cy={608.53}
        r={2.34}
        fx={465.42}
        fy={608.53}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-88"
        cx={521.5}
        cy={486.84}
        r={2.33}
        fx={521.5}
        fy={486.84}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-89"
        cx={537.86}
        cy={486.08}
        r={2.34}
        fx={537.86}
        fy={486.08}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-90"
        cx={518.01}
        cy={495.49}
        r={2.37}
        fx={518.01}
        fy={495.49}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-91"
        cx={559.86}
        cy={496.69}
        r={2.35}
        fx={559.86}
        fy={496.69}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-92"
        cx={550.54}
        cy={497.63}
        r={2.41}
        fx={550.54}
        fy={497.63}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-93"
        cx={472.1}
        cy={501.53}
        r={2.39}
        fx={472.1}
        fy={501.53}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-94"
        cx={503.03}
        cy={506.33}
        r={2.36}
        fx={503.03}
        fy={506.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-95"
        cx={551.9}
        cy={507.33}
        r={2.39}
        fx={551.9}
        fy={507.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-96"
        cx={479.85}
        cy={506.97}
        r={2.39}
        fx={479.85}
        fy={506.97}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-97"
        cx={511.15}
        cy={509.82}
        r={2.34}
        fx={511.15}
        fy={509.82}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-98"
        cx={494.99}
        cy={510.96}
        r={2.36}
        fx={494.99}
        fy={510.96}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-99"
        cx={536.59}
        cy={511}
        r={2.4}
        fx={536.59}
        fy={511}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-100"
        cx={487.29}
        cy={489.53}
        r={2.35}
        fx={487.29}
        fy={489.53}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-101"
        cx={512.16}
        cy={518.4}
        r={2.38}
        fx={512.16}
        fy={518.4}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-102"
        cx={494.88}
        cy={519.27}
        r={2.35}
        fx={494.88}
        fy={519.27}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-103"
        cx={478.42}
        cy={520.53}
        r={2.37}
        fx={478.42}
        fy={520.53}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-104"
        cx={507.24}
        cy={523.87}
        r={2.39}
        fx={507.24}
        fy={523.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-105"
        cx={471.81}
        cy={525.96}
        r={2.38}
        fx={471.81}
        fy={525.96}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-106"
        cx={526.9}
        cy={526.61}
        r={2.35}
        fx={526.9}
        fy={526.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-107"
        cx={496.4}
        cy={528.52}
        r={2.43}
        fx={496.4}
        fy={528.52}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-108"
        cx={576.16}
        cy={530.3}
        r={2.36}
        fx={576.16}
        fy={530.3}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-109"
        cx={535.44}
        cy={529.42}
        r={2.36}
        fx={535.44}
        fy={529.42}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-110"
        cx={566.36}
        cy={533.36}
        r={2.35}
        fx={566.36}
        fy={533.36}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-111"
        cx={581.56}
        cy={534.49}
        r={2.39}
        fx={581.56}
        fy={534.49}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-112"
        cx={418.58}
        cy={581.48}
        r={2.35}
        fx={418.58}
        fy={581.48}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-113"
        cx={558.06}
        cy={538.59}
        r={2.34}
        fx={558.06}
        fy={538.59}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-114"
        cx={572.42}
        cy={539.68}
        r={2.41}
        fx={572.42}
        fy={539.68}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-115"
        cx={549.81}
        cy={539.78}
        r={2.36}
        fx={549.81}
        fy={539.78}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-116"
        cx={438.47}
        cy={541.44}
        r={2.33}
        fx={438.47}
        fy={541.44}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-117"
        cx={481.31}
        cy={544.92}
        r={2.37}
        fx={481.31}
        fy={544.92}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-118"
        cx={441.3}
        cy={550.15}
        r={2.36}
        fx={441.3}
        fy={550.15}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-119"
        cx={566.94}
        cy={577.82}
        r={2.35}
        fx={566.94}
        fy={577.82}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-120"
        cx={410.83}
        cy={578.54}
        r={2.34}
        fx={410.83}
        fy={578.54}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-121"
        cx={470.72}
        cy={512.82}
        r={2.35}
        fx={470.72}
        fy={512.82}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-122"
        cx={559.89}
        cy={582.3}
        r={2.35}
        fx={559.89}
        fy={582.3}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-123"
        cx={426.4}
        cy={583.8}
        r={2.35}
        fx={426.4}
        fy={583.8}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-124"
        cx={441.86}
        cy={563.81}
        r={2.36}
        fx={441.86}
        fy={563.81}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-125"
        cx={592.8}
        cy={564.6}
        r={2.38}
        fx={592.8}
        fy={564.6}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-126"
        cx={405.93}
        cy={589.22}
        r={2.36}
        fx={405.93}
        fy={589.22}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-127"
        cx={438.81}
        cy={589.88}
        r={2.36}
        fx={438.81}
        fy={589.88}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-128"
        cx={559.46}
        cy={590.87}
        r={2.37}
        fx={559.46}
        fy={590.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-129"
        cx={468.82}
        cy={565.31}
        r={2.4}
        fx={468.82}
        fy={565.31}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-130"
        cx={541.97}
        cy={595.74}
        r={2.33}
        fx={541.97}
        fy={595.74}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-131"
        cx={549.89}
        cy={596.65}
        r={2.32}
        fx={549.89}
        fy={596.65}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-132"
        cx={567.27}
        cy={596.65}
        r={2.34}
        fx={567.27}
        fy={596.65}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-133"
        cx={563.16}
        cy={566.71}
        r={2.34}
        fx={563.16}
        fy={566.71}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-134"
        cx={613.03}
        cy={597.3}
        r={2.34}
        fx={613.03}
        fy={597.3}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-135"
        cx={454.33}
        cy={568.23}
        r={2.34}
        fx={454.33}
        fy={568.23}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-136"
        cx={557.47}
        cy={598.81}
        r={2.35}
        fx={557.47}
        fy={598.81}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-137"
        cx={394.91}
        cy={602.47}
        r={2.41}
        fx={394.91}
        fy={602.47}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-138"
        cx={469.36}
        cy={557.15}
        r={2.38}
        fx={469.36}
        fy={557.15}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-139"
        cx={551.87}
        cy={604.48}
        r={2.37}
        fx={551.87}
        fy={604.48}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-140"
        cx={529.69}
        cy={605.02}
        r={2.39}
        fx={529.69}
        fy={605.02}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-141"
        cx={540.25}
        cy={605.1}
        r={2.32}
        fx={540.25}
        fy={605.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-142"
        cx={560.37}
        cy={605.61}
        r={2.4}
        fx={560.37}
        fy={605.61}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-143"
        cx={610.35}
        cy={607.03}
        r={2.35}
        fx={610.35}
        fy={607.03}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-144"
        cx={472.52}
        cy={609.23}
        r={2.35}
        fx={472.52}
        fy={609.23}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-145"
        cx={437.65}
        cy={571.57}
        r={2.34}
        fx={437.65}
        fy={571.57}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-146"
        cx={454.68}
        cy={609.76}
        r={2.39}
        fx={454.68}
        fy={609.76}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-147"
        cx={545.98}
        cy={610.03}
        r={2.34}
        fx={545.98}
        fy={610.03}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-148"
        cx={522.08}
        cy={610.63}
        r={2.32}
        fx={522.08}
        fy={610.63}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-149"
        cx={507.79}
        cy={611.19}
        r={2.3}
        fx={507.79}
        fy={611.19}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-150"
        cx={534.36}
        cy={612.06}
        r={2.39}
        fx={534.36}
        fy={612.06}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-151"
        cx={620.5}
        cy={612.74}
        r={2.4}
        fx={620.5}
        fy={612.74}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-152"
        cx={446.75}
        cy={614.18}
        r={2.37}
        fx={446.75}
        fy={614.18}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-153"
        cx={587.31}
        cy={614.38}
        r={2.35}
        fx={587.31}
        fy={614.38}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-154"
        cx={597.41}
        cy={615.1}
        r={2.35}
        fx={597.41}
        fy={615.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-155"
        cx={389.52}
        cy={615.45}
        r={2.34}
        fx={389.52}
        fy={615.45}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-156"
        cx={460.21}
        cy={616.9}
        r={2.36}
        fx={460.21}
        fy={616.9}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-157"
        cx={469.27}
        cy={617}
        r={2.34}
        fx={469.27}
        fy={617}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-158"
        cx={399.03}
        cy={620.22}
        r={2.35}
        fx={399.03}
        fy={620.22}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-159"
        cx={558.4}
        cy={557.87}
        r={2.36}
        fx={558.4}
        fy={557.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-160"
        cx={589.33}
        cy={621.25}
        r={2.35}
        fx={589.33}
        fy={621.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-161"
        cx={518.31}
        cy={622.81}
        r={2.35}
        fx={518.31}
        fy={622.81}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-162"
        cx={462.72}
        cy={623.43}
        r={2.33}
        fx={462.72}
        fy={623.43}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-163"
        cx={617.1}
        cy={623.09}
        r={2.33}
        fx={617.1}
        fy={623.09}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-164"
        cx={413.73}
        cy={602.92}
        r={2.37}
        fx={413.73}
        fy={602.92}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-165"
        cx={553.04}
        cy={625.64}
        r={2.34}
        fx={553.04}
        fy={625.64}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-166"
        cx={479.77}
        cy={563.04}
        r={2.33}
        fx={479.77}
        fy={563.04}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-167"
        cx={469.81}
        cy={628.24}
        r={2.35}
        fx={469.81}
        fy={628.24}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-168"
        cx={403.94}
        cy={630.86}
        r={2.37}
        fx={403.94}
        fy={630.86}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-169"
        cx={509.91}
        cy={630.39}
        r={2.37}
        fx={509.91}
        fy={630.39}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-170"
        cx={575.85}
        cy={575.25}
        r={2.34}
        fx={575.85}
        fy={575.25}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-171"
        cx={454.32}
        cy={633.72}
        r={2.32}
        fx={454.32}
        fy={633.72}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-172"
        cx={450.41}
        cy={641.6}
        r={2.36}
        fx={450.41}
        fy={641.6}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-173"
        cx={527.39}
        cy={455.17}
        r={2.36}
        fx={527.39}
        fy={455.17}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-174"
        cx={496.84}
        cy={468.57}
        r={2.36}
        fx={496.84}
        fy={468.57}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-175"
        cx={495.71}
        cy={480.75}
        r={2.36}
        fx={495.71}
        fy={480.75}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-176"
        cx={452.9}
        cy={620.21}
        r={2.36}
        fx={452.9}
        fy={620.21}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-177"
        cx={580.01}
        cy={618.79}
        r={2.36}
        fx={580.01}
        fy={618.79}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-178"
        cx={450.62}
        cy={542.67}
        r={2.35}
        fx={450.62}
        fy={542.67}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-179"
        cx={556.92}
        cy={572.01}
        r={2.34}
        fx={556.92}
        fy={572.01}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-180"
        cx={489.7}
        cy={537.76}
        r={2.34}
        fx={489.7}
        fy={537.76}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-181"
        cx={425.41}
        cy={576.14}
        r={2.36}
        fx={425.41}
        fy={576.14}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-182"
        cx={418.71}
        cy={571.07}
        r={2.34}
        fx={418.71}
        fy={571.07}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-183"
        cx={505.34}
        cy={621.46}
        r={2.38}
        fx={505.34}
        fy={621.46}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-184"
        cx={494.74}
        cy={502.54}
        r={2.33}
        fx={494.74}
        fy={502.54}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-185"
        cx={447.07}
        cy={582.82}
        r={2.35}
        fx={447.07}
        fy={582.82}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-186"
        cx={509.64}
        cy={499.4}
        r={2.36}
        fx={509.64}
        fy={499.4}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-187"
        cx={566.92}
        cy={511.56}
        r={2.34}
        fx={566.92}
        fy={511.56}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-188"
        cx={571.27}
        cy={558.47}
        r={2.37}
        fx={571.27}
        fy={558.47}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-189"
        cx={556.46}
        cy={524.87}
        r={2.35}
        fx={556.46}
        fy={524.87}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-190"
        cx={540.8}
        cy={624.2}
        r={2.35}
        fx={540.8}
        fy={624.2}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-191"
        cx={436.91}
        cy={581.1}
        r={2.33}
        fx={436.91}
        fy={581.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-192"
        cx={519.91}
        cy={512.23}
        r={2.4}
        fx={519.91}
        fy={512.23}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-193"
        cx={457.83}
        cy={628.42}
        r={2.34}
        fx={457.83}
        fy={628.42}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-194"
        cx={502.11}
        cy={518.47}
        r={2.39}
        fx={502.11}
        fy={518.47}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-195"
        cx={448.37}
        cy={629.44}
        r={2.35}
        fx={448.37}
        fy={629.44}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-196"
        cx={415.02}
        cy={630.55}
        r={2.35}
        fx={415.02}
        fy={630.55}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-197"
        cx={482.89}
        cy={498.33}
        r={2.35}
        fx={482.89}
        fy={498.33}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-198"
        cx={527.86}
        cy={505.05}
        r={2.35}
        fx={527.86}
        fy={505.05}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-199"
        cx={540.7}
        cy={496.1}
        r={2.34}
        fx={540.7}
        fy={496.1}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-200"
        cx={498.03}
        cy={630.31}
        r={2.34}
        fx={498.03}
        fy={630.31}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-201"
        cx={520.16}
        cy={449.99}
        r={2.34}
        fx={520.16}
        fy={449.99}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-202"
        cx={533.56}
        cy={630.38}
        r={2.33}
        fx={533.56}
        fy={630.38}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-203"
        cx={601.25}
        cy={607.2}
        r={2.35}
        fx={601.25}
        fy={607.2}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-204"
        cx={551.82}
        cy={578.18}
        r={2.37}
        fx={551.82}
        fy={578.18}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-205"
        cx={545.28}
        cy={491.23}
        r={2.35}
        fx={545.28}
        fy={491.23}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-206"
        cx={449.3}
        cy={556.58}
        r={2.35}
        fx={449.3}
        fy={556.58}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-207"
        cx={513.52}
        cy={617.35}
        r={2.35}
        fx={513.52}
        fy={617.35}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-208"
        cx={461.8}
        cy={485.98}
        r={2.38}
        fx={461.8}
        fy={485.98}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-209"
        cx={541.3}
        cy={504.32}
        r={2.36}
        fx={541.3}
        fy={504.32}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-210"
        cx={552.06}
        cy={483.54}
        r={2.33}
        fx={552.06}
        fy={483.54}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-211"
        cx={474.58}
        cy={481.59}
        r={2.34}
        fx={474.58}
        fy={481.59}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-212"
        cx={466.8}
        cy={535.59}
        r={2.35}
        fx={466.8}
        fy={535.59}
      />
      <radialGradient
        xlinkHref="#radial-gradient"
        id="radial-gradient-213"
        cx={607.37}
        cy={703.5}
        r={5.54}
        fx={607.37}
        fy={703.5}
      />
      <style>{".cls-148{stroke-width:0;fill:#1d1d1b}"}</style>
    </defs>
    <rect
      width={431.43}
      height={654.74}
      x={286.65}
      y={129.23}
      rx={76.35}
      ry={76.35}
      style={{
        fill: "#fff",
        stroke: "#1d1d1b",
        strokeMiterlimit: 10,
        strokeWidth: 21,
      }}
    />
    <rect
      width={317.89}
      height={324.69}
      x={343.42}
      y={425.33}
      rx={46.15}
      ry={46.15}
      style={{
        fill: "#f49a33",
        strokeWidth: 19,
        stroke: "#1d1d1b",
        strokeMiterlimit: 10,
      }}
    />
    <path
      d="M482.83 593.07c-3.3 2.8-5.78 5.37-10.1 3.35-1.53-.71-3.65-.47-5.44-.24-15.44 2.02-30.9 20.09-30.49 35.54.22 8.42 5.52 13.63 13.92 12.97 12.07-.95 20.1-8.36 26.49-17.82 4.23-6.27 6.45-13.17 5.24-20.9-.41-2.63.29-3.23 5.33-5.58 3.66 7.39 3.96 14.83.51 22.26-2.16 4.63-5.04 8.93-7.17 13.58-1.26 2.75-2.11 5.85-2.35 8.86-.38 4.83.06 9.72-.17 14.58-.14 2.99.76 4.65 3.55 6.1 17.25 8.97 34.4 18.14 51.54 27.32 7.45 3.99 9.97 11.22 6.38 17.76-3.67 6.7-11 8.65-18.35 4.76-20.43-10.83-40.82-21.75-61.26-32.56-4.92-2.6-7.36-6.54-7.41-12.04-.03-3.15.09-6.31-.1-9.46-.04-.76-1.02-1.46-1.57-2.18-.53.76-1.54 1.53-1.51 2.27.21 6.98-1.24 14.22 3.73 20.48.82 1.04.6.66 2.92 2.98 8.7 4.35 12.77 6.3 20.37 10.28 15.94 8.35 31.84 16.74 47.76 25.13.07.04.05.22.15.7-1.29.07-2.52.19-3.76.2-34.19.01-68.38.05-102.57 0-21.66-.03-34.21-21.46-23.67-40.31 15.25-27.29 30.51-54.57 45.82-81.83 7.67-13.65 24.24-17.96 37.4-9.81 1.54.95 2.9 2.19 4.79 3.64ZM525.41 590.52c-3.95-10.87-11.6-14.32-21.97-9.53-4.74 2.19-9.17 5.04-14.13 7.82-2.53-2.8-5.34-5.54-7.69-8.64-1.89-2.49-1.61-5.41.82-7.44 7.18-5.98 11.78-13.48 14.89-22.26 1.5-4.25 3.53-8.98 9.24-9.71.36-.05.82-.3.99-.6 3.48-5.89 9.49-5.59 14.99-5.67 6.79-.09 12.81 2.57 17.79 7.28 9.83 9.31 11.52 23.31 4.14 34.82-2.83 4.42-5.84 8.72-8.83 13.04-4.33 6.28-5.67 6.36-10.37.75l.13.13Zm9.38-15.98c-1.6-1.04-2.65-2.26-3.5-2.14-.95.13-1.73 1.48-2.59 2.3.94.89 1.78 2.23 2.86 2.49.67.16 1.73-1.36 3.23-2.65Z"
      className="cls-148"
    />
    <path
      d="M532.9 721.37c7.21-3.5 11.61-8.4 11.8-16.32.2-7.95-4.6-12.68-11.09-16.79 1.2-.08 2.4-.22 3.6-.22 19.97 0 39.94.03 59.92 0 4.02 0 7.53.7 9.6 4.69.59 1.13 1.62 2.09 2.61 2.93 5.12 4.38 6.68 9.61 3.73 15.81-2.96 6.22-7.87 9.81-14.88 9.85-15.37.1-30.75.03-46.12.03h-19.18Zm75.46-12.12c2.96-.43 5.09-1.8 4.08-4.99-.9-2.86-3.16-2.63-5.44-1.56-.35.17-.78.47-1.09.38-.81-.23-1.79-.44-2.29-1.02-.33-.39-.16-1.5.16-2.09.46-.84 1.25-1.49 1.9-2.22-2.54.42-4.25 1.68-3.43 4.34.79 2.59 2.79 2.54 5.1 1.89 1.17-.33 2.56.12 3.84.21-.51 1.31-.96 2.65-1.57 3.92-.23.47-.83.76-1.26 1.13ZM525.28 590.39c-2.47 6.86-9.2 8.54-17.12 4.3-7.4 5.59-8.75 5.67-13 .2 7.3-3.9 14.1-8.89 22.73-8.53 2.56.11 5.01 2.7 7.52 4.16l-.13-.13Z"
      className="cls-148"
    />
    <path
      d="M474.03 491.54c-1.36 1.12-2.17 2.32-2.95 2.3-.77-.02-2.06-1.24-2.11-1.99-.04-.75 1.1-2.11 1.86-2.22.77-.12 1.72.97 3.19 1.91Z"
      style={{
        fill: "url(#radial-gradient)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M573.44 629.1c-.98-1.55-2.06-2.51-1.95-3.29.12-.82 1.39-1.47 2.16-2.2.71.79 1.91 1.54 1.97 2.38.05.79-1.1 1.66-2.18 3.12Z"
      style={{
        fill: "url(#radial-gradient-2)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M491.64 450.69c1.08 1.4 2.23 2.23 2.2 3.03-.03.8-1.23 1.56-1.92 2.33-.77-.69-2.06-1.31-2.18-2.1-.11-.79.95-1.74 1.89-3.25Z"
      style={{
        fill: "url(#radial-gradient-3)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M457.1 517.94c-1.46.96-2.41 2.07-3.16 1.95-.81-.13-2-1.44-1.97-2.19.03-.75 1.34-1.97 2.15-2.01.77-.05 1.61 1.15 2.98 2.26Z"
      style={{
        fill: "url(#radial-gradient-4)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M467.38 632.78c-1.35 1.09-2.19 2.31-2.95 2.25-.83-.06-2.14-1.27-2.17-2.03-.04-.75 1.18-2.07 1.99-2.2.74-.12 1.69 1.01 3.12 1.98Z"
      style={{
        fill: "url(#radial-gradient-5)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M476.97 623.07c-1.47.93-2.43 2.02-3.21 1.89-.8-.13-1.42-1.42-2.11-2.21.79-.69 1.56-1.9 2.37-1.93.78-.03 1.61 1.16 2.95 2.24Z"
      style={{
        fill: "url(#radial-gradient-6)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M584.46 563.58c-.97-1.52-2.05-2.47-1.93-3.24.13-.82 1.39-1.46 2.16-2.18.71.78 1.92 1.52 1.98 2.34.05.78-1.11 1.63-2.21 3.07Z"
      style={{
        fill: "url(#radial-gradient-7)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M498.46 533.16c1.03 1.65 2.11 2.63 2.08 3.58-.02.75-1.41 1.46-2.19 2.19-.76-.87-1.95-1.66-2.14-2.65-.13-.68 1.11-1.63 2.25-3.12Z"
      style={{
        fill: "url(#radial-gradient-8)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M535.51 519.4c-1.38 1.12-2.21 2.31-2.98 2.28-.83-.03-1.61-1.22-2.41-1.91.7-.78 1.32-2.06 2.13-2.21.76-.14 1.74.92 3.26 1.84Z"
      style={{
        fill: "url(#radial-gradient-9)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M454.89 575.47c-1.39 1.09-2.24 2.26-3.01 2.21-.82-.05-1.58-1.25-2.36-1.95.71-.77 1.33-2.05 2.14-2.18.76-.12 1.72.96 3.22 1.91Z"
      style={{
        fill: "url(#radial-gradient-10)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M573.08 588.61c-1.37 1.1-2.21 2.29-2.96 2.24-.81-.06-2.1-1.28-2.12-2.02-.02-.76 1.16-2.06 1.96-2.19.74-.12 1.68 1 3.11 1.97Z"
      style={{
        fill: "url(#radial-gradient-11)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M606.23 591.75c-.97-1.49-2.07-2.43-1.96-3.21.11-.81 1.39-1.45 2.15-2.17.7.77 1.91 1.51 1.96 2.32.05.78-1.09 1.64-2.15 3.06Z"
      style={{
        fill: "url(#radial-gradient-12)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M426.72 589.42c1.06 1.5 2.17 2.39 2.1 3.17-.07.83-1.27 1.57-1.97 2.35-.74-.74-1.97-1.42-2.08-2.25-.11-.78.97-1.72 1.95-3.26Z"
      style={{
        fill: "url(#radial-gradient-13)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M406.92 597.39c-1.57.92-2.54 1.95-3.31 1.81-.81-.14-1.44-1.39-2.15-2.15.8-.69 1.56-1.87 2.4-1.92.77-.04 1.61 1.13 3.06 2.26Z"
      style={{
        fill: "url(#radial-gradient-14)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M490.98 524.99c-1.48.95-2.46 2.05-3.21 1.91-.81-.15-1.97-1.49-1.93-2.25.04-.74 1.38-1.93 2.2-1.97.77-.03 1.59 1.18 2.95 2.31Z"
      style={{
        fill: "url(#radial-gradient-15)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M548.8 521.57c.96 1.57 2.01 2.52 1.9 3.31-.12.82-1.35 1.49-2.1 2.22-.7-.79-1.9-1.54-1.96-2.37-.05-.8 1.08-1.68 2.16-3.16Z"
      style={{
        fill: "url(#radial-gradient-16)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M433.1 595.41c.93 1.5 2.02 2.49 1.87 3.23-.16.82-1.51 1.98-2.26 1.92-.75-.06-1.91-1.41-1.95-2.24-.04-.75 1.19-1.57 2.35-2.92Z"
      style={{
        fill: "url(#radial-gradient-17)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M459.77 607.36c-1.06-1.43-2.21-2.31-2.13-3.07.08-.81 1.31-2.09 2.04-2.1.76 0 2.03 1.21 2.14 2.02.1.76-1.03 1.68-2.05 3.15Z"
      style={{
        fill: "url(#radial-gradient-18)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M457.65 512.31c-1.04-1.44-2.15-2.3-2.1-3.08.05-.82 1.21-1.57 1.89-2.35.76.71 2.04 1.36 2.15 2.16.11.78-.97 1.73-1.94 3.27Z"
      style={{
        fill: "url(#radial-gradient-19)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M499.45 605.96c.99 1.63 2.07 2.63 1.93 3.41-.15.81-1.41 1.72-2.34 1.94-.43.1-1.76-1.35-1.72-2.05.04-.89 1.05-1.73 2.13-3.3Z"
      style={{
        fill: "url(#radial-gradient-20)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M401.37 611.75c-1.45.98-2.39 2.1-3.15 1.99-.81-.12-2-1.43-1.98-2.19.02-.75 1.33-1.97 2.13-2.03.77-.05 1.62 1.14 2.99 2.23Z"
      style={{
        fill: "url(#radial-gradient-21)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M480.31 615.19c-1.52.93-2.49 1.99-3.26 1.86-.8-.13-1.42-1.4-2.12-2.17.78-.69 1.54-1.89 2.35-1.93.79-.03 1.62 1.13 3.03 2.24Z"
      style={{
        fill: "url(#radial-gradient-22)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M567.82 623.53c-.93-1.55-1.98-2.52-1.85-3.3.13-.82 1.39-1.46 2.16-2.17.7.79 1.9 1.56 1.94 2.39.04.79-1.13 1.64-2.24 3.08Z"
      style={{
        fill: "url(#radial-gradient-23)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M515.05 524.9c1.06 1.39 2.24 2.27 2.17 3.04-.07.83-1.28 2.15-2.05 2.18-.75.04-2.12-1.2-2.21-1.99-.09-.8 1.06-1.74 2.08-3.23Z"
      style={{
        fill: "url(#radial-gradient-24)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M580.36 583.98c-1.51.9-2.49 1.95-3.24 1.81-.81-.16-1.4-1.43-2.09-2.21.8-.68 1.57-1.87 2.39-1.9.76-.03 1.57 1.17 2.94 2.31Z"
      style={{
        fill: "url(#radial-gradient-25)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M529.89 486.48c.98 1.47 2.09 2.41 1.96 3.14-.14.82-1.39 1.46-2.15 2.17-.72-.76-1.92-1.48-2-2.3-.07-.74 1.1-1.6 2.19-3.01Z"
      style={{
        fill: "url(#radial-gradient-26)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M626.24 623.5c-.9-1.56-1.9-2.53-1.78-3.31.13-.81 1.36-1.45 2.1-2.16.7.79 1.9 1.56 1.94 2.38.04.79-1.13 1.63-2.27 3.09Z"
      style={{
        fill: "url(#radial-gradient-27)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M447.83 621.37c-1.47.96-2.42 2.07-3.19 1.95-.81-.12-1.45-1.4-2.17-2.17.78-.71 1.52-1.94 2.34-1.99.78-.05 1.63 1.13 3.02 2.21Z"
      style={{
        fill: "url(#radial-gradient-28)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M495.86 494.03c-1.08-1.43-2.22-2.29-2.17-3.06.06-.82 1.24-1.57 1.94-2.35.75.71 2.01 1.36 2.12 2.16.11.78-.95 1.73-1.89 3.25Z"
      style={{
        fill: "url(#radial-gradient-29)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M414.38 585.73c.93 1.52 1.99 2.49 1.86 3.26-.13.8-1.41 1.42-2.19 2.12-.67-.79-1.85-1.57-1.88-2.38-.02-.79 1.13-1.63 2.2-3.01Z"
      style={{
        fill: "url(#radial-gradient-30)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M513.99 453.97c-1.35 1.07-2.19 2.25-2.95 2.2-.82-.05-1.56-1.27-2.33-1.97.71-.77 1.35-2.04 2.17-2.17.75-.12 1.69.99 3.11 1.94Z"
      style={{
        fill: "url(#radial-gradient-31)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M458.86 562.09c-1.13-1.43-2.31-2.27-2.27-3.04.05-.84 1.24-1.61 1.93-2.41.77.71 2.02 1.34 2.17 2.16.13.77-.91 1.74-1.83 3.28Z"
      style={{
        fill: "url(#radial-gradient-32)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M494.71 459.76c1.47-.92 2.44-2.01 3.21-1.88.79.13 1.94 1.47 1.91 2.23-.03.71-1.4 1.87-2.21 1.9-.78.02-1.59-1.16-2.91-2.25Z"
      style={{
        fill: "url(#radial-gradient-33)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M516.62 463.97c-1.08-1.4-2.26-2.26-2.2-3.03.06-.81 1.27-2.12 2.02-2.15.74-.03 2.04 1.21 2.15 2.01.11.78-1 1.71-1.97 3.17Z"
      style={{
        fill: "url(#radial-gradient-34)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M567.77 519.38c-1.51.93-2.48 2-3.25 1.87-.81-.14-1.43-1.42-2.13-2.2.79-.69 1.56-1.89 2.38-1.92.78-.03 1.61 1.15 3 2.25Z"
      style={{
        fill: "url(#radial-gradient-35)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M588.29 578.27c1.01 1.48 2.11 2.4 2.01 3.16-.11.8-1.37 2.01-2.12 2.01-.73 0-1.96-1.28-2.03-2.08-.07-.77 1.07-1.64 2.14-3.08Z"
      style={{
        fill: "url(#radial-gradient-36)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M448.77 522.47c-1.51.95-2.45 2.01-3.23 1.9-.79-.11-2.01-1.42-1.96-2.13.05-.75 1.34-1.93 2.14-1.98.77-.05 1.62 1.11 3.04 2.2Z"
      style={{
        fill: "url(#radial-gradient-37)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M533.67 471.11c-1.49.97-2.4 2.03-3.15 1.93-.81-.11-1.47-1.32-2.19-2.06.75-.72 1.45-1.95 2.26-2.03.76-.07 1.63 1.08 3.08 2.16Z"
      style={{
        fill: "url(#radial-gradient-38)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M524.24 472.15c-1.41 1.06-2.28 2.21-3.06 2.16-.8-.06-1.52-1.29-2.28-2.01.73-.74 1.39-1.98 2.2-2.08.77-.1 1.7.99 3.14 1.94Z"
      style={{
        fill: "url(#radial-gradient-39)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M483.16 473.59c-1.41 1.09-2.27 2.26-3.03 2.2-.82-.06-2.12-1.27-2.14-2.01-.03-.74 1.19-2.03 2-2.15.76-.11 1.7.99 3.17 1.96Z"
      style={{
        fill: "url(#radial-gradient-40)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M465.92 522.74c-1.43.97-2.36 2.09-3.13 1.98-.8-.11-2.01-1.41-1.99-2.16.02-.74 1.33-1.96 2.13-2.01.78-.05 1.63 1.13 2.99 2.19Z"
      style={{
        fill: "url(#radial-gradient-41)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M469.76 581.86c-1.01-1.48-2.11-2.39-2.03-3.17.09-.8 1.35-2.04 2.08-2.03.74 0 1.96 1.27 2.04 2.07.07.77-1.05 1.66-2.09 3.12Z"
      style={{
        fill: "url(#radial-gradient-42)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M487.99 479.79c-1.48.96-2.41 2.04-3.19 1.94-.81-.11-1.46-1.36-2.18-2.11.76-.71 1.5-1.94 2.3-1.99.79-.05 1.65 1.1 3.07 2.16Z"
      style={{
        fill: "url(#radial-gradient-43)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M554.43 588.46c-1.38 1.1-2.22 2.3-3.01 2.26-.82-.03-1.59-1.26-2.38-1.96.71-.79 1.33-2.09 2.15-2.22.77-.12 1.75.97 3.24 1.91Z"
      style={{
        fill: "url(#radial-gradient-44)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M482.34 552.13c-1.38 1.09-2.21 2.25-2.98 2.21-.81-.04-2.14-1.22-2.17-1.94-.03-.75 1.15-2.05 1.95-2.18.76-.12 1.71.96 3.2 1.91Z"
      style={{
        fill: "url(#radial-gradient-45)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M565.67 555.7c-1.12-1.38-2.33-2.23-2.28-3 .05-.81 1.26-2.12 2.03-2.17.73-.05 2.05 1.17 2.18 1.98.12.76-.98 1.72-1.93 3.19Z"
      style={{
        fill: "url(#radial-gradient-46)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M580.07 550.68c1.14 1.37 2.34 2.17 2.32 2.95-.02.8-1.18 2.14-1.92 2.2-.73.06-2.07-1.1-2.22-1.89-.14-.76.91-1.74 1.82-3.26Z"
      style={{
        fill: "url(#radial-gradient-47)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M405.47 603.03c.97 1.48 2.07 2.43 1.96 3.21-.12.81-1.41 1.45-2.19 2.17-.71-.78-1.94-1.53-1.99-2.36-.05-.78 1.14-1.63 2.22-3.02Z"
      style={{
        fill: "url(#radial-gradient-48)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M581.38 544.13c-1.52.96-2.47 2.04-3.24 1.92-.8-.12-1.42-1.4-2.13-2.17.77-.69 1.51-1.88 2.32-1.93.77-.05 1.62 1.09 3.04 2.17Z"
      style={{
        fill: "url(#radial-gradient-49)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M481.89 488.36c-1.55 1.02-2.41 2-3.3 2.03-.68.02-2.07-1.32-1.96-1.78.22-.92 1.16-2.17 1.96-2.29.78-.12 1.74 1.01 3.31 2.04Z"
      style={{
        fill: "url(#radial-gradient-50)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M506.98 492.05c-.92-1.66-1.83-2.6-1.78-3.48.04-.68 1.48-1.95 1.95-1.81.89.27 2.06 1.31 2.14 2.12.07.77-1.13 1.64-2.3 3.16Z"
      style={{
        fill: "url(#radial-gradient-51)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M390.91 624.59c-1.49.93-2.46 2.02-3.24 1.9-.8-.12-1.43-1.42-2.13-2.2.79-.7 1.56-1.91 2.38-1.95.78-.04 1.62 1.16 2.99 2.25Z"
      style={{
        fill: "url(#radial-gradient-52)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M458.9 634.68c1.07 1.43 2.22 2.31 2.16 3.09-.07.81-1.3 1.53-2.02 2.3-.76-.73-2.01-1.39-2.12-2.21-.1-.77 1-1.7 1.98-3.17Z"
      style={{
        fill: "url(#radial-gradient-53)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M564.38 529.32c-.94-1.49-2.03-2.46-1.9-3.21.14-.8 1.48-1.95 2.23-1.91.74.04 1.92 1.37 1.95 2.18.03.76-1.17 1.58-2.29 2.94Z"
      style={{
        fill: "url(#radial-gradient-54)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M505.27 496.44c-1.45.97-2.38 2.07-3.14 1.96-.81-.11-2.05-1.4-2.01-2.11.04-.76 1.31-1.97 2.12-2.04.76-.06 1.63 1.11 3.03 2.19Z"
      style={{
        fill: "url(#radial-gradient-55)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M440.94 634.01c-1.15-1.39-2.34-2.21-2.32-2.98.03-.8 1.21-2.14 1.94-2.18.75-.05 2.07 1.12 2.22 1.92.14.75-.92 1.73-1.84 3.25Z"
      style={{
        fill: "url(#radial-gradient-56)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M457.43 528.29c-1.52.98-2.47 2.07-3.24 1.96-.82-.12-1.47-1.38-2.19-2.14.78-.7 1.51-1.89 2.34-1.95.78-.06 1.64 1.07 3.09 2.14Z"
      style={{
        fill: "url(#radial-gradient-57)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M461.8 495.04c1.07 1.39 2.24 2.24 2.2 3.02-.05.82-1.27 1.57-1.98 2.35-.77-.72-2.07-1.36-2.18-2.17-.11-.78 1-1.73 1.96-3.2Z"
      style={{
        fill: "url(#radial-gradient-58)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M380.12 634.01c-1.16-1.38-2.38-2.19-2.35-2.95.03-.81 1.19-2.14 1.94-2.2.73-.06 2.08 1.09 2.23 1.89.15.75-.91 1.74-1.82 3.27Z"
      style={{
        fill: "url(#radial-gradient-59)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M432.07 560.42c-1.11-1.42-2.28-2.25-2.24-3.03.04-.82 1.23-1.58 1.92-2.37.77.7 2.03 1.32 2.15 2.13.12.78-.92 1.74-1.84 3.28Z"
      style={{
        fill: "url(#radial-gradient-60)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M407.06 620.1c.98 1.5 2.08 2.44 1.97 3.2-.12.8-1.38 1.44-2.15 2.15-.71-.76-1.92-1.48-1.97-2.28-.05-.79 1.09-1.64 2.15-3.07Z"
      style={{
        fill: "url(#radial-gradient-61)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M468.66 543.87c.95 1.46 2.05 2.41 1.94 3.19-.11.8-1.43 2.02-2.17 1.98-.75-.04-1.96-1.36-2-2.16-.04-.79 1.14-1.64 2.22-3.01Z"
      style={{
        fill: "url(#radial-gradient-62)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M526.64 511.65c1.07 1.36 2.25 2.21 2.19 2.98-.06.83-1.27 1.57-1.97 2.36-.77-.72-2.05-1.36-2.18-2.17-.12-.76.99-1.71 1.96-3.16Z"
      style={{
        fill: "url(#radial-gradient-63)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M518.94 500.66c.96 1.51 2.03 2.45 1.92 3.21-.12.81-1.37 1.45-2.12 2.17-.71-.77-1.93-1.49-1.99-2.31-.06-.76 1.1-1.62 2.2-3.07Z"
      style={{
        fill: "url(#radial-gradient-64)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M540.46 620.3c-1.09-1.4-2.27-2.26-2.22-3.03.06-.81 1.27-2.12 2.02-2.15.74-.03 2.04 1.19 2.16 2 .11.76-.99 1.71-1.96 3.18Z"
      style={{
        fill: "url(#radial-gradient-65)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M592.96 571.47c-1.38 1.1-2.22 2.28-3 2.24-.82-.04-1.58-1.25-2.36-1.94.71-.78 1.33-2.07 2.14-2.2.77-.12 1.73.96 3.22 1.9Z"
      style={{
        fill: "url(#radial-gradient-66)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M588.92 547.28c-1.47.93-2.45 2.03-3.21 1.9-.8-.14-1.97-1.48-1.93-2.24.04-.74 1.39-1.92 2.21-1.95.77-.03 1.59 1.18 2.93 2.29Z"
      style={{
        fill: "url(#radial-gradient-67)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M448.72 532.74c-1.49.97-2.44 2.07-3.21 1.95-.8-.11-2.01-1.43-1.98-2.17.03-.74 1.34-1.95 2.15-2 .78-.04 1.64 1.13 3.04 2.21Z"
      style={{
        fill: "url(#radial-gradient-68)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M433.02 548.02c-1.47.95-2.42 2.04-3.18 1.92-.8-.13-1.99-1.44-1.95-2.18.04-.76 1.34-1.97 2.14-2.01.77-.04 1.61 1.15 2.99 2.27Z"
      style={{
        fill: "url(#radial-gradient-69)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M577.15 548.61c-1.39 1.07-2.25 2.23-3.02 2.18-.81-.05-2.12-1.26-2.14-2-.03-.74 1.19-2.03 2-2.14.77-.11 1.7 1 3.17 1.96Z"
      style={{
        fill: "url(#radial-gradient-70)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M461.85 549.35c-1.49.94-2.47 2.04-3.22 1.9-.81-.14-1.99-1.47-1.95-2.23.04-.73 1.39-1.91 2.21-1.95.77-.03 1.6 1.16 2.96 2.27Z"
      style={{
        fill: "url(#radial-gradient-71)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M407.65 616.99c-1.07-1.41-2.25-2.28-2.19-3.05.06-.82 1.28-2.13 2.03-2.15.75-.02 2.05 1.22 2.16 2.03.1.78-1.02 1.72-2 3.18Z"
      style={{
        fill: "url(#radial-gradient-72)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M601.16 597.44c1.52-1.16 2.4-2.35 3.15-2.27.8.09 1.83 1.27 2.07 2.17.13.48-1.14 1.88-1.83 1.92-.86.04-1.78-.89-3.4-1.82Z"
      style={{
        fill: "url(#radial-gradient-73)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M478.07 571.25c-1.48.99-2.39 2.08-3.17 1.99-.78-.09-1.97-1.38-1.95-2.11.02-.75 1.27-1.99 2.04-2.04.78-.05 1.64 1.09 3.08 2.16Z"
      style={{
        fill: "url(#radial-gradient-74)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M518.17 607.07c-1.47.96-2.42 2.07-3.19 1.95-.81-.13-2.04-1.45-2-2.19.04-.76 1.37-1.97 2.19-2.02.78-.05 1.63 1.16 3 2.26Z"
      style={{
        fill: "url(#radial-gradient-75)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M474.42 535.32c1.1 1.37 2.3 2.21 2.26 2.98-.04.81-1.24 2.13-2 2.18-.73.05-2.07-1.16-2.19-1.96-.12-.77.98-1.73 1.93-3.2Z"
      style={{
        fill: "url(#radial-gradient-76)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M485.28 512.36c.94 1.49 2.02 2.45 1.9 3.23-.12.8-1.41 1.43-2.18 2.14-.7-.79-1.91-1.55-1.95-2.38-.04-.78 1.14-1.62 2.23-2.99Z"
      style={{
        fill: "url(#radial-gradient-77)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M593.81 605.92c-1.55 1-2.42 1.99-3.29 1.98-.7 0-2.08-1.39-1.96-1.84.24-.91 1.22-2.11 2.05-2.24.74-.12 1.68 1.04 3.2 2.09Z"
      style={{
        fill: "url(#radial-gradient-78)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M552.45 514.59c1.1 1.38 2.28 2.2 2.26 2.99-.03.81-1.22 1.59-1.9 2.39-.79-.69-2.08-1.31-2.21-2.11-.13-.78.94-1.76 1.86-3.28Z"
      style={{
        fill: "url(#radial-gradient-79)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M485.24 453.59c-1.43 1.19-2.19 2.27-3.07 2.38-.65.08-2.11-1.15-2.06-1.69.09-.91.95-2.25 1.74-2.45.74-.19 1.79.87 3.39 1.76Z"
      style={{
        fill: "url(#radial-gradient-80)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M593.2 554.48c-1.52.97-2.45 2.04-3.23 1.94-.8-.11-1.44-1.36-2.15-2.11.76-.7 1.49-1.89 2.3-1.95.78-.05 1.64 1.07 3.08 2.12Z"
      style={{
        fill: "url(#radial-gradient-81)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M488.31 464.8c-1.67.93-2.6 1.85-3.48 1.81-.68-.03-1.97-1.48-1.83-1.91.3-.89 1.33-2.02 2.14-2.09.77-.07 1.66 1.08 3.17 2.2Z"
      style={{
        fill: "url(#radial-gradient-82)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M477.73 467.12c-1.36 1.04-2.22 2.2-3 2.15-.81-.05-1.55-1.27-2.32-1.98.71-.77 1.36-2.06 2.16-2.16.78-.1 1.72 1.02 3.16 1.99Z"
      style={{
        fill: "url(#radial-gradient-83)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M487.6 557.26c-.99-1.5-2.07-2.42-1.99-3.21.09-.82 1.3-1.53 2.02-2.28.72.77 1.93 1.49 2.01 2.31.08.79-1.03 1.7-2.04 3.18Z"
      style={{
        fill: "url(#radial-gradient-84)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M489.62 470.44c.91 1.65 1.82 2.58 1.77 3.46-.04.7-1.48 2-1.89 1.85-.9-.31-2.05-1.33-2.12-2.14-.07-.77 1.11-1.66 2.25-3.17Z"
      style={{
        fill: "url(#radial-gradient-85)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M504.01 480.52c-.96-1.6-1.91-2.49-1.89-3.36.01-.7 1.39-2.04 1.82-1.92.91.26 2.13 1.23 2.24 2.03.1.76-1.06 1.69-2.17 3.25Z"
      style={{
        fill: "url(#radial-gradient-86)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M467.98 608.47c-1.49 1.09-2.43 2.27-3.15 2.16-.8-.13-1.79-1.38-1.96-2.28-.1-.55 1.2-1.93 1.86-1.92.88.02 1.73 1.02 3.25 2.04Z"
      style={{
        fill: "url(#radial-gradient-87)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M524.07 486.77c-1.42 1.06-2.28 2.21-3.04 2.14-.81-.07-2.09-1.27-2.11-2-.01-.75 1.18-2.02 1.99-2.13.75-.11 1.68 1 3.16 1.99Z"
      style={{
        fill: "url(#radial-gradient-88)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M537.92 483.53c1.01 1.43 2.14 2.34 2.04 3.1-.1.8-1.38 2.02-2.13 2-.76-.02-2-1.27-2.07-2.07-.07-.75 1.1-1.63 2.16-3.03Z"
      style={{
        fill: "url(#radial-gradient-89)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M518.2 498.1c-1.13-1.4-2.33-2.25-2.29-3.02.04-.82 1.23-2.13 2-2.19.73-.05 2.06 1.16 2.19 1.97.12.77-.96 1.74-1.9 3.24Z"
      style={{
        fill: "url(#radial-gradient-90)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M559.77 499.28c-1.01-1.56-2.14-2.55-1.98-3.26.18-.82 1.43-1.76 2.34-1.92.53-.09 1.85 1.3 1.82 1.98-.04.86-1.08 1.69-2.18 3.2Z"
      style={{
        fill: "url(#radial-gradient-91)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M550.35 494.94c1.12 1.41 2.31 2.26 2.27 3.05-.04.81-1.28 1.56-2 2.34-.77-.72-2.02-1.36-2.15-2.18-.12-.77.95-1.73 1.88-3.21Z"
      style={{
        fill: "url(#radial-gradient-92)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M474.8 501.45c-1.44 1.05-2.31 2.17-3.09 2.11-.82-.07-1.54-1.27-2.31-1.98.72-.74 1.38-1.98 2.18-2.08.78-.1 1.71.97 3.22 1.95Z"
      style={{
        fill: "url(#radial-gradient-93)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M505.68 506.42c-1.61.99-2.5 1.94-3.38 1.94-.69 0-2.04-1.39-1.91-1.84.25-.9 1.24-2.1 2.04-2.2.77-.1 1.7 1.03 3.25 2.1Z"
      style={{
        fill: "url(#radial-gradient-94)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M552.13 510.02c-1.14-1.41-2.32-2.23-2.29-3.01.03-.81 1.23-1.58 1.91-2.36.77.69 2.04 1.29 2.18 2.09.13.77-.9 1.74-1.81 3.28Z"
      style={{
        fill: "url(#radial-gradient-95)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M479.92 504.26c.98 1.5 2.05 2.42 1.96 3.21-.1.81-1.34 1.48-2.07 2.21-.71-.76-1.92-1.49-1.98-2.3-.06-.79 1.06-1.67 2.1-3.12Z"
      style={{
        fill: "url(#radial-gradient-96)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M513.72 510.01c-1.58.96-2.47 1.93-3.33 1.9-.67-.02-1.93-1.42-1.81-1.95.21-.9 1.23-2.1 2.04-2.22.72-.1 1.62 1.12 3.1 2.27Z"
      style={{
        fill: "url(#radial-gradient-97)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M497.65 510.74c-1.51 1.13-2.41 2.31-3.18 2.24-.81-.08-1.83-1.24-2.13-2.14-.14-.42 1.16-1.86 1.85-1.89.89-.04 1.81.88 3.46 1.8Z"
      style={{
        fill: "url(#radial-gradient-98)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M536.38 508.27c1.11 1.43 2.26 2.26 2.22 3.05-.03.83-1.19 1.61-1.86 2.41-.76-.7-2.02-1.31-2.15-2.12-.13-.79.88-1.76 1.78-3.34Z"
      style={{
        fill: "url(#radial-gradient-99)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M487.17 492.11c-.99-1.57-2.12-2.57-1.96-3.28.18-.81 1.45-1.75 2.36-1.88.54-.08 1.86 1.31 1.81 1.98-.05.87-1.1 1.68-2.22 3.19Z"
      style={{
        fill: "url(#radial-gradient-100)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M514.81 518.32c-1.36 1.04-2.22 2.21-3 2.15-.81-.06-1.53-1.29-2.3-2 .72-.76 1.37-2.03 2.17-2.13.78-.09 1.7 1.02 3.13 1.98Z"
      style={{
        fill: "url(#radial-gradient-101)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M497.46 519.14c-1.36 1.08-2.21 2.28-2.98 2.23-.82-.05-2.13-1.25-2.17-2.01-.04-.74 1.18-2.06 1.98-2.18.76-.11 1.71 1 3.16 1.96Z"
      style={{
        fill: "url(#radial-gradient-102)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M481.08 520.38c-1.49 1.09-2.39 2.26-3.17 2.18-.83-.09-1.86-1.24-2.14-2.14-.14-.45 1.19-1.9 1.88-1.92.89-.03 1.81.93 3.42 1.88Z"
      style={{
        fill: "url(#radial-gradient-103)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M507.25 521.18c1.03 1.49 2.14 2.39 2.04 3.15-.1.81-1.33 1.48-2.07 2.22-.73-.74-1.94-1.42-2.04-2.23-.09-.76 1.02-1.65 2.06-3.13Z"
      style={{
        fill: "url(#radial-gradient-104)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M474.49 525.88c-1.44 1.06-2.31 2.19-3.08 2.12-.81-.07-1.53-1.28-2.28-2 .72-.74 1.38-1.98 2.19-2.09.76-.1 1.68.98 3.17 1.96Z"
      style={{
        fill: "url(#radial-gradient-105)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M526.85 529.2c-1.02-1.55-2.16-2.53-2.02-3.25.16-.81 1.42-1.76 2.33-1.92.53-.09 1.86 1.28 1.83 1.95-.04.87-1.07 1.7-2.14 3.22Z"
      style={{
        fill: "url(#radial-gradient-106)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M496.42 531.27c-1.04-1.51-2.15-2.42-2.08-3.23.07-.81 1.33-1.51 2.06-2.26.73.77 1.96 1.48 2.06 2.33.09.77-1.03 1.69-2.04 3.17Z"
      style={{
        fill: "url(#radial-gradient-107)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M576.39 532.95c-1.14-1.52-2.17-2.31-2.25-3.19-.06-.7 1.18-2.19 1.62-2.11.92.17 2.21 1.02 2.4 1.81.18.76-.85 1.8-1.77 3.49Z"
      style={{
        fill: "url(#radial-gradient-108)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M535.62 526.82c.96 1.58 1.93 2.48 1.91 3.35-.02.67-1.43 1.96-1.95 1.83-.9-.21-2.12-1.25-2.22-2.05-.09-.75 1.12-1.65 2.26-3.13Z"
      style={{
        fill: "url(#radial-gradient-109)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M566.42 530.75c1.01 1.56 2.14 2.54 2 3.29-.15.8-1.41 1.76-2.32 1.93-.51.09-1.84-1.3-1.81-1.98.04-.88 1.06-1.72 2.12-3.24Z"
      style={{
        fill: "url(#radial-gradient-110)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M581.44 531.81c1.09 1.44 2.24 2.3 2.18 3.07-.06.81-1.28 1.54-1.99 2.3-.76-.71-2.01-1.35-2.12-2.15-.11-.77.96-1.7 1.93-3.22Z"
      style={{
        fill: "url(#radial-gradient-111)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M418.45 578.89c1.11 1.52 2.15 2.33 2.21 3.21.04.66-1.26 2.05-1.79 1.97-.9-.14-2.18-1.07-2.34-1.86-.15-.74.94-1.73 1.92-3.32Z"
      style={{
        fill: "url(#radial-gradient-112)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M560.69 538.53c-1.51 1.05-2.44 2.18-3.21 2.08-.81-.11-1.79-1.3-2.04-2.2-.12-.45 1.24-1.83 1.93-1.83.88 0 1.76.98 3.32 1.95Z"
      style={{
        fill: "url(#radial-gradient-113)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M572.36 542.37c-1-1.48-2.11-2.41-2.01-3.18.11-.82 1.37-1.48 2.13-2.21.72.76 1.96 1.47 2.03 2.29.07.78-1.08 1.66-2.14 3.1Z"
      style={{
        fill: "url(#radial-gradient-114)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M549.74 537.16c1.09 1.56 2.23 2.5 2.12 3.25-.12.81-1.33 1.8-2.23 2.01-.5.12-1.87-1.2-1.88-1.88 0-.88.97-1.76 1.98-3.37Z"
      style={{
        fill: "url(#radial-gradient-115)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M441.01 541.31c-1.38 1.1-2.22 2.28-2.98 2.23-.79-.05-2.06-1.27-2.09-2.01-.03-.75 1.14-2.05 1.93-2.17.75-.11 1.69.99 3.14 1.95Z"
      style={{
        fill: "url(#radial-gradient-116)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M481.56 547.59c-1.11-1.35-2.29-2.15-2.28-2.93.01-.81 1.17-1.61 1.83-2.41.79.68 2.08 1.26 2.22 2.05.14.78-.89 1.76-1.77 3.29Z"
      style={{
        fill: "url(#radial-gradient-117)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M443.9 550.3c-1.48.96-2.44 2.07-3.2 1.95-.82-.13-2.01-1.44-1.99-2.21.02-.74 1.37-1.95 2.18-1.99.78-.04 1.62 1.15 3.01 2.25Z"
      style={{
        fill: "url(#radial-gradient-118)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M569.52 577.62c-1.37 1.12-2.2 2.32-2.98 2.29-.81-.03-2.13-1.22-2.18-1.96-.05-.74 1.13-2.08 1.92-2.21.77-.13 1.73.95 3.23 1.88Z"
      style={{
        fill: "url(#radial-gradient-119)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M413.4 578.37c-1.47 1.13-2.36 2.33-3.13 2.26-.78-.07-1.81-1.33-2-2.22-.12-.54 1.15-1.94 1.8-1.94.89 0 1.78.95 3.33 1.91Z"
      style={{
        fill: "url(#radial-gradient-120)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M470.73 510.24c1.04 1.53 2.19 2.5 2.06 3.23-.15.8-1.4 1.77-2.31 1.93-.53.1-1.87-1.26-1.85-1.93.03-.87 1.04-1.71 2.1-3.23Z"
      style={{
        fill: "url(#radial-gradient-121)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M560.2 579.69c.89 1.63 1.81 2.57 1.75 3.44-.05.68-1.49 1.91-1.99 1.76-.89-.25-2.07-1.32-2.13-2.12-.06-.75 1.17-1.61 2.37-3.08Z"
      style={{
        fill: "url(#radial-gradient-122)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M426.46 581.2c1 1.5 2.1 2.42 1.99 3.19-.11.8-1.37 2.01-2.12 2.01-.72 0-1.94-1.3-2-2.1-.06-.78 1.07-1.65 2.13-3.1Z"
      style={{
        fill: "url(#radial-gradient-123)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M441.58 561.15c1.15 1.46 2.35 2.32 2.3 3.08-.06.83-1.18 1.89-2.07 2.22-.42.16-1.92-1.12-1.97-1.81-.06-.88.85-1.83 1.74-3.49Z"
      style={{
        fill: "url(#radial-gradient-124)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M592.77 567.26c-1-1.45-2.11-2.36-2.02-3.12.1-.81 1.34-1.48 2.09-2.21.72.75 1.93 1.45 2.01 2.26.08.76-1.05 1.64-2.08 3.06Z"
      style={{
        fill: "url(#radial-gradient-125)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M408.59 589.12c-1.55 1.07-2.38 2.08-3.26 2.12-.7.04-2.14-1.24-2.05-1.68.2-.92 1.08-2.19 1.88-2.34.77-.15 1.77.92 3.43 1.9Z"
      style={{
        fill: "url(#radial-gradient-126)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M441.47 590.07c-1.64.93-2.57 1.87-3.46 1.84-.69-.03-2-1.48-1.86-1.92.28-.89 1.32-2.04 2.14-2.12.77-.07 1.66 1.09 3.18 2.2Z"
      style={{
        fill: "url(#radial-gradient-127)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M562.13 591.15c-1.54.88-2.53 1.9-3.28 1.74-.8-.17-1.38-1.44-2.05-2.23.8-.66 1.6-1.82 2.42-1.84.77-.01 1.57 1.18 2.92 2.32Z"
      style={{
        fill: "url(#radial-gradient-128)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M468.65 562.58c1.09 1.44 2.23 2.29 2.19 3.06-.05.83-1.21 1.6-1.88 2.39-.76-.7-2.01-1.32-2.14-2.13-.13-.78.9-1.74 1.84-3.33Z"
      style={{
        fill: "url(#radial-gradient-129)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M544.52 595.5c-1.45 1.16-2.22 2.23-3.09 2.33-.64.07-2.05-1.2-2-1.76.09-.9.98-2.21 1.77-2.4.72-.18 1.74.9 3.32 1.83Z"
      style={{
        fill: "url(#radial-gradient-130)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M552.39 596.77c-1.41.98-2.35 2.11-3.06 1.99-.8-.14-1.96-1.44-1.94-2.2.02-.74 1.29-1.97 2.09-2.03.73-.06 1.57 1.15 2.91 2.24Z"
      style={{
        strokeWidth: 0,
        fill: "url(#radial-gradient-131)",
      }}
    />
    <path
      d="M569.83 596.39c-1.43 1.18-2.3 2.43-3.03 2.36-.8-.08-1.86-1.29-2.07-2.18-.13-.55 1.09-1.98 1.76-2.01.86-.04 1.78.91 3.34 1.84Z"
      style={{
        fill: "url(#radial-gradient-132)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M565.73 566.65c-1.51 1.08-2.34 2.1-3.22 2.15-.64.04-2-1.29-1.91-1.84.14-.9 1.09-2.19 1.88-2.33.75-.13 1.72 1 3.26 2.02Z"
      style={{
        fill: "url(#radial-gradient-133)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M615.61 597.39c-1.55 1-2.41 1.98-3.28 1.98-.66 0-1.97-1.35-1.86-1.87.19-.91 1.16-2.16 1.96-2.28.74-.11 1.67 1.07 3.18 2.16Z"
      style={{
        fill: "url(#radial-gradient-134)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M454.2 565.66c1.09 1.4 2.26 2.25 2.21 3.02-.05.8-1.25 2.09-1.99 2.12-.74.03-2.04-1.16-2.16-1.95-.11-.76.97-1.7 1.94-3.19Z"
      style={{
        fill: "url(#radial-gradient-135)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M557.66 596.2c.95 1.59 1.9 2.49 1.87 3.36-.02.68-1.41 1.98-1.89 1.85-.91-.24-2.14-1.24-2.23-2.04-.09-.75 1.11-1.66 2.26-3.17Z"
      style={{
        fill: "url(#radial-gradient-136)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M395.04 599.78c.97 1.49 2.08 2.45 1.96 3.22-.12.81-1.42 1.45-2.2 2.16-.71-.78-1.93-1.53-1.98-2.36-.05-.78 1.13-1.63 2.22-3.03Z"
      style={{
        fill: "url(#radial-gradient-137)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M469.16 559.82c-.93-1.54-1.97-2.5-1.84-3.26.14-.8 1.4-1.4 2.16-2.09.69.77 1.89 1.52 1.93 2.33.04.77-1.13 1.6-2.25 3.02Z"
      style={{
        fill: "url(#radial-gradient-138)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M552.1 607.15c-1.16-1.55-2.34-2.44-2.26-3.22.08-.81 1.26-1.83 2.16-2.11.46-.14 1.89 1.15 1.91 1.84.04.89-.88 1.82-1.81 3.49Z"
      style={{
        fill: "url(#radial-gradient-139)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M529.66 602.34c1.03 1.44 2.14 2.32 2.07 3.1-.07.81-1.28 1.52-1.99 2.28-.74-.73-1.99-1.4-2.08-2.19-.09-.79 1-1.7 1.99-3.19Z"
      style={{
        fill: "url(#radial-gradient-140)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M542.82 604.93c-1.39 1.11-2.2 2.27-2.97 2.24-.8-.03-2.13-1.2-2.16-1.9-.03-.76 1.1-2.07 1.89-2.21.75-.13 1.72.93 3.23 1.87Z"
      style={{
        fill: "url(#radial-gradient-141)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M560.2 602.9c1.09 1.42 2.24 2.26 2.19 3.04-.04.83-1.22 1.59-1.89 2.38-.77-.7-2.03-1.32-2.16-2.13-.12-.78.92-1.74 1.86-3.29Z"
      style={{
        fill: "url(#radial-gradient-142)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M610.46 609.62c-1.1-1.52-2.27-2.44-2.17-3.19.11-.8 1.34-1.8 2.24-2 .52-.11 1.9 1.19 1.9 1.86 0 .88-.98 1.76-1.97 3.33Z"
      style={{
        fill: "url(#radial-gradient-143)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M475.11 609.13c-1.5 1.1-2.32 2.14-3.19 2.19-.67.04-2.06-1.28-1.98-1.81.15-.91 1.08-2.18 1.89-2.36.72-.15 1.72.97 3.28 1.98Z"
      style={{
        fill: "url(#radial-gradient-144)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M437.48 574.15c-.94-1.49-2.01-2.44-1.88-3.19.13-.8 1.43-2 2.15-1.95.75.05 1.93 1.34 1.98 2.15.05.76-1.13 1.6-2.24 3Z"
      style={{
        fill: "url(#radial-gradient-145)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M454.92 607.06c.9 1.55 1.93 2.54 1.79 3.31-.14.8-1.42 1.4-2.2 2.08-.68-.79-1.86-1.57-1.88-2.39-.02-.78 1.15-1.6 2.29-3.01Z"
      style={{
        fill: "url(#radial-gradient-146)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M548.54 610.21c-1.54.96-2.43 1.94-3.29 1.92-.67-.02-1.94-1.42-1.82-1.97.2-.9 1.23-2.12 2.04-2.22.72-.09 1.62 1.13 3.07 2.27Z"
      style={{
        fill: "url(#radial-gradient-147)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M524.64 610.85c-1.52.92-2.48 1.95-3.24 1.82-.78-.13-1.94-1.45-1.88-2.16.06-.75 1.35-1.93 2.13-1.95.78-.02 1.59 1.15 2.99 2.29Z"
      style={{
        fill: "url(#radial-gradient-148)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M510.34 611.78c-1.73.73-2.86 1.64-3.6 1.4-.75-.25-1.5-1.64-1.51-2.54 0-.52 1.56-1.59 2.22-1.45.86.18 1.53 1.3 2.88 2.59Z"
      style={{
        fill: "url(#radial-gradient-149)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M537.06 611.91c-1.42 1.08-2.26 2.22-3.04 2.18-.82-.05-1.58-1.21-2.37-1.89.7-.76 1.32-2.02 2.12-2.14.78-.12 1.73.92 3.28 1.86Z"
      style={{
        fill: "url(#radial-gradient-150)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M623.19 612.54c-1.4 1.12-2.23 2.3-3 2.26-.83-.05-1.59-1.24-2.38-1.94.7-.77 1.33-2.03 2.14-2.17.76-.13 1.72.93 3.24 1.85Z"
      style={{
        fill: "url(#radial-gradient-151)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M447.01 611.51c.9 1.67 1.82 2.61 1.77 3.49-.04.69-1.51 1.98-1.94 1.83-.89-.31-2.03-1.35-2.1-2.18-.06-.77 1.12-1.65 2.27-3.14Z"
      style={{
        fill: "url(#radial-gradient-152)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M589.95 614.23c-1.47 1.08-2.37 2.25-3.14 2.17-.82-.09-1.85-1.24-2.14-2.13-.14-.44 1.19-1.89 1.88-1.91.89-.03 1.8.93 3.39 1.87Z"
      style={{
        fill: "url(#radial-gradient-153)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M597.45 612.46c1 1.56 2.1 2.53 1.97 3.3-.13.81-1.36 1.74-2.27 1.97-.43.11-1.77-1.31-1.75-2.01.03-.88 1.03-1.73 2.05-3.26Z"
      style={{
        fill: "url(#radial-gradient-154)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M389.18 612.87c1.22 1.45 2.46 2.29 2.4 3.04-.06.8-1.24 1.87-2.13 2.12-.51.14-1.96-1.06-2.01-1.74-.06-.86.85-1.8 1.73-3.42Z"
      style={{
        fill: "url(#radial-gradient-155)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M460.32 619.57c-1.08-1.57-2.22-2.51-2.12-3.28.11-.81 1.31-1.75 2.23-2.05.39-.13 1.78 1.25 1.79 1.95 0 .88-.93 1.78-1.9 3.38Z"
      style={{
        fill: "url(#radial-gradient-156)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M469.35 614.44c.99 1.44 2.11 2.36 2.01 3.12-.11.8-1.39 2.01-2.14 2-.74-.01-1.98-1.29-2.04-2.09-.06-.77 1.1-1.63 2.16-3.03Z"
      style={{
        fill: "url(#radial-gradient-157)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M401.6 620.27c-1.54 1.03-2.4 2.03-3.27 2.04-.67 0-1.97-1.35-1.87-1.91.15-.89 1.16-2.15 1.95-2.27.74-.11 1.67 1.05 3.2 2.13Z"
      style={{
        fill: "url(#radial-gradient-158)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M558.6 555.27c.95 1.61 2.03 2.64 1.85 3.37-.19.8-1.48 1.71-2.39 1.84-.52.07-1.79-1.37-1.74-2.04.07-.88 1.13-1.67 2.28-3.17Z"
      style={{
        fill: "url(#radial-gradient-159)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M591.92 621.2c-1.52 1.07-2.47 2.23-3.21 2.12-.81-.13-1.8-1.37-1.98-2.28-.11-.52 1.23-1.9 1.9-1.88.88.02 1.75 1.02 3.28 2.04Z"
      style={{
        fill: "url(#radial-gradient-160)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M520.89 622.6c-1.47 1.15-2.36 2.37-3.11 2.29-.8-.08-1.83-1.31-2.05-2.2-.13-.53 1.13-1.94 1.79-1.96.87-.03 1.78.92 3.36 1.87Z"
      style={{
        fill: "url(#radial-gradient-161)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M460.2 623.44c1.38-1.04 2.27-2.21 3.01-2.12.8.09 2.03 1.36 2.04 2.11 0 .76-1.23 2.03-2.02 2.12-.75.08-1.64-1.08-3.02-2.11Z"
      style={{
        fill: "url(#radial-gradient-162)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M614.5 623.32c1.45-1.13 2.23-2.18 3.1-2.27.67-.07 2.14 1.16 2.07 1.65-.13.92-.99 2.24-1.77 2.42-.75.17-1.79-.88-3.41-1.8Z"
      style={{
        fill: "url(#radial-gradient-163)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M416.34 603.06c-1.49.97-2.45 2.07-3.23 1.96-.81-.12-2.03-1.44-2-2.18.04-.75 1.36-1.96 2.18-2.01.78-.05 1.64 1.14 3.04 2.23Z"
      style={{
        fill: "url(#radial-gradient-164)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M555.59 625.85c-1.48.93-2.45 2.01-3.2 1.88-.79-.14-1.93-1.47-1.89-2.24.03-.73 1.37-1.94 2.15-1.95.79-.01 1.6 1.19 2.95 2.31Z"
      style={{
        fill: "url(#radial-gradient-165)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M482.33 563.23c-1.49.92-2.45 2-3.22 1.87-.79-.13-1.95-1.47-1.91-2.22.04-.72 1.39-1.89 2.19-1.91.78-.02 1.59 1.16 2.94 2.26Z"
      style={{
        fill: "url(#radial-gradient-166)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M472.39 628.07c-1.36 1.11-2.19 2.32-2.96 2.28-.81-.04-2.14-1.24-2.18-2-.04-.75 1.15-2.08 1.95-2.21.76-.13 1.72.98 3.19 1.93Z"
      style={{
        fill: "url(#radial-gradient-167)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M406.61 631.07c-1.52.9-2.47 1.93-3.24 1.8-.8-.13-1.4-1.38-2.09-2.14.78-.68 1.54-1.86 2.34-1.89.78-.03 1.6 1.13 2.99 2.23Z"
      style={{
        fill: "url(#radial-gradient-168)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M509.78 627.72c1.08 1.41 2.22 2.25 2.18 3.01-.05.81-1.23 1.55-1.92 2.32-.77-.7-2.03-1.31-2.15-2.11-.12-.77.95-1.71 1.9-3.23Z"
      style={{
        fill: "url(#radial-gradient-169)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M578.41 575.28c-1.51 1.04-2.48 2.21-3.2 2.07-.8-.16-1.76-1.43-1.91-2.34-.09-.55 1.26-1.9 1.91-1.87.88.04 1.71 1.07 3.2 2.13Z"
      style={{
        fill: "url(#radial-gradient-170)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M456.83 633.89c-1.45.96-2.39 2.06-3.13 1.94-.78-.13-1.91-1.45-1.88-2.21.03-.75 1.3-1.98 2.07-2.01.76-.04 1.58 1.16 2.94 2.28Z"
      style={{
        fill: "url(#radial-gradient-171)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M450.19 644.22c-.93-1.64-1.87-2.56-1.83-3.45.02-.67 1.46-1.92 1.97-1.79.89.23 2.08 1.29 2.15 2.08.07.77-1.12 1.65-2.28 3.15Z"
      style={{
        fill: "url(#radial-gradient-172)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M527.48 452.54c1.01 1.61 1.97 2.5 1.97 3.37 0 .68-1.37 2-1.87 1.89-.9-.21-2.11-1.2-2.22-2.01-.11-.75 1.04-1.68 2.13-3.25Z"
      style={{
        fill: "url(#radial-gradient-173)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M499.47 468.78c-1.69.94-2.72 1.98-3.49 1.81-.78-.17-1.65-1.47-1.79-2.38-.07-.49 1.36-1.74 2.02-1.68.89.09 1.7 1.1 3.25 2.24Z"
      style={{
        fill: "url(#radial-gradient-174)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M495.63 483.4c-1.01-1.63-2.09-2.62-1.95-3.37.16-.8 1.4-1.73 2.31-1.91.48-.1 1.79 1.29 1.76 1.97-.04.88-1.04 1.72-2.13 3.31Z"
      style={{
        fill: "url(#radial-gradient-175)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M452.75 622.88c-.95-1.66-1.89-2.57-1.86-3.46.02-.68 1.46-1.99 1.89-1.85.9.29 2.05 1.3 2.14 2.12.08.77-1.07 1.67-2.17 3.2Z"
      style={{
        fill: "url(#radial-gradient-176)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M579.88 616.12c1.08 1.56 2.22 2.49 2.12 3.25-.11.82-1.28 1.76-2.19 2.07-.37.13-1.78-1.22-1.8-1.91-.01-.88.91-1.78 1.87-3.42Z"
      style={{
        fill: "url(#radial-gradient-177)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M453.27 542.9c-1.63.91-2.55 1.84-3.42 1.79-.7-.04-2-1.47-1.86-1.9.29-.89 1.31-2.05 2.13-2.13.76-.07 1.65 1.11 3.15 2.24Z"
      style={{
        fill: "url(#radial-gradient-178)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M559.53 572.41c-1.66.83-2.61 1.7-3.47 1.62-.68-.06-1.89-1.51-1.74-1.96.3-.89 1.35-2.06 2.15-2.1.76-.04 1.59 1.2 3.06 2.44Z"
      style={{
        fill: "url(#radial-gradient-179)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M492.28 537.5c-1.39 1.16-2.2 2.36-2.96 2.33-.81-.03-2.14-1.17-2.2-1.92-.06-.73 1.09-2.06 1.89-2.22.75-.15 1.73.89 3.27 1.8Z"
      style={{
        fill: "url(#radial-gradient-180)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M425.52 578.8c-1.09-1.57-2.23-2.49-2.13-3.26.1-.81 1.29-1.8 2.19-2.06.44-.13 1.85 1.2 1.86 1.87 0 .89-.93 1.79-1.91 3.45Z"
      style={{
        fill: "url(#radial-gradient-181)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M421.29 570.89c-1.42 1.13-2.25 2.29-3.01 2.25-.8-.04-2.1-1.22-2.14-1.95-.04-.74 1.12-2.04 1.92-2.17.75-.13 1.71.93 3.23 1.87Z"
      style={{
        fill: "url(#radial-gradient-182)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M508 621.3c-1.39 1.1-2.22 2.25-3 2.22-.81-.04-1.55-1.23-2.33-1.92.69-.77 1.3-2.04 2.08-2.16.77-.12 1.72.93 3.24 1.87Z"
      style={{
        fill: "url(#radial-gradient-183)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M497.3 502.78c-1.52.91-2.51 1.97-3.24 1.81-.8-.17-1.94-1.48-1.89-2.22.05-.72 1.39-1.86 2.2-1.89.75-.03 1.55 1.16 2.92 2.31Z"
      style={{
        fill: "url(#radial-gradient-184)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M449.71 582.94c-1.65.98-2.65 2.04-3.42 1.89-.79-.15-1.69-1.42-1.86-2.34-.09-.46 1.34-1.74 2.02-1.7.89.06 1.71 1.06 3.26 2.14Z"
      style={{
        fill: "url(#radial-gradient-185)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M512.25 499.49c-1.57 1.01-2.45 1.99-3.32 1.98-.68 0-2.01-1.37-1.9-1.87.21-.91 1.19-2.14 2-2.26.74-.11 1.68 1.06 3.21 2.15Z"
      style={{
        fill: "url(#radial-gradient-186)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M566.94 514.16c-1.06-1.56-2.19-2.5-2.08-3.25.11-.78 1.37-1.76 2.26-1.93.52-.1 1.88 1.22 1.86 1.86-.03.89-1.01 1.75-2.04 3.32Z"
      style={{
        fill: "url(#radial-gradient-187)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M571.22 555.8c1.07 1.61 2.18 2.55 2.07 3.32-.11.81-1.32 1.78-2.22 2.01-.45.12-1.82-1.24-1.82-1.93 0-.88.96-1.77 1.97-3.41Z"
      style={{
        fill: "url(#radial-gradient-188)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M556.48 522.24c1.01 1.59 1.98 2.46 2 3.35.01.67-1.34 2.02-1.81 1.91-.9-.21-2.13-1.18-2.23-1.97-.1-.79 1.01-1.73 2.04-3.3Z"
      style={{
        fill: "url(#radial-gradient-189)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M543.38 624.09c-1.37 1.07-2.23 2.26-3 2.21-.82-.06-2.14-1.26-2.17-2.01-.03-.75 1.2-2.06 2.01-2.17.77-.11 1.72 1.01 3.16 1.98Z"
      style={{
        fill: "url(#radial-gradient-190)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M439.47 581.19c-1.57 1-2.54 2.1-3.29 1.96-.78-.14-1.71-1.42-1.85-2.31-.08-.54 1.27-1.85 1.9-1.8.89.07 1.71 1.07 3.24 2.16Z"
      style={{
        fill: "url(#radial-gradient-191)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M519.99 514.94c-1.07-1.5-2.18-2.37-2.11-3.15.07-.81 1.27-1.52 1.98-2.28.75.72 1.99 1.37 2.1 2.18.11.77-.97 1.7-1.96 3.25Z"
      style={{
        fill: "url(#radial-gradient-192)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M457.78 631.04c-1.02-1.59-1.98-2.45-1.98-3.31 0-.69 1.33-2.03 1.8-1.92.9.21 2.11 1.16 2.24 1.96.12.73-1 1.68-2.07 3.26Z"
      style={{
        fill: "url(#radial-gradient-193)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M502.41 521.15c-1.17-1.38-2.38-2.18-2.36-2.94.02-.82 1.2-1.61 1.87-2.41.79.67 2.07 1.24 2.24 2.05.15.75-.87 1.75-1.75 3.31Z"
      style={{
        fill: "url(#radial-gradient-194)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M448.37 632.09c-1.02-1.59-2.13-2.55-2.01-3.33.13-.8 1.36-1.72 2.28-1.97.41-.11 1.75 1.3 1.73 2-.02.88-1 1.74-2.01 3.29Z"
      style={{
        fill: "url(#radial-gradient-195)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M414.75 633.21c-.9-1.69-1.79-2.62-1.73-3.5.04-.68 1.49-1.95 1.91-1.81.89.31 2.03 1.34 2.08 2.15.06.78-1.11 1.65-2.26 3.16Z"
      style={{
        fill: "url(#radial-gradient-196)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M485.5 498.14c-1.51 1.14-2.4 2.32-3.14 2.23-.81-.1-1.85-1.28-2.07-2.17-.13-.51 1.15-1.91 1.84-1.94.86-.04 1.76.92 3.37 1.87Z"
      style={{
        fill: "url(#radial-gradient-197)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M527.83 502.42c1.06 1.6 2.16 2.55 2.04 3.3-.12.79-1.34 1.76-2.23 1.95-.49.11-1.83-1.22-1.82-1.89.01-.88.97-1.74 2.01-3.36Z"
      style={{
        fill: "url(#radial-gradient-198)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M540.82 493.48c.96 1.59 1.91 2.48 1.89 3.35-.01.69-1.41 2.02-1.85 1.89-.89-.26-2.06-1.25-2.16-2.06-.09-.76 1.05-1.67 2.12-3.18Z"
      style={{
        fill: "url(#radial-gradient-199)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M497.93 627.69c1.07 1.53 2.07 2.35 2.12 3.23.04.68-1.25 2.09-1.7 2-.91-.19-2.17-1.09-2.32-1.87-.14-.76.93-1.75 1.9-3.36Z"
      style={{
        fill: "url(#radial-gradient-200)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M520.43 447.35c.89 1.68 1.79 2.62 1.73 3.49-.05.68-1.51 1.93-1.95 1.78-.88-.31-1.99-1.35-2.06-2.17-.06-.76 1.12-1.62 2.28-3.11Z"
      style={{
        fill: "url(#radial-gradient-201)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M533.27 627.76c1.16 1.49 2.34 2.34 2.27 3.08-.07.82-1.18 1.83-2.05 2.15-.4.15-1.85-1.09-1.91-1.78-.07-.86.81-1.79 1.69-3.46Z"
      style={{
        fill: "url(#radial-gradient-202)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M603.86 607.06c-1.53 1.11-2.43 2.27-3.18 2.18-.8-.1-1.84-1.29-2.04-2.17-.12-.52 1.16-1.91 1.84-1.93.87-.02 1.77.94 3.38 1.92Z"
      style={{
        fill: "url(#radial-gradient-203)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M551.87 575.53c1.03 1.63 2 2.51 1.99 3.38 0 .69-1.37 2.03-1.85 1.91-.9-.22-2.1-1.19-2.24-2.01-.12-.74 1.01-1.69 2.09-3.28Z"
      style={{
        fill: "url(#radial-gradient-204)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M545.05 493.89c-.91-1.68-1.82-2.62-1.77-3.49.04-.69 1.49-1.97 1.92-1.82.88.3 2 1.33 2.08 2.15.07.76-1.09 1.64-2.23 3.16Z"
      style={{
        fill: "url(#radial-gradient-205)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M449.46 559.19c-1.13-1.51-2.31-2.42-2.22-3.17.1-.8 1.32-1.82 2.21-2.03.51-.12 1.91 1.16 1.92 1.84.02.87-.94 1.77-1.92 3.36Z"
      style={{
        fill: "url(#radial-gradient-206)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M516.14 617.13c-1.49 1.15-2.38 2.35-3.13 2.28-.8-.08-1.84-1.28-2.08-2.17-.13-.49 1.13-1.91 1.8-1.94.88-.03 1.79.9 3.41 1.84Z"
      style={{
        fill: "url(#radial-gradient-207)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M461.67 483.3c1.08 1.44 2.21 2.29 2.15 3.05-.06.82-1.23 1.55-1.92 2.32-.75-.71-1.99-1.33-2.12-2.14-.12-.76.93-1.7 1.89-3.23Z"
      style={{
        fill: "url(#radial-gradient-208)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M541.05 501.66c1.17 1.55 2.34 2.44 2.27 3.2-.08.81-1.23 1.84-2.12 2.12-.46.14-1.89-1.12-1.93-1.8-.05-.88.86-1.82 1.78-3.51Z"
      style={{
        fill: "url(#radial-gradient-209)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M554.64 483.29c-1.38 1.14-2.19 2.32-2.97 2.3-.8-.01-2.13-1.15-2.2-1.89-.06-.72 1.09-2.06 1.87-2.2.77-.14 1.75.89 3.29 1.79Z"
      style={{
        fill: "url(#radial-gradient-210)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M474.86 484.16c-1.19-1.46-2.27-2.22-2.38-3.09-.08-.65 1.17-2.1 1.72-2.04.91.09 2.24.96 2.44 1.75.19.73-.87 1.77-1.78 3.38Z"
      style={{
        fill: "url(#radial-gradient-211)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M469.42 535.85c-1.65.91-2.58 1.82-3.46 1.77-.67-.03-1.92-1.46-1.78-1.94.26-.89 1.3-2.07 2.09-2.13.78-.06 1.64 1.13 3.14 2.3Z"
      style={{
        fill: "url(#radial-gradient-212)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M534.79 574.54c-1.5 1.29-2.56 2.81-3.23 2.65-1.09-.25-1.92-1.6-2.86-2.49.86-.82 1.64-2.17 2.59-2.3.86-.12 1.9 1.09 3.5 2.14Z"
      style={{
        fill: "#fff",
        strokeWidth: 0,
      }}
    />
    <path
      d="M608.36 709.25c.43-.37 1.04-.66 1.26-1.13.61-1.26 1.06-2.61 1.57-3.92-1.29-.09-2.68-.54-3.84-.21-2.31.65-4.31.69-5.1-1.89-.81-2.66.89-3.92 3.43-4.34-.65.73-1.45 1.38-1.9 2.22-.32.59-.49 1.7-.16 2.09.5.58 1.47.8 2.29 1.02.3.08.73-.22 1.09-.38 2.28-1.07 4.54-1.3 5.44 1.56 1.01 3.19-1.12 4.56-4.08 4.99Z"
      style={{
        fill: "url(#radial-gradient-213)",
        strokeWidth: 0,
      }}
    />
  </svg>
)