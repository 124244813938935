import { Autocomplete, Button } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import tanqueCH from "../../../assets/TTF/Captura.PNG";
import tanqueC from "../../../assets/TTF/CapturaC.PNG";
import tanqueCV from "../../../assets/TTF/CapturaCV.PNG";
import flotante from "../../../assets/TTF/Flot.PNG";
import conico from "../../../assets/TTF/conic.PNG";
import geo from "../../../assets/TTF/geo.jpg";
import meb from "../../../assets/TTF/meb.PNG";
import {
  useAuth,
  useProductoBase,
  useTablaAforo,
  useTanques
} from "../../../hooks";
import { aforoSelect } from "../../Comons";
import { alertas } from "../../Comons/Alertas/alertas";


export function AddEditTablaForm(props) {
  const { onClose, onRefetch, Tanques } = props;
  const { auth } = useAuth();
  const { Successful } = alertas();


  const { ProductoBaseDB, getProductoBaseDB } = useProductoBase();
  const { tablasAforoDB, getTablasAforoDB } = useTablaAforo();
  const { addTanques, updateTanques } = useTanques();

  const [tablasFormatA, setTablasTFormatA] = useState([]);
  const [widthSize, setWidthSize] = useState(6)
  const [checkBoxWS, setCheckBoxWS] = useState(12)



  const renderUnidadLongitud = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.longitud) {
      return `${auth.me.SistemasMedicion.longitud} `;
    }
    return "No especificado";
  };



  const renderUnidadVolumen = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.volumen) {
      return `${auth.me.SistemasMedicion.volumen} `;
    }
    return "No especificado";
  };



  const renderUnidadTemperatura = () => {
    if (auth.me?.SistemasMedicion && auth.me?.SistemasMedicion.temperatura) {
      return `${auth.me.SistemasMedicion.temperatura} `;
    }
    return "No especificado";
  };



  useEffect(() => {
    getTablasAforoDB();
    getProductoBaseDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  useEffect(() => {
    setTablasTFormatA(aforoSelect(tablasAforoDB));
  }, [tablasAforoDB]);


  const formik = useFormik({
    // Initializa los valores del formulario con los valores iniciales proporcionados por la función initialValues
    initialValues: initialValues(Tanques),
    // Establece la validación del esquema utilizando Yup, si se proporciona un objeto  se utiliza un esquema de actualización, de lo contrario se utiliza un esquema nuevo
    validationSchema: Yup.object(newSchema(auth)),
    // Desactiva la validación al cambiar los valores del formulario
    validateOnChange: false,
    onSubmit: async (formValue) => {
      try {
        if (!formValue.is_consumo_int) {
          formValue.cap_consumo_int = 0
          formValue.cap_consumo_int_act = 0
        }

        if (formValue.tipo_techo != 2 || formValue.tipo_techo != 4) {
          formValue.ajuste_techo_flotante = 0
          formValue.zona_critica_fra = 0
        }

        // Si se proporciona un objeto se actualiza, de lo contrario se crea uno nuevo
        if (Tanques) await updateTanques(Tanques.id_tanque, formValue);
        else await addTanques(formValue);
        Successful();
        onRefetch();
        onClose();
      } catch (error) {
        // Muestra un mensaje de error si ocurre algún problema
        if (error?.message) {
          toast.error(error.message, {
            position: "top-center",
          });
        } else {
          onClose();
        }
      }
    },
  });

  return (
    <>
      <form className="add-edit-secciones-form" onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          {/* fila 1 */}
          <Grid item xs={12} sm={3}>
            <TextField
              required
              fullWidth
              label="Nombre del tanque"
              name="nombre_tanque"
              placeholder="Nombre del tanque"
              value={formik.values.nombre_tanque}
              onChange={formik.handleChange}
              error={formik.errors.nombre_tanque}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              required
              fullWidth
              label="Código del tanque"
              name="codigo_tanque"
              placeholder="Código del tanque"
              value={formik.values.codigo_tanque}
              onChange={formik.handleChange}
              error={formik.errors.codigo_tanque}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <Autocomplete
              fullWidth
              id="id_producto"
              options={ProductoBaseDB}
              getOptionLabel={(option) => `${option?.nombre}`}
              value={
                ProductoBaseDB?.find(
                  (data) => data.id === formik.values?.id_producto
                ) || null
              }
              onChange={(_, value) =>
                formik.setFieldValue("id_producto", value?.id || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Producto"
                  variant="outlined"
                  error={
                    formik.touched.id_producto &&
                    Boolean(formik.errors.id_producto)
                  }
                  helperText={
                    formik.touched.id_producto &&
                    formik.errors.id_producto
                  }
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>
           
          <Grid item xs={12} sm={3}>
            {auth.me.owner2.modulos_Activos.modulo_almacenamiento && (
              <Autocomplete
                id="id_tabla_aforo"
                options={tablasFormatA}
                getOptionLabel={(option) => option.text}
                value={
                  tablasFormatA?.find(
                    (opt) => opt.key === formik.values?.id_tabla_aforo
                  ) || null
                }
                onChange={(_, value) => {
                  formik.setFieldValue("id_tabla_aforo", value?.key || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tabla de aforo"
                    fullWidth
                    variant="outlined"
                    error={formik.errors.id_tabla_aforo && Boolean(formik.errors.id_tabla_aforo)}
                    helperText={formik.touched.id_tabla_aforo && formik.errors.id_tabla_aforo}
                    sx={{ background: "#fcfcfc" }}
                  />
                )}
              />
            )}
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            {auth.me.owner2.modulos_Activos.modulo_almacenamiento && (
              <Autocomplete
              fullWidth
              id="mapeo_tanque"
              options={MapeosAlmacenamientoDB}
              getOptionLabel={(option) => `${option.nombre_mapeo}`}
              value={
                MapeosAlmacenamientoDB?.find( (data) => data.id_mapping === formik.values?.mapeo_tanque ) || null }
              onChange={(_, value) =>
                formik.setFieldValue(
                  "mapeo_tanque",
                  value?.id_mapping || ""
                )
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Mapeos de tanque"
                  variant="outlined"
                  error={
                    formik.touched.mapeo_tanque &&
                    Boolean(formik.errors.mapeo_tanque)
                  }
                  helperText={
                    formik.touched.tipo_propiedad && formik.errors.tipo_propiedad
                  }
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
            )}
          </Grid> */}
          
          {/* fila 2 */}
          <Grid item xs={12} sm={12}>
            <Autocomplete
              id="tipo_tanque"
              options={TanqueOptions}
              getOptionLabel={(option) => option.text}
              value={
                TanqueOptions?.find(
                  (opt) => opt.key === formik.values?.tipo_tanque
                ) || null
              }
              onChange={(_, value) => {
                formik.setFieldValue("tipo_tanque", value?.key || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de tanque"
                  fullWidth
                  variant="outlined"
                  error={formik.errors.tipo_tanque && Boolean(formik.errors.tipo_tanque)}
                  helperText={formik.touched.tipo_tanque && formik.errors.tipo_tanque}
                  sx={{ background: "#fcfcfc" }}
                />
              )}
            />
          </Grid>

          {/* fila 3 condicional */}
          {formik.values.tipo_tanque == 3 || formik.values.tipo_tanque == 2 ? (
            <>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  label="Presión de almacenamiento"
                  name="presion_almacenamiento"
                  placeholder="Presión almacenamiento (tanques presurizados)"
                  value={formik.values.presion_almacenamiento}
                  onChange={formik.handleChange}
                  error={formik.errors.presion_almacenamiento}
                  type="number"
                />
              </Grid>
            </>
          ) : (
            <>
            </>
          )}

          {/* fila 3 condicional */}
          {formik.values.tipo_tanque == 1 ? (
            <>
              <Grid item xs={12} sm={widthSize}>
                <Autocomplete
                  id="tipo_techo"
                  options={TechoOptions}
                  getOptionLabel={(option) => option.text}
                  value={
                    TechoOptions?.find(
                      (opt) => opt.key === formik.values?.tipo_techo
                    ) || null
                  }
                  onChange={(_, value) => {
                    formik.setFieldValue("tipo_techo", value?.key || "");
                    if (value?.key == 2 || value?.key == 4) {
                      setWidthSize(3)
                    } else {
                      setWidthSize(6)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de techo"
                      fullWidth
                      variant="outlined"
                      error={formik.errors.tipo_techo && Boolean(formik.errors.tipo_techo)}
                      helperText={formik.touched.tipo_techo && formik.errors.tipo_techo}
                      sx={{ background: "#fcfcfc" }}
                    />
                  )}
                />
              </Grid>

              {formik.values.tipo_techo == 2 || formik.values.tipo_techo == 4 ? (
                <>
                  <Grid item xs={12} sm={widthSize}>
                    <TextField
                      required
                      fullWidth
                      label="Ajuste de techo flotante"
                      name="ajuste_techo_flotante"
                      placeholder="Ajuste de techo Flotante"
                      value={formik.values.ajuste_techo_flotante}
                      onChange={formik.handleChange}
                      error={formik.errors.ajuste_techo_flotante}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={widthSize}>
                    <TextField
                      required
                      fullWidth
                      label="Identificación de la zona critica"
                      name="zona_critica_fra"
                      placeholder="Identificación de la zona critica (techo o membrana flotante)"
                      value={formik.values.zona_critica_fra}
                      onChange={formik.handleChange}
                      error={formik.errors.zona_critica_fra}
                      type="number"
                    />
                  </Grid>
                </>
              ) : (
                <></>
              )}

              <Grid item xs={12} sm={widthSize}>
                <Autocomplete
                  id="tipo_fondo"
                  options={FondoOptions}
                  getOptionLabel={(option) => option.text}
                  value={
                    FondoOptions?.find(
                      (opt) => opt.key === formik.values?.tipo_fondo
                    ) || null
                  }
                  onChange={(_, value) => {
                    formik.setFieldValue("tipo_fondo", value?.key || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tipo de fondo"
                      fullWidth
                      variant="outlined"
                      error={formik.errors.tipo_fondo && Boolean(formik.errors.tipo_fondo)}
                      helperText={formik.touched.tipo_fondo && formik.errors.tipo_fondo}
                      sx={{ background: "#fcfcfc" }}
                    />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
            </>
          )}

          {/* fila 4 */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Altura de referencia " + renderUnidadLongitud()}
              name="altura_referencia"
              placeholder={"Altura de referencia " + renderUnidadLongitud()}
              value={formik.values.altura_referencia}
              onChange={formik.handleChange}
              
              error={
                formik.touched.altura_referencia && Boolean(formik.errors.altura_referencia)
              }
              helperText={formik.touched.altura_referencia && formik.errors.altura_referencia}
              type="number"
              
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Altura del cilindro " + renderUnidadLongitud()}
              name="altura_cilindro"
              placeholder={"Altura del cilindro " + renderUnidadLongitud()}
              value={formik.values.altura_cilindro}
              onChange={formik.handleChange}
              error={
                formik.touched.altura_cilindro && Boolean(formik.errors.altura_cilindro)
              }
              helperText={formik.touched.altura_cilindro && formik.errors.altura_cilindro}
              type="number"
            />
          </Grid>

          {/* fila 5 */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Altura máxima llenado " + renderUnidadLongitud()}
              name="Altura_máxima_llenado"
              placeholder={"Altura máxima llenado " + renderUnidadLongitud()}
              value={formik.values.Altura_máxima_llenado}
              onChange={formik.handleChange}
              error={
                formik.touched.Altura_máxima_llenado && Boolean(formik.errors.Altura_máxima_llenado)
              }
              helperText={formik.touched.Altura_máxima_llenado && formik.errors.Altura_máxima_llenado}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Altura máxima de seguridad " + renderUnidadLongitud()}
              name="Altura_máxima_seguridad"
              placeholder={"Altura máxima de seguridad " + renderUnidadLongitud()}
              value={formik.values.Altura_máxima_seguridad}
              onChange={formik.handleChange}
              error={
                formik.touched.Altura_máxima_seguridad && Boolean(formik.errors.Altura_máxima_seguridad)
              }
              helperText={formik.touched.Altura_máxima_seguridad && formik.errors.Altura_máxima_seguridad}
              type="number"
            />
          </Grid>

          {/* fila 6 */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Capacidad del tanque " + renderUnidadVolumen()}
              name="capacidad_tanque"
              placeholder={"Capacidad del tanque " + renderUnidadVolumen()}
              value={formik.values.capacidad_tanque}
              onChange={formik.handleChange}
              error={
                formik.touched.capacidad_tanque && Boolean(formik.errors.capacidad_tanque)
              }
              helperText={formik.touched.capacidad_tanque && formik.errors.capacidad_tanque}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Diámetro del tanque "+ renderUnidadLongitud()}
              name="diametro_tanque"
              placeholder={"Diámetro tanque"}
              value={formik.values.diametro_tanque}
              onChange={formik.handleChange}
              error={
                formik.touched.diametro_tanque && Boolean(formik.errors.diametro_tanque)
              }
              helperText={formik.touched.diametro_tanque && formik.errors.diametro_tanque}

              type="number"
            />
          </Grid>

          {/* fila 7 */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Temperatura de operación " + renderUnidadTemperatura()}
              name="temperatura_operacion"
              placeholder={"Temperatura de operación " + renderUnidadTemperatura()}
              value={formik.values.temperatura_operacion}
              onChange={formik.handleChange}
              error={
                formik.touched.temperatura_operacion && Boolean(formik.errors.temperatura_operacion)
              }
              helperText={formik.touched.temperatura_operacion && formik.errors.temperatura_operacion}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Temperatura base lámina " + renderUnidadTemperatura()}
              name="temperatura_lamina"
              placeholder={
                "Temperatura base de la lámina " + renderUnidadTemperatura()
              }
              value={formik.values.temperatura_lamina}
              onChange={formik.handleChange}
              
              error={
                formik.touched.temperatura_lamina && Boolean(formik.errors.temperatura_lamina)
              }
              helperText={formik.touched.temperatura_lamina && formik.errors.temperatura_lamina}
              type="number"
            />
          </Grid>

          {/* fila 8 */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label="Coeficiente de expansión del material"
              name="coeficiente_lineal_expansion"
              placeholder="Coeficiente lineal de expansión "
              value={formik.values.coeficiente_lineal_expansion}
              onChange={formik.handleChange}
              error={
                formik.touched.coeficiente_lineal_expansion && Boolean(formik.errors.coeficiente_lineal_expansion)
              }
              helperText={formik.touched.coeficiente_lineal_expansion && formik.errors.coeficiente_lineal_expansion}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              label={"Gravedad API Aforo"}
              name="GravedadApi"
              placeholder="Gravedad API @ 60 ºF || 15 ºC"
              value={formik.values.GravedadApi}
              onChange={formik.handleChange}
              error={
                formik.touched.GravedadApi && Boolean(formik.errors.GravedadApi)
              }
              helperText={formik.touched.GravedadApi && formik.errors.GravedadApi}
              type="number"
            />
          </Grid>

          {/* fila 9 */}
          <Grid item xs={12} sm={checkBoxWS}>
            <FormControlLabel fullWidth style={{ paddingLeft: '10px', }}
              control={
                <Checkbox
                  name='is_consumo_int'
                  checked={formik.values.is_consumo_int}
                  onChange={() => {
                    formik.setFieldValue('is_consumo_int', !formik.values.is_consumo_int);

                    if (!formik.values.is_consumo_int) {
                      setCheckBoxWS(4)
                    } else {
                      setCheckBoxWS(12)
                    }
                  }}
                />
              }
              label={<strong>¿Destinado para consumo interno?</strong>}
            />
          </Grid>
          {formik.values.is_consumo_int && (
            <>
              <Grid item xs={12} sm={checkBoxWS}>
                <TextField
                  label={"Capacidad"}
                  name="cap_consumo_int"
                  id="cap_consumo_int"
                  placeholder="250"
                  value={formik.values.cap_consumo_int}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cap_consumo_int && Boolean(formik.errors.cap_consumo_int)
                  }
                  helperText={formik.touched.cap_consumo_int && formik.errors.cap_consumo_int}
                  
                />
                
              </Grid>
              <Grid item xs={12} sm={checkBoxWS}>
                <TextField
                  label={"Capacidad actual"}
                  name="cap_consumo_int_act"
                  id="cap_consumo_int_act"
                  placeholder="250"
                  value={formik.values.cap_consumo_int_act}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cap_consumo_int_act && Boolean(formik.errors.cap_consumo_int_act)
                  }
                  helperText={formik.touched.cap_consumo_int_act && formik.errors.cap_consumo_int_act}
                />
              </Grid>
            </>
          )}

          {/* fila 10 */}
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Comentarios"
              name="comentarios"
              placeholder="Comentarios extra..."
              value={formik.values.comentarios}
              onChange={formik.handleChange}
              error={formik.errors.comentarios}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
          <Button type="submit" variant="contained" color="error" className="btn btn-danger" fullWidth>
              {Tanques ? "Actualizar" : "Crear"}
            </Button>
          </Grid>
        </Grid>
      </form>
       
      
    </>
  );
}

function initialValues(data) {
  return {
    nombre_tanque: data?.nombre_tanque || "",
    codigo_tanque: data?.codigo_tanque || "",
    mapeo_tanque: data?.mapeo_tanque || "",
    id_tabla_aforo: data?.id_tabla_aforo || "",
    altura_referencia: data?.altura_referencia || "",
    capacidad_tanque: data?.capacidad_tanque || "",
    temperatura_operacion: data?.temperatura_operacion || "",
    temperatura_lamina: data?.temperatura_lamina || "",
    diametro_tanque: data?.diametro_tanque || "",
    coeficiente_lineal_expansion: data?.coeficiente_lineal_expansion || "",
    tipo_techo: data?.tipo_techo || "",
    tipo_tanque: data?.tipo_tanque || "",
    tipo_fondo: data?.tipo_fondo || "",
    GravedadApi: data?.GravedadApi || "",
    id_producto: data?.id_producto || "",
    ajuste_techo_flotante: data?.ajuste_techo_flotante || 0,
    zona_critica_fra: data?.zona_critica_fra || 0,
    presion_almacenamiento: data?.presion_almacenamiento || 0,
    comentarios: data?.comentarios || "",
    Altura_máxima_seguridad: data?.Altura_máxima_seguridad || "",
    Altura_máxima_llenado: data?.Altura_máxima_llenado || "",
    altura_cilindro: data?.altura_cilindro || "",
    is_consumo_int: data?.is_consumo_int || false,
    cap_consumo_int: data?.cap_consumo_int || "0",
    cap_consumo_int_act: data?.cap_consumo_int_act || "0",
  };


}

function newSchema(auth) {
  
  if (auth.me.owner2.modulos_Activos.modulo_almacenamiento) {
    return {
      nombre_tanque: Yup.string().required(true),
      codigo_tanque: Yup.string().required(true),
      // mapeo_tanque: Yup.number().required(true),
      id_tabla_aforo: Yup.number().required(true),
      altura_referencia: Yup.number().required(true),
      capacidad_tanque: Yup.number().required(true),
      temperatura_operacion: Yup.number().required(true),
      temperatura_lamina: Yup.number().required(true),
      diametro_tanque: Yup.number().required(true),
      coeficiente_lineal_expansion: Yup.number().required(true),
      GravedadApi: Yup.number().required(true),
      tipo_techo: Yup.number(),
      tipo_tanque: Yup.number().required(true),
      tipo_fondo: Yup.number(),
      id_producto: Yup.string().required(true),
      ajuste_techo_flotante: Yup.number(),
      zona_critica_fra: Yup.number(),
      presion_almacenamiento: Yup.number(),
      comentarios: Yup.string(),
      altura_cilindro: Yup.number().required(true),
      Altura_máxima_llenado: Yup.number().required(true),
      Altura_máxima_seguridad: Yup.number().required(true),
      is_consumo_int: Yup.bool(),
      cap_consumo_int: Yup.string(),
      cap_consumo_int_act: Yup.string(),
    };
  } else {
    return {
      nombre_tanque: Yup.string().required(true),
      codigo_tanque: Yup.string().required(true),
      altura_referencia: Yup.number().required(true),
      capacidad_tanque: Yup.number().required(true),
      temperatura_operacion: Yup.string().matches(/^\d+\.\d+$/, { message: 'Debe ser un número decimal válido' }).required(true),
      temperatura_lamina: Yup.string().matches(/^\d+\.\d+$/, { message: 'Debe ser un número decimal válido' }).required(true),
      diametro_tanque: Yup.number().required(true),
      coeficiente_lineal_expansion: Yup.string().matches(/^\d+\.\d+$/, { message: 'Debe ser un número decimal válido' }).required(true),
      GravedadApi: Yup.string().matches(/^\d+\.\d+$/, { message: 'Debe ser un número decimal válido' }).required(true),
      tipo_techo: Yup.number(),
      tipo_tanque: Yup.number().required(true),
      tipo_fondo: Yup.number(),
      id_producto: Yup.string().required(true),
      ajuste_techo_flotante: Yup.number(),
      zona_critica_fra: Yup.number(),
      presion_almacenamiento: Yup.number(),
      comentarios: Yup.string(),
      altura_cilindro: Yup.string().matches(/^\d+\.\d+$/, { message: 'Debe ser un número decimal válido' }).required(true),
      Altura_máxima_llenado: Yup.string().matches(/^\d+\.\d+$/, { message: 'Debe ser un número decimal válido' }).required(true),
      Altura_máxima_seguridad: Yup.string().matches(/^\d+\.\d+$/, { message: 'Debe ser un número decimal válido' }).required(true),
      is_consumo_int: Yup.bool(),
      cap_consumo_int: Yup.string(),
      cap_consumo_int_act: Yup.string(),
    };
  }

}




const TanqueOptions = [
  {
    key: "1",
    text: "Tanque Cilindro vertical",
    value: "1",
    image: { avatar: true, src: tanqueCV },
  },
  {
    key: "2",
    text: "Tanque cilindrico Horizontal",
    value: "2",
    image: { avatar: true, src: tanqueCH },
  },
  {
    key: "3",
    text: "Tanque esferico",
    value: "3",
    image: { avatar: true, src: tanqueC },
  },
];

const TechoOptions = [
  {
    key: "1",
    text: "Techo Conico",
    value: "1",
    image: { avatar: true, src: conico },
  },
  {
    key: "2",
    text: "Techo Flotante",
    value: "2",
    image: { avatar: true, src: flotante },
  },
  {
    key: "3",
    text: "Techo Geodesico",
    value: "3",
    image: { avatar: true, src: geo },
  },
  {
    key: "4",
    text: "Membrana Flotante",
    value: "4",
    image: { avatar: true, src: meb },
  },
];

const FondoOptions = [
  {
    key: "1",
    text: "Fondo plano",
    value: "1",
  },
  {
    key: "2",
    text: "Fondo triangular",
    value: "2",
  },
  {
    key: "3",
    text: "Fondo circular",
    value: "3",
  },
];
