export const columns = [

  {
    header: "Index",
    name: "codigo_aditivo",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Código",
    name: "code",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Descripción corta",
    name: "shortDesc",
    maxWidth: 1000, defaultFlex: 1
  },
  {
    header: "Descripción larga",
    name: "longDesc",
    maxWidth: 1000, defaultFlex: 1
  }
];

export const filterValue = [
  { name: 'codigo_aditivo', operator: 'startsWith', type: 'string', value: '' },
  { name: 'code', operator: 'startsWith', type: 'string', value: '' },
  { name: 'shortDesc', operator: 'startsWith', type: 'string', value: '' },
  { name: 'longDesc', operator: 'startsWith', type: 'string', value: '' },
];