import { BASE_API } from "../../../utils/constants";
import { makeRequest } from "../../Request";
const BASE_URL = `${BASE_API}/`;

// Función para consultar un nuevo dato
export async function getAlarmasNivelTanquesApi(id, token, schema) {
    const url = `${BASE_URL}${schema}/alarmsT/AlarmasNivel/${id}`;
    return makeRequest(url, "GET", token);
}

// Función para actualizar los datos 
export async function updateAlarmasNivelTanqueApi(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/alarmsT/AlarmasNivel/${id}/`;
    return makeRequest(url, "PUT", token, data);
}

// Función para consultar un nuevo dato
export async function getAlarmasTemperTanquesApi(id, token, schema) {
    const url = `${BASE_URL}${schema}/alarmsT/AlarmasTemperatura/${id}`;
    return makeRequest(url, "GET", token);
}

// Función para actualizar los datos 
export async function updateAlarmasTemperTanqueApi(id, data, token, schema) {
    const url = `${BASE_URL}${schema}/alarmsT/AlarmasTemperatura/${id}/`;
    return makeRequest(url, "PUT", token, data);
}


